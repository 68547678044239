import { ChangeEvent, useEffect, useState } from 'react';
import InputField from '../../UI/InputField';
import {
  emptyTableOne,
  emptyTableTwo,
  emptyTableThree,
  InputElementTableOne,
  InputElementTableTwo,
  InputElementTableThree,
  InputElementTableFour,
  InputsHeader,
  emptyinputsHeader,
  tableHeadersOne,
  tableHeadersTwo,
  tableHeadersThree,
  InputElementTableFourData
} from './AnodenbauRollToRollData';
import { useNavigate, useLocation } from 'react-router-dom';
import { ApiBackendData } from '../../backend/ApiBackendData';
import { __AnodeR2R, __EvaluationConverted, __LayerR2RConverted, __LayerResponse, __LayerResponseR2R, __LightningR2RConverted, __LightningResponseR2R, __RunningPlanR2R, __Vorreinigung, __VorreinigungResponse, __FileResponse } from '../../backend/types/__GeneralStructures';
import { __AnodeConstructionR2RCreateConverted, __AnodeConstructionR2REditConverted, __MaterialUnconverted } from '../../backend/types/__RequestStructures';
import Config from '../../util/Config';
import { ApiAnodeConstructionR2R } from '../../backend/ApiAnodeConstructionR2R';
import Loading from '../../components/Ladebalken';
import FileUploadAccordeon from '../../components/FileUpload';
import { ApiFile } from '../../backend/ApiFiles';
import { ConfirmationModal, InfoModal } from '../../components/Modal';
import { IBackendAnodeConstructionR2RList, IBackendAnodesR2R } from '../../backend/types/__ResponseStructures';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { MultiSelectDropdownWithCheckboxes } from '../../components/MultiSelectDropdown';
import { ApiWorkBlock } from '../../backend/ApiWorkBlock';
import { ApiMaterial } from '../../backend/ApiMaterial';
import { useUserList } from '../../hooks/UserHooks';

interface CreateTableOneEntryProps {
  inputElement: InputElementTableOne;
  index: number;
  keyName: string;
  subIndex: number;
  handleChangeTableOne: (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
    index: number
  ) => void;
  blitzList: string[];
};
interface CreateTableTwoEntryProps {
  inputElement: InputElementTableTwo;
  index: number;
  keyName: string;
  subIndex: number;
  handleChangeTableTwo: (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
    index: number
  ) => void;
  blitzList: string[];
};
interface CreateTableThreeEntryProps {
  inputElement: InputElementTableThree;
  index: number;
  keyName: string;
  subIndex: number;
  handleChangeTableThree: (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
    index: number
  ) => void;
  blitzList: string[];
};

const createChangeHandler =
  <T extends { [key: string]: string }>(
    table: T[],
    setInputs: React.Dispatch<React.SetStateAction<T[]>>
  ) =>
  (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const name = event.target.name;
    const value = event.target.value;
    const list = [...table];
    list[index] = { ...list[index], [name]: value };

    setInputs(list);
  };
export function CreateTableOneEntry({
  inputElement,
  index,
  keyName,
  subIndex,
  handleChangeTableOne,
}: CreateTableOneEntryProps) {
  if (subIndex === 0) {
    return <div className='input-wrapper'>{inputElement[keyName]}</div>;
  } else if (subIndex === 4) {
    return (
      <InputField
        inputType='text'
        name={keyName}
        placeholder={''}
        value={inputElement[keyName]}
        changeHandler={(event) => handleChangeTableOne(event, index)}
        disabled={false}
        inputClass='text-center text-md cut-text-overflow margin-top-5'
      />
    );
  } else {
    return (
      <InputField
        inputType='text'
        name={keyName}
        placeholder={''}
        value={inputElement[keyName]}
        changeHandler={(event) => handleChangeTableOne(event, index)}
        disabled={false}
        inputClass='text-center text-md cut-text-overflow margin-top-5'
      />
    );
  }
};
export function CreateTableTwoEntry({
  inputElement,
  index,
  keyName,
  subIndex,
  handleChangeTableTwo,
}: CreateTableTwoEntryProps) {
  if (subIndex === 0) {
    return <div className='input-wrapper'>{inputElement[keyName]}</div>;
  } else if (subIndex === 4) {
    return (
      <InputField
        inputType='text'
        name={keyName}
        placeholder={''}
        value={inputElement[keyName]}
        changeHandler={(event) => handleChangeTableTwo(event, index)}
        disabled={false}
        inputClass='text-center text-md cut-text-overflow margin-top-5'
      />
    );
  } else {
    return (
      <InputField
        inputType='text'
        name={keyName}
        placeholder={''}
        value={inputElement[keyName]}
        changeHandler={(event) => handleChangeTableTwo(event, index)}
        disabled={false}
        inputClass='text-center text-md cut-text-overflow margin-top-5'
      />
    );
  }
};
export function CreateTableThreeEntry({
  inputElement,
  index,
  keyName,
  subIndex,
  handleChangeTableThree,
}: CreateTableThreeEntryProps) {
  if (subIndex === 0) {
    return <div className='input-wrapper'>{inputElement[keyName]}</div>;
  } else if (subIndex === 4) {
    return (
      <InputField
        inputType='text'
        name={keyName}
        placeholder={''}
        value={inputElement[keyName]}
        changeHandler={(event) => handleChangeTableThree(event, index)}
        disabled={false}
        inputClass='text-center text-md cut-text-overflow margin-top-5'
      />
    );
  } else {
    return (
      <InputField
        inputType='text'
        name={keyName}
        placeholder={''}
        value={inputElement[keyName]}
        changeHandler={(event) => handleChangeTableThree(event, index)}
        disabled={false}
        inputClass='text-center text-md cut-text-overflow margin-top-5'
      />
    );
  }
};

export default function AnodenbauRollToRoll() {
  // call Apis for basic data
  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  const location = useLocation();
  const navigate = useNavigate();

  const [userListDict,userDataLoaded] = useUserList();

  const [blitzList, setBlitzList] = useState<string[]>([]);
  const [blitzListOptions, setBlitzListOptions] = useState<string[]>([]);
  const [selectionList, setSelectionList] = useState<boolean[]>([]);

  const [anodeConstructionData, setAnodeConstructionData] = useState<IBackendAnodeConstructionR2RList | undefined>(location.state.data);

  /**
   * Table Functions
   */
  const createTableOneData = () : InputElementTableOne[] =>{
    let vorreinigungen: __VorreinigungResponse[] = anodeConstructionData?.anode?.vorreinigung ?? [];
    let temp:InputElementTableOne[] = vorreinigungen.map((reinigung)=>{
      return{
        id: reinigung.id ?? '',
        gasfluss: reinigung.gasFlow,
        start: reinigung.positionStartStop.split(', ')[0],
        stop: reinigung.positionStartStop.split(', ')[1],
        leistung: reinigung.leistung,
        blitz: reinigung.lightnings,
        druck: reinigung.pressure,
        kommentar: reinigung.comment,
        geschwindigkeit: reinigung.speed
      }
    });
    return temp;
  };
  const createTableTwoData = (): InputElementTableTwo[] =>{
    let blitzArray = anodeConstructionData?.anode?.lightnings.map(
      (blitz: { number: any }) => {
        return blitz.number;
      }
    ) ?? [];
    setBlitzList(blitzArray);
    let blitzOptions: string[] =[];
    for(let blitz of blitzArray){
      blitzOptions.push(blitz.toString());
    }
    setBlitzListOptions(blitzOptions);

    let layer: __LayerResponseR2R[] = anodeConstructionData?.anode?.layers ?? [];
    let temp: InputElementTableTwo[] = layer.map((oneLayer) =>{
      let tempLightnings: {id:string,label:string}[] = oneLayer.lightnings.map(x=>{ return {id: String(x.number-1), label:x.number.toString()}});
      return {
        schritt: oneLayer.number.toString(),
        material: oneLayer.material,
        dicke: oneLayer.layerDepth.toString(),
        gasfluss: oneLayer.gasFlow.toString(),
        druck: oneLayer.pressure.toString(),
        p: oneLayer.source_P.toString(),
        u: oneLayer.source_U.toString(),
        i: oneLayer.source_I.toString(),
        geschwindigkeit: oneLayer.speed.toString(),
        start: oneLayer.positionStart,
        stop: oneLayer.positionEnd,
        blitz: oneLayer.lightnings.map(x => x.number.toString()),
        blitzList: tempLightnings,
        kommentar: oneLayer.comment
      }
    });
    // selection list
    let newSelectionList = [];
    for(let t of temp) {
      newSelectionList.push(false);
    }
    setSelectionList(newSelectionList);

    return temp;
  };
  const createTableThreeData = (): InputElementTableThree[] =>{
    let lightning: __LightningResponseR2R[] = anodeConstructionData?.anode?.lightnings ?? [];
    let temp: InputElementTableThree[] = lightning.map(item =>{
      return{
        blitz: item.number.toString(),
        spannung: item.tension.toString(),
        energie: item.energy.toString(),
        impulslaenge: item.impulseLength.toString(),
        start: item.positionOfStart.toString(),
        ende: item.positionOfEnd.toString(),
        geschwindigkeit: item.speed.toString(),
        anzahl: item.numberOfLightnings.toString(),
        intervall: item.intervall.toString(),
        vorpuls: item.preLightningVolt.toString(),
        vorpulsPulslaenge: item.preLightningPulseLen.toString(),
        lampe: item.lamp,
        kommentar: item.comment
      }
    });
    return temp;
  };
  const createTableFourData = (): InputElementTableFour =>{
  let id = anodeConstructionData?.anode?.runningPlan.id ?? '';
  let tableLength = anodeConstructionData?.anode?.runningPlan.length ?? 0;
  let segment: string[] = anodeConstructionData?.anode?.runningPlan?.segment.split('\t') ?? [];
  let band: string[] = anodeConstructionData?.anode?.runningPlan?.band.split('\t') ?? [];
  let pos: string[] = anodeConstructionData?.anode?.runningPlan?.pos.split('\t') ?? [];
  let start: string[] = anodeConstructionData?.anode?.runningPlan?.startPositions.split('\t') ?? [];
  let end: string[] = anodeConstructionData?.anode?.runningPlan?.endPositions.split('\t') ?? [];

  setPlanData(
    {id: id, length:tableLength}
  )
  return {
    segment: segment.map((val, idx) => { return {value:val} }),
    band: band.map((val, idx) => { return {value:val} }),
    pos: pos.map((val, idx) => { return {value:val} }),
    ende: end.map((val, idx) => { return {value:val} }),
    start: start.map((val, idx) => { return {value:val} })
  }
  };


  /**
   * Use States
   */
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [planData, setPlanData] = useState({id:'',length:0});
  // validation errors
  const [nameAlreadyExists, setNameAlreadyExists] = useState<boolean>(false);
  const [files, setFiles] = useState<__FileResponse[]>([]);
  const [loadFiles, setLoadFiles] = useState(false);
  const [infotext, setInfotext] = useState('');
  const [inputsTableFourSegment, setInputsTableFourSegment] = useState<InputElementTableFourData[]>(
    anodeConstructionData && anodeConstructionData.anode
    ? () => createTableFourData().segment.map( (val, idx) => { return val; } ) 
    : []
  );
  const [inputsTableFourBand, setInputsTableFourBand] = useState<InputElementTableFourData[]>(
    anodeConstructionData && anodeConstructionData.anode
    ? () => createTableFourData().band.map( (val, idx) => { return val; } ) 
    : []
  );
  const [inputsTableFourPos, setInputsTableFourPos] = useState<InputElementTableFourData[]>(
    anodeConstructionData && anodeConstructionData.anode
    ? () => createTableFourData().pos.map( (val, idx) => { return val; } ) 
    : []
  );
  const [inputsTableFourStart, setInputsTableFourStart] = useState<InputElementTableFourData[]>(
    anodeConstructionData && anodeConstructionData.anode
    ? () => createTableFourData().start.map( (val, idx) => { return val; } ) 
    : []
  );
  const [inputsTableFourEnde, setInputsTableFourEnde] = useState<InputElementTableFourData[]>(
    anodeConstructionData && anodeConstructionData.anode
    ? () => createTableFourData().ende.map( (val, idx) => { return val; } ) 
    : []
  );
  const [inputsHeader, setInputsHeader] =useState<InputsHeader>(emptyinputsHeader);

  const [initialized, setInitialization] = useState(false);
  const [inputsTableOne, setInputsTableOne] = useState<InputElementTableOne[]>(
    (anodeConstructionData && anodeConstructionData.anode?.vorreinigung && anodeConstructionData.anode.vorreinigung.length > 0)
    ? () => createTableOneData() 
    : [] 
  );
  const [inputsTableTwo, setInputsTableTwo] = useState<InputElementTableTwo[]>(
    (anodeConstructionData && anodeConstructionData.anode?.layers &&anodeConstructionData.anode?.layers.length > 0) 
    ? () => createTableTwoData() 
    : [] 
  );
  const [inputsTableThree, setInputsTableThree] = useState<
    InputElementTableThree[]
  >(
    (anodeConstructionData && anodeConstructionData.anode?.lightnings && anodeConstructionData.anode.lightnings.length > 0)
    ? () => createTableThreeData() 
    : [] 
  );
  const [anodeNames, setAnodeNames] = useState<string[]>([]);
  const [anodesList, setAnodesList] = useState<IBackendAnodesR2R[]>([]);

  const [deleteRefresh, setDeleteRefresh] = useState<boolean>(true);
  const [reloadTableTwo, setReloadTableTwo] = useState(false);

  const [checkLight, setCheckLights] = useState<any[]>([]);

  /**
   * Loading
  */
  const loadAnodeNames = () =>{
    ApiAnodeConstructionR2R.ListAnodes({
      construction:{
        state:{
          name: 'aktiv'
        }
      }
    }).then((data1) =>{
      ApiAnodeConstructionR2R.ListAnodes({
        construction:{
          state:{
            name: 'abgeschlossen'
          }
        }
      }).then((data2)=>{
          let data = [...data1,...data2];
          //sort anodes by name
          data = data.sort((a,b)=>{
            if(a.name.trim().toLowerCase() < b.name.trim().toLowerCase()){
              return -1
            }
            if(a.name.trim().toLowerCase() > b.name.trim().toLowerCase()){
              return 1;
            }
            return 0;
          })
          //set data
          let namesList = data.map(x => x.name.trim());
          setAnodeNames(namesList);
          setAnodesList(data);
          setInitialization(true);
        }).catch((e)=>{
          console.log(e);
      });
    }).catch((e)=>{
      console.log(e);
    });
  };
  const loadFileData = async()=>{
    if(anodeConstructionData && anodeConstructionData.id !== ''){
      await ApiAnodeConstructionR2R.Get(anodeConstructionData.id).then((data)=>{
        setFiles(data.files ?? []);
      });
    }
    setLoadFiles(true);
  };
  const loadData = async() =>{
    loadAnodeNames();
    // get current Date
    let currentFullDate = new Date();
    let month: number|string = currentFullDate.getMonth()+1;
    if (month < 10){
      month = '0' + month.toString();
    }
    else{
      month = month.toString();
    }
    let date =  [currentFullDate.getFullYear().toString(), month,currentFullDate.getDate().toString()].join('-');
    if(!location.state.workblockID){
      setOpenLoadingModal(false);
      return
    }
    await ApiWorkBlock.Get(location.state.workblockID).then((data)=>{
      //set header inputs
      setInputsHeader({
        packageID: data.workpackage.name ?? '',
        workBlockID: anodeConstructionData?.workblock?.name?? '',
        vorgehensbeschreibung: anodeConstructionData?.anode
          ? anodeConstructionData.anode.description
          : '',
        afterSputteringGlassSclices: anodeConstructionData?.anode
        ? anodeConstructionData.anode.afterSputteringGlassSclices
        : false,
        afterSputteringRolling: anodeConstructionData?.anode
        ? anodeConstructionData.anode.afterSputteringRolling
        : false,
        date: anodeConstructionData
          ? anodeConstructionData.date.split('T')[0]
          : date,
        status: anodeConstructionData 
          ? anodeConstructionData.state.name
          : enums.WorkBlockState[0],
        name: anodeConstructionData?.anode 
          ? anodeConstructionData.anode.name.trim()
          : '',
        anodeID: anodeConstructionData?.anode 
          ? anodeConstructionData.anode.id
          : '',
        verantwortlicher: anodeConstructionData
          ? anodeConstructionData.responsibleUser[0].displayName
          : userListDict[0].displayName,
        syntheseort: anodeConstructionData
          ? anodeConstructionData.location.name
          : enums.Location[0],
        substrat: anodeConstructionData
          ? anodeConstructionData.substrat 
          : '',
        blitzCheckbox: anodeConstructionData 
          ? anodeConstructionData.preLightning
          : false ,
        ofenCheckbox: anodeConstructionData 
          ? anodeConstructionData.preStove
          : false ,
        plasmaCheckbox: anodeConstructionData 
          ? anodeConstructionData.prePlasma
          : false ,
        andereCheckbox: anodeConstructionData 
          ? anodeConstructionData.preOthers
          : false ,
        andereInput: anodeConstructionData 
          ? anodeConstructionData.preOthersText
          : '' ,
        laengeInput: anodeConstructionData 
          ? anodeConstructionData.preLength
          : '' ,
      });
      setOpenLoadingModal(false);
    });
    return;
  };
  const reloadAnodeConstruction = async(id:string) => {
    setOpenLoadingModal(true);
    await ApiAnodeConstructionR2R.Get(id)
      .then((data)=>{
        setAnodeConstructionData(data);
      })
      .catch((e)=> {
        console.log(e);
      });
  };


  /**
   * Hanlde Functions
   */
  //InputsHeader
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputsHeader((values) => ({ ...values, [name]: value }));
  };
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setInputsHeader((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const checked = event.target.checked;
    setInputsHeader((prevState) => ({ ...prevState, [name]: checked }));
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    if(name === 'status' && value === 'abgeschlossen'){
      let newInputs = {...inputsHeader};
      let currentFullDate = new Date();
      let month: number|string = currentFullDate.getMonth()+1;
      if (month < 10){
        month = '0' + month.toString();
      }
      else{
        month = month.toString();
      }
      let date =  [currentFullDate.getFullYear().toString(), month,currentFullDate.getDate().toString()].join('-');
      newInputs.date = date
      newInputs.status = value;
      setInputsHeader(newInputs);
    }
    else{
      setInputsHeader((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  //Tables
  const handleChangeTableOne = createChangeHandler<InputElementTableOne>(
    inputsTableOne,
    setInputsTableOne
  );
  const handleChangeTableTwo = createChangeHandler<InputElementTableTwo>(
    inputsTableTwo,
    setInputsTableTwo
  );
  const handleChangeTableThree = createChangeHandler<InputElementTableThree>(
    inputsTableThree,
    setInputsTableThree
  );
  const handleInputChangeTableOne = (
    event: ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    setInputsTableOne((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index ? { ...input, [name]: value } : input
      )
    );
  };
  const handleInputChangeTableTwo = (
    event: ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    setInputsTableTwo((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index ? { ...input, [name]: value } : input
      )
    );
  };
  const handleInputChangeTableThree = (
    event: ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    setInputsTableThree((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index ? { ...input, [name]: value } : input
      )
    );
  };
  const handleSelectionChange = (selectedValues: {id:string,label:string}[], index:number) => {
    let newTableTwo = [...inputsTableTwo];
    newTableTwo[index].blitz = selectedValues.map(x => x.label);
    newTableTwo[index].blitzList = selectedValues;
    checkLinkedLightnings(newTableTwo);
    setInputsTableTwo(newTableTwo);
  };
  const handleAddRowTableOne = () => {
    setInputsTableOne([...inputsTableOne, emptyTableOne]);
  };
  const handleAddRowTableTwo = () => {
    // setReloadTableTwo(true);
    let newTableTwo = JSON.parse(JSON.stringify(emptyTableTwo)); // copy
    if(inputsTableTwo.length > 0){
      newTableTwo.schritt = (Number(inputsTableTwo[inputsTableTwo.length-1].schritt) + 1).toString();
    }
    else{
      newTableTwo.schritt = '1';
    }
    setInputsTableTwo([...inputsTableTwo, newTableTwo]);

    let newSelectionList = [...selectionList];
    newSelectionList.push(false);
    setSelectionList(newSelectionList);
  };
  const handleAddRowTableThree = async () => {
    setDeleteRefresh(false);
    let newTableThree = JSON.parse(JSON.stringify(emptyTableThree)); // copy
    if(inputsTableThree.length > 1){
      newTableThree.blitz = (Number(inputsTableThree[inputsTableThree.length-1].blitz)+1).toString();
    }
    else{
      newTableThree.blitz = (inputsTableThree.length+1).toString();
    }

    let newInputsTableThree = [...inputsTableThree, newTableThree];
    await setInputsTableThree(newInputsTableThree);
    await setBlitzList([...blitzList, (blitzList.length+1).toString()]);

    let newBlitzList = newInputsTableThree.map(element => element.blitz);
    await setBlitzListOptions(newBlitzList);
  };
  const handleAddRowAtSpecifiedIndexTableTwo = (index: number) => {
    // setReloadTableTwo(true);
    let newInputsTableTwo = [...inputsTableTwo];
    //left side of array until index and oush empty row there
    let newInputsTableTwoLeft = newInputsTableTwo.slice(0,index);

    let newTableTwo = JSON.parse(JSON.stringify(emptyTableTwo)); // copy
    newTableTwo.schicht = (inputsTableTwo.length + 1).toString();
    newInputsTableTwoLeft.push(newTableTwo);

    //handle right side
    let newInputsTableTwoRight = newInputsTableTwo.slice(index,newInputsTableTwo.length);
    
    //concat arrays
    newInputsTableTwo = newInputsTableTwoLeft.concat(newInputsTableTwoRight);

    //reset layer numbers && selectiolist
    let newSelectionList: boolean[] = [];
    for(let idx in newInputsTableTwo){
      newInputsTableTwo[idx].schritt = String(Number(idx)+1);
      newSelectionList.push(false);
    }
    setInputsTableTwo(newInputsTableTwo);
    setSelectionList(newSelectionList);
  };
  const handleSelectionRowTableTwo = (index: number) => {
    let newSelectionList = [...selectionList];
    newSelectionList[index] = !newSelectionList[index]
    setSelectionList(newSelectionList);
    setDeleteRefresh(true);
  };
  const handleDuplicateSelectedRows = () => {
    let newRows : InputElementTableTwo[] = [];
    let newSelectionList: boolean[] = [...selectionList];
    let offsetCount = 0
    for(let selectionIdx in selectionList) {
      if(selectionList[selectionIdx]){
        let newTableTwo = JSON.parse(JSON.stringify(inputsTableTwo[selectionIdx])); // copy
        newTableTwo.schritt = (inputsTableTwo.length + 1 + offsetCount).toString();
  
        newRows.push(newTableTwo);
        newSelectionList.push(false);
        offsetCount += 1;
      }
    }

    setInputsTableTwo([...inputsTableTwo, ...newRows]);
    setSelectionList(newSelectionList);
  };
  const handleLoadAnode = (selectedAnode: IBackendAnodesR2R |null) => {
    if(selectedAnode === null){
      return;
    }
    setDeleteRefresh(false);
    //add lightnings
    let lastNumberOfLightning = inputsTableThree.length > 0 ? Number(inputsTableThree[inputsTableThree.length-1].blitz) : 0;
    let newBlitzlist:string[] = [];

    if(selectedAnode.lightnings && selectedAnode.lightnings.length > 0){
      let newInputsTableThree = [...inputsTableThree];
      for(let blitz of selectedAnode.lightnings){
        newInputsTableThree.push({
          blitz: String(lastNumberOfLightning + blitz.number),
          spannung: String(blitz.tension),
          energie:String(blitz.energy),
          impulslaenge: String(blitz.impulseLength),
          start: String(blitz.positionOfStart),
          ende: String(blitz.positionOfEnd),
          geschwindigkeit: String(blitz.speed),
          anzahl: String(blitz.number),
          intervall: String(blitz.intervall),
          vorpuls: String(blitz.preLightningVolt),
          vorpulsPulslaenge: String(blitz.preLightningPulseLen),
          lampe: String(blitz.lamp),
          kommentar: blitz.comment
        });
      }
      newBlitzlist = newInputsTableThree.map(x => x.blitz);
      setInputsTableThree(newInputsTableThree);
    }
    //add layer
    if(selectedAnode.layers && selectedAnode.layers.length > 0){
      let lastNumberOfLayer = inputsTableTwo.length > 0 ? Number(inputsTableTwo[inputsTableTwo.length-1].schicht) : 0;
      let newInputsTableTwo = [...inputsTableTwo];
      for(let layer of selectedAnode.layers){
        let blitzNumbers = layer.lightnings.map( x=> String(lastNumberOfLightning + x.number));
        newInputsTableTwo.push({
          schritt: String(lastNumberOfLayer + layer.number),
          material: layer.material,
          dicke: String(layer.layerDepth),
          gasfluss: String(layer.gasFlow),
          druck: String(layer.pressure),
          p: String(layer.source_P),
          u: String(layer.source_U),
          i: String(layer.source_I),
          geschwindigkeit: String(layer.speed),
          start: String(layer.positionStart),
          stop: String(layer.positionEnd),
          blitz: blitzNumbers,
          blitzList: blitzNumbers.map(x => {
            return {id: String(newBlitzlist.indexOf(x)), label: x}
          }),
          kommentar: layer.comment
        })
      }
      //reset the number of layers && selectiolist
      let newSelectionList: boolean[] = [];
      for(let idx in newInputsTableTwo){
        newInputsTableTwo[idx].schritt = String(Number(idx)+1);
        newSelectionList.push(false);
      }
      setInputsTableTwo(newInputsTableTwo);
      setSelectionList(newSelectionList);
    }

  };
  const handleCreateMaterial = async() =>{
    //create anode material
    let requestDataAnode: __MaterialUnconverted = {
      name: inputsHeader.name,
      platform: Config.Platform,
      materialType: 'Anode',
      total: '1',
      available: '1',
      orderNumber: '',
      state: 'vorhanden',
      origin: 'hergestellt',
      annotation: '',
      deleted: 'false',
      delieverer: '',
      placeOfStorage: '',
      producer: '',
      orderInfo: ''  
    }
    await ApiMaterial.Create(requestDataAnode)
    .then(async()=>{
      setInfotext('Material wurde erstellt.');
      //create cathode material
      let requestDataKathode: __MaterialUnconverted = requestDataAnode;
      requestDataKathode.materialType = 'Kathode'
      await ApiMaterial.Create(requestDataKathode).then(()=>{
        setInfotext('Material wurde erstellt.');
      })
      .catch(()=>{
        setInfotext('Material konnte nicht erstellt werden');
      })
    })
    .catch(() =>{
      setInfotext('Material konnte nicht erstellt werden');
    });
  };
  
  
  /**
   * Handle Table Split Bandlauf-Plan
   */
  const handleChangeTableFourSegment = createChangeHandler<InputElementTableFourData>(
    inputsTableFourSegment,
    setInputsTableFourSegment
  );
  const handleChangeTableFourBand = createChangeHandler<InputElementTableFourData>(
    inputsTableFourBand,
    setInputsTableFourBand
  );
  const handleChangeTableFourPos = createChangeHandler<InputElementTableFourData>(
    inputsTableFourPos,
    setInputsTableFourPos
  );
  const handleChangeTableFourStart = createChangeHandler<InputElementTableFourData>(
    inputsTableFourStart,
    setInputsTableFourStart
  );
  const handleChangeTableFourEnde = createChangeHandler<InputElementTableFourData>(
    inputsTableFourEnde,
    setInputsTableFourEnde
  );
  const handleChangeLengthTableFour = (event: ChangeEvent<HTMLInputElement>) =>{

    let tableLength = Number(event.target.value);
    if(tableLength > inputsTableFourSegment.length){
      let newInputsTableFourSegment = [...inputsTableFourSegment];
      let newInputsTableFourBand = [...inputsTableFourBand];
      let newInputsTableFourPos = [...inputsTableFourPos];
      let newInputsTableFourStart = [...inputsTableFourStart];
      let newInputsTableFourEnde = [...inputsTableFourEnde];

      for( let idx = inputsTableFourSegment.length; idx < tableLength; idx += 1){
        newInputsTableFourSegment.push({value:""});
        newInputsTableFourBand.push({value:""});
        newInputsTableFourPos.push({value:""});
        newInputsTableFourStart.push({value:""});
        newInputsTableFourEnde.push({value:""});
      }
      setInputsTableFourSegment(newInputsTableFourSegment);
      setInputsTableFourBand(newInputsTableFourBand);
      setInputsTableFourPos(newInputsTableFourPos);
      setInputsTableFourStart(newInputsTableFourStart);
      setInputsTableFourEnde(newInputsTableFourEnde);
    }
    else{
      setInputsTableFourSegment(inputsTableFourSegment.slice(0,tableLength));
      setInputsTableFourBand(inputsTableFourBand.slice(0,tableLength));
      setInputsTableFourPos(inputsTableFourPos.slice(0,tableLength));
      setInputsTableFourStart(inputsTableFourStart.slice(0,tableLength));
      setInputsTableFourEnde(inputsTableFourEnde.slice(0,tableLength));
    }
    setPlanData({id:planData.id,length:tableLength})
  };
  
  /**
   * Validations
  */
  const validateName = () => {
    let checkTemp = anodeConstructionData?.anode 
    ? (
      anodeConstructionData.anode.name.trim() !== inputsHeader.name.trim() && inputsHeader.anodeID !== '' && anodeNames.includes(inputsHeader.name.trim())
    ) 
    : false
    if(anodeNames.includes(inputsHeader.name.trim()) && inputsHeader.anodeID === '' || checkTemp){
      setNameAlreadyExists(true);
      return;
    }
    setNameAlreadyExists(false);
  };
  const handleBack = () => {
    navigate(-1);
  };
  const checkLinkedLightnings = (table:InputElementTableTwo[]) =>{
    let blitzNumbers: any[] = [];
    for(let blitz of table.map(entry => entry.blitz)){
      if(blitz.length > 0){
        for(let one of blitz){
          blitzNumbers.push(Number(one));
        }
      }
    }
    setCheckLights(blitzNumbers);
  };

  /**
   * Submitting and APIs
   */
  const handleSave = () => {
    setOpenLoadingModal(true);
    let lightningsRequest: __LightningR2RConverted[] = inputsTableThree.map(blitz =>{
      return{
        number: Number(blitz.blitz),
        tension: Number(blitz.spannung.replace(',','.')),
        energy: Number(blitz.energie.replace(',','.')),
        lamp: blitz.lampe,
        numberOfLightnings: Number(blitz.anzahl),
        positionStart: blitz.start,
        positionEnd: blitz.ende,
        speed: Number(blitz.geschwindigkeit.replace(',','.')),
        intervall: Number(blitz.intervall.replace(',','.')),
        impulseLength: Number(blitz.impulslaenge.replace(',','.')),
        preLightningPulseLen: Number(blitz.vorpulsPulslaenge.replace(',','.')),
        preLightningVolt: Number(blitz.vorpuls.replace(',','.')),
        comment: blitz.kommentar
      }
    });
    let layerRequest: __LayerR2RConverted[] = inputsTableTwo.map(layer =>{
      return{
        number: Number(layer.schritt),
        material: layer.material,
        layerDepth: Number(layer.dicke.replace(',','.')),
        gasFlow: Number(layer.gasfluss.replace(',','.')),
        pressure: Number(layer.druck.replace(',','.')),
        source_I: Number(layer.i.replace(',','.')),
        source_P: Number(layer.p.replace(',','.')),
        source_U: Number(layer.u.replace(',','.')),
        speed: Number(layer.geschwindigkeit.replace(',','.')),
        positionEnd: layer.stop,
        positionStart: layer.start,
        lightnings: layer.blitz.map(x => Number(x)),
        comment: layer.kommentar
      }
    });
    let evaluation: __EvaluationConverted|null = null;
    let runningPlanRequest: __RunningPlanR2R ={
      id: planData.id !== '' ? planData.id : undefined,
      length: planData.length,
      segment: inputsTableFourSegment.map(x=> x.value).join('\t'),
      band: inputsTableFourBand.map(x=> x.value).join('\t'),
      startPosition: inputsTableFourStart.map(x=> x.value).join('\t'),
      endPosition: inputsTableFourEnde.map(x=> x.value).join('\t'),
      pos: inputsTableFourPos.map(x=> x.value).join('\t')
    };
    let vorreinigung: __Vorreinigung[] = inputsTableOne.map(reinigung =>{
      return{
        id : reinigung.id ?? '',
        speed: reinigung.geschwindigkeit,
        leistung: reinigung.leistung,
        gasFlow: reinigung.gasfluss,
        pressure: reinigung.druck,
        positionStartStop: reinigung.start.concat(', ', reinigung.stop),
        lightnings: reinigung.blitz,
        comment: reinigung.kommentar
      }
    });
    if(anodeConstructionData){
      let requestData: __AnodeConstructionR2REditConverted = {
        anodeConstructionID: anodeConstructionData.id ?? '',
        platform: Config.Platform,
        responsibleUser: [userListDict[userListDict.map(x => x.displayName).indexOf(inputsHeader.verantwortlicher)].loginName]
          ?? [userListDict[0].loginName], /////
        state: inputsHeader.status,
        prePlasma: inputsHeader.plasmaCheckbox,
        preLightning: inputsHeader.blitzCheckbox,
        preStove: inputsHeader.ofenCheckbox,
        preOthers: inputsHeader.andereCheckbox,
        preOthersText: inputsHeader.andereInput,
        preLength: inputsHeader.laengeInput,
        anode: {
          id: inputsHeader.anodeID,
          name: inputsHeader.name.trim(),
          runningPlan: runningPlanRequest,
          vorreinigung: vorreinigung,
          description: inputsHeader.vorgehensbeschreibung,
          afterSputteringGlassSclices: inputsHeader.afterSputteringGlassSclices,
          afterSputteringRolling: inputsHeader.afterSputteringRolling
        },
        lightnings: lightningsRequest,
        layer: layerRequest,
        evaluation: evaluation,
        substrat: inputsHeader.substrat,
        location: inputsHeader.syntheseort,
        date: new Date(inputsHeader.date)
      }
      ApiAnodeConstructionR2R.Edit(requestData).then(()=>{
        setInitialization(false);
        reloadAnodeConstruction(anodeConstructionData.id);
      })
      .catch(e => {
        setOpenLoadingModal(false);
        console.log(e);
      });
    }
    else{
      let requestData: __AnodeConstructionR2RCreateConverted = {
        platform: Config.Platform,
        workBlockID: location.state.workblockID,
        responsibleUser:[userListDict[userListDict.map(x=>x.displayName).indexOf(inputsHeader.verantwortlicher)].loginName]
          ?? [userListDict[0].loginName], /////
        state: inputsHeader.status,
        prePlasma: inputsHeader.plasmaCheckbox,
        preLightning: inputsHeader.blitzCheckbox,
        preStove: inputsHeader.ofenCheckbox,
        preOthers: inputsHeader.andereCheckbox,
        preOthersText: inputsHeader.andereInput,
        preLength: inputsHeader.laengeInput,
        anodeR2R: {
          name: inputsHeader.name.trim(),
          runningPlan: runningPlanRequest,
          vorreinigung: vorreinigung,
          description: inputsHeader.vorgehensbeschreibung,
          afterSputteringGlassSclices: inputsHeader.afterSputteringGlassSclices,
          afterSputteringRolling: inputsHeader.afterSputteringRolling
        },
        lightnings: lightningsRequest,
        layer: layerRequest,
        evaluation: evaluation,
        substrat: inputsHeader.substrat,
        location: inputsHeader.syntheseort
      }
      ApiAnodeConstructionR2R.Create(requestData).then((id) => {
        setInitialization(false);
        reloadAnodeConstruction(id);
      })
      .catch(e => {
        setOpenLoadingModal(false);
        console.log(e);
      });
    }
  };
  const handleGeneratePdf = async () => {
    let id = anodeConstructionData?.id ?? '';
    if(id === '') return;
    setOpenLoadingModal(true);
    ApiAnodeConstructionR2R.GeneratePdf(id).then((url) => {
      window.open(url, '_blank');
      setOpenLoadingModal(false);
    })
    .catch((e:unknown)=>{
      setOpenLoadingModal(false);
      //open error modal to show fail
      let errorModal = document.getElementById("ErrorDownloadFailed");
      if(!errorModal) return;
      $('#ErrorDownloadFailed').modal('show');
    });
  };
  const handleReloadPage = async () => {
    setLoadFiles(false);
  };
  const submitModalCloseAccordion = (response:FormData)  =>{
    sendFileData(response);
    // send formdata to backend
    return response;
  };
  const sendFileData = async(data:FormData) =>{
    if(anodeConstructionData){
      const id = anodeConstructionData.id;
      await ApiFile.Upload(data,'/anoder2r',id).then(async ()=>{
        await ApiAnodeConstructionR2R.Get(id).then((data) => {
          const fileList = data.files?? []
          setFiles(fileList);
        });
      });
    }
    setLoadFiles(false);
  };

  /**
   * Use Effects
   */
  
  useEffect(() => {
    document.title = 'Anodenbau';
    setOpenLoadingModal(true);
    loadFileData();
  },[]);
  useEffect(()=> {
    if(anodeConstructionData){
      setInputsTableTwo(createTableTwoData());
      setInputsTableFourBand(createTableFourData().band.map( (val, idx) => { return val; } ));
      setInputsTableFourEnde(createTableFourData().ende.map( (val, idx) => { return val; } ));
      setInputsTableFourPos(createTableFourData().pos.map( (val, idx) => { return val; } ));
      setInputsTableFourSegment(createTableFourData().segment.map( (val, idx) => { return val; } ));
      setInputsTableFourStart(createTableFourData().start.map( (val, idx) => { return val; } ));
      setInputsTableThree(createTableThreeData());
      setInputsTableOne(createTableOneData());
      setOpenLoadingModal(false);
    }
  },[anodeConstructionData]);
  useEffect(()=>{
    if(userDataLoaded){
      loadData();
    }
  },[userDataLoaded]);
  useEffect(()=>{
    loadFileData();
  },[loadFiles]);
  useEffect(() => {
    validateName();
  }, [inputsHeader]);
  useEffect(() => {
    setDeleteRefresh(true);
    setBlitzList(inputsTableThree.map(x => x.blitz));
    setBlitzListOptions(inputsTableThree.map(x => x.blitz));
    checkLinkedLightnings(inputsTableTwo);
  }, [inputsTableThree]);
  useEffect(() => {
    setDeleteRefresh(true);
  },[blitzListOptions]);
  useEffect(()=>{
    if(reloadTableTwo){
      setReloadTableTwo(false);
    }
  },[reloadTableTwo]);
  

  return (
    <div className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
      {initialized ? 
      <>
        <h1>Syntheseprotokoll Roll-to-Roll</h1>
        <form action='' className='margin-top-15'>
          <div className='col-6'>
            <div className='row'>
                <p className='h6 fw-bold'>Arbeitspaket: {inputsHeader.packageID}</p>
              </div>
              <div className='row mt-0'>
              <p className='h6 fw-bold'>Arbeitsblock: {inputsHeader.workBlockID}</p>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col'>
              <label htmlFor=''>Verwantwortlicher</label>
              <div style={{ visibility: 'hidden', height: 0 }}>
                <InputField
                  inputType='text'
                  name='verwantwortlicher'
                  placeholder=''
                  value={inputsHeader.verantwortlicher}
                  changeHandler={handleChange}
                  disabled={false}
                  inputClass='text-center text-md border-bottom-primary cut-text-overflow'
                />
              </div>
              <select
                name='verwantwortlicher'
                value={inputsHeader.verantwortlicher}
                onChange={(event) => handleOptionChange(event)}
                className='input-lg text-md border-bottom-primary'
              >
                {userListDict.map((option) => (
                  <option key={option.displayName} value={option.displayName} hidden={!option.stateIsActve}>
                    {option.displayName}
                  </option>
                ))}
              </select>
            </div>
            <div className='col'>
              <label htmlFor=''>Syntheseort</label>{' '}
              <div style={{ visibility: 'hidden', height: 0 }}>
                <InputField
                  inputType='text'
                  name='syntheseort'
                  placeholder=''
                  value={inputsHeader.syntheseort}
                  changeHandler={handleChange}
                  disabled={false}
                  inputClass='text-center text-md border-bottom-primary cut-text-overflow'
                />
              </div>
              <select
                name='syntheseort'
                value={inputsHeader.syntheseort}
                onChange={(event) => handleOptionChange(event)}
                className='input-lg text-md border-bottom-primary'
              >
                {enums.Location.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className='col'>
              <label htmlFor='status'>Status</label>
              <div style={{ visibility: 'hidden', height: 0 }}>
                <InputField
                  inputType='text'
                  name='status'
                  placeholder=''
                  value={inputsHeader.status}
                  changeHandler={handleChange}
                  disabled={false}
                  inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
                />
              </div>
              <select
                name='status'
                value={inputsHeader.status}
                onChange={(event) => handleOptionChange(event)}
                className='input-lg text-md border-bottom-primary'
              >
                {enums.WorkBlockState.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col'>
              <label htmlFor=''>Name</label>
              <InputField
                inputType='text'
                name='name'
                placeholder=''
                value={inputsHeader.name}
                changeHandler={handleChange}
                disabled={false}
                inputClass={`text-left text-md ${nameAlreadyExists || inputsHeader.name === "" ? "border-bottom-error" : "border-bottom-primary"} cut-text-overflow`}
              />
              {nameAlreadyExists ? 
                <p className = 'text-err margin-bottom-10'>Name existiert bereits.</p> : 
                <></>}
              {inputsHeader.name === "" ?
                <p className = 'text-err margin-bottom-10'>Name nicht erlaubt.</p> 
                : 
                <></>
              }
            </div>
            <div className='col'>
               <label htmlFor=''>Substrat</label>
              <InputField
                inputType='text'
                name='substrat'
                placeholder=''
                value={inputsHeader.substrat}
                changeHandler={handleChange}
                disabled={false}
                inputClass='text-center text-md border-bottom-primary cut-text-overflow'
              />
            </div>
            <div className='col'>
              <label htmlFor=""> Datum</label>
              <InputField
                  inputType='date'
                  name='date'
                  placeholder= {inputsHeader.date}
                  value={inputsHeader.date}
                  changeHandler={handleChange}
                  disabled={false}
                  inputClass='text-center text-md border-bottom-primary cut-text-overflow'
                />
            </div>
          </div>
          <div className='margin-top-5 col-8'>
            <label htmlFor='vorgehensbeschreibung'>Vorgehensbeschreibung</label>
            <textarea
              className='input-lg-textarea border-bottom-primary cut-text-overflow'
              name='vorgehensbeschreibung'
              value={inputsHeader.vorgehensbeschreibung}
              onChange={handleInputChange}
            />
          </div>
          <h2 className='mt-5'>Handhabung nach dem Sputtern</h2>
          <div className='row margin-top-10' style={{alignItems:'center'}}>
            <div className='col-2'>
              <input
                type='checkbox'
                name='afterSputteringGlassSclices'
                id='afterSputteringGlassSclices'
                checked={inputsHeader.afterSputteringGlassSclices}
                onChange={handleCheckboxChange}
              />
              <label htmlFor='afterSputteringGlassSclices' className='margin-left-5'>
                In Glassscheiben
              </label>
            </div>
            <div className='col-2'>
              <input
                type='checkbox'
                name='afterSputteringRolling'
                id='afterSputteringRolling'
                checked={inputsHeader.afterSputteringRolling}
                onChange={handleCheckboxChange}
              />
              <label htmlFor='afterSputteringRolling' className='margin-left-5'>
                Rollen
              </label>
            </div>
          </div>

          <h2 className='mt-5 mb-0'>Vorreinigung</h2>
          <div className='row margin-top-0' style={{alignItems:'center'}}>
            <div className='col-2'>
              <input
                type='checkbox'
                name='plasmaCheckbox'
                id='plasmaCheckbox'
                checked={inputsHeader.plasmaCheckbox}
                onChange={handleCheckboxChange}
              />
              <label htmlFor='plasmaCheckbox' className='margin-left-5'>
                Plasma
              </label>
            </div>
            <div className='col-2'>
              <input
                type='checkbox'
                name='blitzCheckbox'
                id='blitzCheckbox'
                checked={inputsHeader.blitzCheckbox}
                onChange={handleCheckboxChange}
              />
              <label htmlFor='blitzCheckbox' className='margin-left-5'>
                Blitz
              </label>
            </div>

            <div className='col-2'>
              <input
                type='checkbox'
                name='ofenCheckbox'
                id='ofenCheckbox'
                checked={inputsHeader.ofenCheckbox}
                onChange={handleCheckboxChange}
              />
              <label htmlFor='ofenCheckbox' className='margin-left-5'>
                Ofen
              </label>
            </div>
            <div className='col-4'>
                <input
                  type='checkbox'
                  name='andereCheckbox'
                  id='andereCheckbox'
                  checked={inputsHeader.andereCheckbox}
                  onChange={handleCheckboxChange}
                />
                <span className="margin-left-5" id="andereCheckbox">Andere:</span>
                <input
                  type='text'
                  aria-labelledby='andereCheckbox'
                  name='andereInput'
                  placeholder=''
                  value={inputsHeader.andereInput}
                  onChange={handleChange}
                  disabled={false}
                  className='text-center text-md cut-text-overflow input-short text-md border-bottom-primary'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-3'>
                <span className="margin-right-5" id="laengeInput">Länge:</span>
                <input
                  type='text'
                  aria-labelledby='laengeInput'
                  name='laengeInput'
                  placeholder=''
                  value={inputsHeader.laengeInput}
                  onChange={handleChange}
                  disabled={false}
                  className='text-center text-md cut-text-overflow input-short text-md border-bottom-primary'
                />
              </div>
            </div>
        </form>
        
        <div className="table-responsive margin-top-25">
          <table className='table main-table table-hover align-middle' style={{width:'100%'}}>
          {tableHeadersOne}
          <tbody>
            {inputsTableOne.map((inputElement, index) => (
              <>
              <tr key={index}>
                  <td key='i1_del' rowSpan={2} style={{width:'40px'}}>
                    <button
                      className='btn-delete p-0'
                      type='button'
                      title='Löschen'
                      data-bs-toggle='modal'
                      data-bs-target={'#anodeR2RTableOne_'+String(index)}
                    ><FontAwesomeIcon
                      icon={faXmark}
                      size='sm'
                      />
                    </button>
                    <ConfirmationModal
                      modalID={'anodeR2RTableOne_'+String(index)}
                      modalText='Soll der Eintrag wirklich gelöscht werden?'
                      confirmationText='löschen'
                      handleConfirm={() => setInputsTableOne((prevTable) => {
                          const newTable = [...prevTable];
                          newTable.splice(index, 1);
                          return newTable;
                        })
                      }
                    />
                  </td>
                  <td key='i1_geschwindigkeit'>
                    <InputField
                      inputType='text'
                      name='geschwindigkeit'
                      placeholder={''}
                      value={inputElement.geschwindigkeit}
                      changeHandler={(event) =>
                        handleChangeTableOne(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i1_leistung'>
                    <InputField
                      inputType='text'
                      name='leistung'
                      placeholder={''}
                      value={inputElement.leistung}
                      changeHandler={(event) =>
                        handleChangeTableOne(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i1_gasfluss'>
                    <InputField
                      inputType='text'
                      name='gasfluss'
                      placeholder={''}
                      value={inputElement.gasfluss}
                      changeHandler={(event) =>
                        handleChangeTableOne(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i1_druck'>
                    <InputField
                      inputType='text'
                      name='druck'
                      placeholder={''}
                      value={inputElement.druck}
                      changeHandler={(event) =>
                        handleChangeTableOne(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i1_start'>
                    <InputField
                      inputType='text'
                      name='start'
                      placeholder={''}
                      value={inputElement.start}
                      changeHandler={(event) =>
                        handleChangeTableOne(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i1_stop'>
                    <InputField
                      inputType='text'
                      name='stop'
                      placeholder={''}
                      value={inputElement.stop}
                      changeHandler={(event) =>
                        handleChangeTableOne(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i1_blitz'>
                    <InputField
                      inputType='text'
                      name='blitz'
                      placeholder={''}
                      value={inputElement.blitz}
                      changeHandler={(event) =>
                        handleChangeTableOne(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  
              </tr>
              <tr>
                <td key='i1_kommentar' colSpan={7}>
                  <textarea
                    style={{ width: '100%', margin: '0', lineHeight: 'auto' }}
                    name='kommentar'
                    placeholder={''}
                    id= 'kommentar'
                    value={inputElement.kommentar}
                    onChange={event => handleInputChangeTableOne(event,index)}
                  />
                </td>
            </tr>
            </>
            ))}
          </tbody>
          </table>
        </div>
        <input
          className='btn-main margin-top-5'
          type='button'
          value='Vorreinigung hinzufügen'
          onClick={handleAddRowTableOne}
          disabled= {inputsTableOne.length > 0}
        />
        <div className='row'>
          <h2 className='margin-top-25'>Schichtaufbau:</h2>
        </div>
        <div className="table-responsive margin-top-5">
          <table className='table main-table table-hover align-middle'style={{width:'100%'}}>
          {tableHeadersTwo}
          <tbody>
            {!reloadTableTwo? <>
            {inputsTableTwo.map((inputElement, index) => (
              <>
              <tr key={index}>
                  <td key='i2_del' rowSpan={2} style={{width:'70px'}}>
                    <button
                      className='btn text-success btn-add'
                      type='button'
                      title='Schritt oben einfügen'
                      onClick={event => handleAddRowAtSpecifiedIndexTableTwo(index)}
                    >
                      <FontAwesomeIcon
                      icon={faPlus}
                      size='sm'
                      />
                    </button>
                    <button
                      className='btn-delete p-0 pe-2'
                      type='button'
                      title='Löschen'
                      onClick={() => {
                        // setReloadTableTwo(true);
                        setInputsTableTwo((prevTable) => {
                          const newTable = [...prevTable];
                          newTable.splice(index, 1);
                          //reset layer numbers && selectiolist
                          let newSelectionList: boolean[] = [];
                          for(let idx in newTable){
                            newTable[idx].schritt = String(Number(idx)+1);
                            newSelectionList.push(false);
                          }
                          return newTable;
                        })
                      }}
                      // data-bs-toggle='modal'
                      // data-bs-target={'#anodeR2RTableTwo_'+String(index)}
                    ><FontAwesomeIcon
                      icon={faXmark}
                      size='sm'
                      />
                    </button>
                    {/* <ConfirmationModal
                      modalID={'anodeR2RTableTwo_'+String(index)}
                      modalText='Soll der Eintrag wirklich gelöscht werden?'
                      confirmationText='löschen'
                      handleConfirm={() => 
                        setInputsTableTwo((prevTable) => {
                          const newTable = [...prevTable];
                          newTable.splice(index, 1);
                          return newTable;
                        })
                      }
                    />  */}
                    {deleteRefresh ? 
                    <input
                      id={'marking-selection-anodeR2R'+String(index)}
                      className='btn-selection'
                      name ='selection'
                      type='checkbox'
                      title="Markieren"
                      onChange={() =>handleSelectionRowTableTwo(index)}
                    /> : <></>}               
                  </td>
                  <td key='i2_schritt' style={{width:'100px'}}>
                    <InputField
                      inputType='text'
                      name='schritt'
                      placeholder={''}
                      value={inputElement.schritt}
                      changeHandler={(event) =>
                        handleChangeTableTwo(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i2_material'>
                    <InputField
                      inputType='text'
                      name='material'
                      placeholder={''}
                      value={inputElement.material}
                      changeHandler={(event) =>
                        handleChangeTableTwo(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i2_dicke'style={{width:'100px'}}>
                    <InputField
                      inputType='text'
                      name='dicke'
                      placeholder={''}
                      value={inputElement.dicke}
                      changeHandler={(event) =>
                        handleChangeTableTwo(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i2_p'style={{width:'110px'}}>
                    <InputField
                      inputType='text'
                      name='p'
                      placeholder={''}
                      value={inputElement.p}
                      changeHandler={(event) =>
                        handleChangeTableTwo(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i2_geschwindigkeit'style={{width:'100px'}}>
                    <InputField
                      inputType='text'
                      name='geschwindigkeit'
                      placeholder={''}
                      value={inputElement.geschwindigkeit}
                      changeHandler={(event) =>
                        handleChangeTableTwo(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i2_start'style={{width:'100px'}}>
                    <InputField
                      inputType='text'
                      name='start'
                      placeholder={''}
                      value={inputElement.start}
                      changeHandler={(event) =>
                        handleChangeTableTwo(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i2_stop'style={{width:'100px'}}>
                    <InputField
                      inputType='text'
                      name='stop'
                      placeholder={''}
                      value={inputElement.stop}
                      changeHandler={(event) =>
                        handleChangeTableTwo(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  {/* <td key='i2_gasfluss'>
                    <InputField
                      inputType='text'
                      name='gasfluss'
                      placeholder={''}
                      value={inputElement.gasfluss}
                      changeHandler={(event) =>
                        handleChangeTableTwo(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i2_druck'>
                    <InputField
                      inputType='text'
                      name='druck'
                      placeholder={''}
                      value={inputElement.druck}
                      changeHandler={(event) =>
                        handleChangeTableTwo(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i2_u'>
                    <InputField
                      inputType='text'
                      name='u'
                      placeholder={''}
                      value={inputElement.u}
                      changeHandler={(event) =>
                        handleChangeTableTwo(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i2_i'>
                    <InputField
                      inputType='text'
                      name='i'
                      placeholder={''}
                      value={inputElement.i}
                      changeHandler={(event) =>
                        handleChangeTableTwo(event, index)
                      }
                      inputClass='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td> */}
                  <td key='lightningSelectR2R'  style={{width:'150px'}}>
                    { deleteRefresh
                      ? <MultiSelectDropdownWithCheckboxes
                          valueList={blitzListOptions} 
                          selectedValues={inputElement.blitzList}
                          formClass="border-dark"
                          inputTextClass='margin-top-5 input-lg'
                          handleChange={(result) => handleSelectionChange(result, index)}
                        />
                      : <></>
                    }
                  </td>
              </tr>
              <tr>
                <td key='i2_kommentar' colSpan={12}>
                  <textarea
                    style={{ width: '100%', margin: '0', lineHeight: 'auto' }}
                    name='kommentar'
                    placeholder={''}
                    id= 'kommentar'
                    value={inputElement.kommentar}
                    onChange={event => handleInputChangeTableTwo(event,index)}
                  />
                </td>
                </tr>
              </>
            ))}</>:<></>}
          </tbody>
          </table>
        </div>
        <input
          className='btn-main margin-top-5'
          type='button'
          value='Schritt hinzufügen'
          onClick={handleAddRowTableTwo}
        />
        <input
          className='btn-main margin-top-5 margin-left-10'
          type='button'
          value='Markierte Schichten duplizieren'
          onClick={handleDuplicateSelectedRows}
        />
        {
          anodesList.length > 0
          ? <>
              <button
              className='btn-main margin-top-5 margin-left-10'
              type='button'
              data-bs-toggle='modal'
              data-bs-target={'#LoadAnodeR2R'}
              >Anodenbau Laden
            </button>
            <SelectAnodeR2RModalProps
              modalID='LoadAnodeR2R'
              listOfAnodes={anodesList}
              handleSelect={handleLoadAnode}
            />
          </>
          : <></>
        }
        <button
          className='btn-main margin-top-15 margin-left-10'
          type='button'
          title='Alle Tabelleneinträge löschen'
          data-bs-toggle='tooltip'
          onClick={() => {
            setDeleteRefresh(false);
            setInputsTableOne([]);
            setInputsTableTwo([]);
            setInputsTableThree([]);
            setInputsTableFourBand([]);
            setInputsTableFourEnde([]);
            setInputsTableFourPos([]);
            setInputsTableFourSegment([]);
            setInputsTableFourStart([]);
            setPlanData({id:planData.id,length:0});
          }}
        >
          Tabellen leeren
        </button>
        <div className='row'>
          <h2 className='margin-top-25'>Blitz Einstellungen:</h2>
        </div>
        <div className="table-responsive margin-top-5">
          <table className='table main-table table-hover align-middle'style={{width:'100%'}}>
          {tableHeadersThree}
          <tbody>
            {inputsTableThree.map((inputElement, index) => (
              <>
              <tr key={index}>
                  <td key='i3_del' rowSpan={2} style={{width:'60px'}}>
                    <button
                      className='btn-delete'
                      type='button'
                      title='Löschen'
                      disabled = {checkLight.includes(Number(inputElement.blitz))}
                      data-bs-toggle='modal'
                      data-bs-target={'#anodeR2RTableThree_'+String(index)}
                    ><FontAwesomeIcon
                      icon={faXmark}
                      size='sm'
                      />
                    </button>
                    <ConfirmationModal
                      modalID={'anodeR2RTableThree_'+String(index)}
                      modalText='Soll der Eintrag wirklich gelöscht werden?'
                      confirmationText='löschen'
                      handleConfirm={(() => {
                        setDeleteRefresh(false);
                        setInputsTableThree((prevTable) => {
                          const newTable = [...prevTable];
                          newTable.splice(index, 1);
                          return newTable;
                        });
                      })
                      }
                    />                
                  </td>
                  <td key='i3_blitz'style={{width:'100px'}}>
                    <InputField
                      inputType='text'
                      name = 'blitz'
                      placeholder=''
                      disabled={true}
                      value={inputElement.blitz}
                      changeHandler={((event) =>{
                        setDeleteRefresh(false);
                        handleChangeTableThree(event,index);
                      })}
                      inputClass ='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i3_lampe'style={{width:'100px'}}>
                    <InputField
                      inputType='text'
                      name = 'lampe'
                      placeholder=''
                      value={inputElement.lampe}
                      changeHandler={(event) =>handleChangeTableThree(event,index)}
                      inputClass ='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i3_spannung'style={{width:'130px'}}>
                    <InputField
                      inputType='text'
                      name = 'spannung'
                      placeholder=''
                      value={inputElement.spannung}
                      changeHandler={(event) =>handleChangeTableThree(event,index)}
                      inputClass ='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i3_anzahl'style={{width:'100px'}}>
                    <input
                      type='number'
                      name = 'anzahl'
                      placeholder={'0'}
                      min = '0'
                      value={inputElement.anzahl ?? '0'}
                      className='input-lg text-md border-bottom-primary margin-top-5'
                      onChange={(event) =>handleChangeTableThree(event,index)}
                    />
                  </td>
                  <td key='i3_start'style={{width:'100px'}}>
                    <InputField
                      inputType='text'
                      name = 'start'
                      placeholder=''
                      value={inputElement.start}
                      changeHandler={(event) =>handleChangeTableThree(event,index)}
                      inputClass ='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i3_ende'style={{width:'100px'}}>
                    <InputField
                      inputType='text'
                      name = 'ende'
                      placeholder=''
                      value={inputElement.ende}
                      changeHandler={(event) =>handleChangeTableThree(event,index)}
                      inputClass ='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i3_geschwindigkeit'style={{width:'100px'}}>
                    <InputField
                      inputType='text'
                      name = 'geschwindigkeit'
                      placeholder=''
                      value={inputElement.geschwindigkeit}
                      changeHandler={(event) =>handleChangeTableThree(event,index)}
                      inputClass ='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i3_impulslaenge'style={{width:'100px'}}>
                    <InputField
                      inputType='text'
                      name = 'impulslaenge'
                      placeholder=''
                      value={inputElement.impulslaenge}
                      changeHandler={(event) =>handleChangeTableThree(event,index)}
                      inputClass ='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i3_intervall'style={{width:'100px'}}>
                    <InputField
                      inputType='text'
                      name = 'intervall'
                      placeholder=''
                      value={inputElement.intervall}
                      changeHandler={(event) =>handleChangeTableThree(event,index)}
                      inputClass ='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i3_vorpuls'style={{width:'100px'}}>
                    <InputField
                      inputType='text'
                      name = 'vorpuls'
                      placeholder=''
                      value={inputElement.vorpuls}
                      changeHandler={(event) =>handleChangeTableThree(event,index)}
                      inputClass ='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='i3_vorpulsPulslaenge'style={{width:'120px'}}>
                    <InputField
                      inputType='text'
                      name = 'vorpulsPulslaenge'
                      placeholder=''
                      value={inputElement.vorpulsPulslaenge}
                      changeHandler={(event) =>handleChangeTableThree(event,index)}
                      inputClass ='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td>
                  {/* <td key='i3_energie'>
                    <InputField
                      inputType='text'
                      name = 'energie'
                      placeholder=''
                      value={inputElement.energie}
                      changeHandler={(event) =>handleChangeTableThree(event,index)}
                      inputClass ='text-center text-md cut-text-overflow margin-top-5'
                    />
                  </td> */}
              </tr>
              <tr>
                <td key='i3_kommentar' className='p-1' colSpan={12}>
                  <textarea
                    style={{ width: '100%', margin: '0', lineHeight: 'auto' }}
                    name='kommentar'
                    placeholder={''}
                    id= 'kommentar'
                    value={inputElement.kommentar}
                    onChange={event => handleInputChangeTableThree(event,index)}
                  />
                  </td> 
              </tr>
            </>
            ))}
          </tbody>
          </table>
        </div>
        <input
          className='btn-main margin-top-5'
          type='button'
          value='Blitz Hinzufügen'
          onClick={handleAddRowTableThree}
        />
        <div className='row'>
          <h2 className='margin-top-25'>Split Bandlauf-Plan:</h2>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text" id="splitLaenge">Segmente:</span>
          <input
            aria-describedby='splitLaenge'
            type='number'
            name='length'
            placeholder={'0'}
            value={planData.length.toString()}
            min = '0'
            onChange={(event) => handleChangeLengthTableFour(event)
              //handleChangeLengthTableFour(Number(event.target.value))
            }
            disabled={false}
            className='text-center text-md cut-text-overflow input-short text-md border-bottom-primary'
          />
        </div>
          
        <div className="table-responsive margin-top-5">
          <table className='table main-table table-hover align-middle'>
          <tbody>
            <tr key='i4_segment'>
              <th style={{ width: '140px' }} className='th'>Segment</th>
              {inputsTableFourSegment.map((val, index) => (
                <td key={index} style={{ maxWidth: '100px' }}>
                  <InputField
                    inputType='text'
                    name="value"
                    placeholder={''}
                    value={val.value}
                    changeHandler={event =>handleChangeTableFourSegment(event,index)}
                    disabled={false}
                    inputClass='text-center text-md cut-text-overflow margin-top-5'
                  />
                </td>
              ))}
            </tr>
            <tr key='i4_band'>
              <th style={{ width: '140px' }} className='th'>Band</th>
              {inputsTableFourBand.map((val, index) => (
                <td key={index} style={{ width: '100px' }}>
                  <InputField
                    inputType='text'
                    name="value"
                    placeholder={''}
                    value={val.value}
                    changeHandler={event =>handleChangeTableFourBand(event,index)}
                    disabled={false}
                    inputClass='text-center text-md cut-text-overflow margin-top-5'
                  />
                </td>
              ))}
            </tr>
            <tr key='i4_pos'>
              <th style={{ width: '10px' }} className='th'>Pos</th>
              {inputsTableFourPos.map((val, index) => (
                <td key={index} style={{ width: '100px' }}>
                  <InputField
                    inputType='text'
                    name="value"
                    placeholder=""
                    value={val.value}
                    changeHandler={event =>handleChangeTableFourPos(event,index)}
                    disabled={false}
                    inputClass='text-center text-md cut-text-overflow margin-top-5'
                  />
                </td>
              ))}
            </tr>
            <tr key='i4_start'>
              <th style={{ width: '140px' }} className='th'>Start</th>
              {inputsTableFourStart.map((val,index) => (
                <td key={index} style={{ width: '100px' }}>
                  <InputField
                    inputType='text'
                    name="value"
                    placeholder={''}
                    value={val.value}
                    changeHandler={event =>handleChangeTableFourStart(event,index)}
                    disabled={false}
                    inputClass='text-center text-md cut-text-overflow margin-top-5'
                  />
                </td>
              ))}
            </tr>
            <tr key='i4_ende'>
              <th style={{ width: '140px' }} className='th'>Ende</th>
              {inputsTableFourEnde.map((val, index) => (
                <td key={index} style={{ width: '100px' }}>
                  <InputField
                    inputType='text'
                    name="value"
                    value={val.value}
                    placeholder={""}
                    changeHandler={event =>handleChangeTableFourEnde(event,index)}
                    disabled={false}
                    inputClass='text-center text-md cut-text-overflow margin-top-5'
                  />
                </td>
              ))}
            </tr> 
          </tbody>
          </table>
        </div>

        <input
          className='btn-main margin-top-5 margin-right-15'
          type='button'
          value='Zurück'
          onClick={handleBack}
        />
        <input
          className='btn-main margin-top-5 margin-right-15'
          type='button'
          value='Speichern'
          onClick={handleSave}
          disabled = {nameAlreadyExists || inputsHeader.name === ""}
        />
        {anodeConstructionData ?
          <input
            className='btn-main margin-top-5 margin-right-15'
            type='button'
            value='Dokumente generieren'
            onClick={handleGeneratePdf}
          />
          :(<></>)
        }
        <button
            className='btn-main margin-top-15'
            type='button'
            onClick={handleCreateMaterial}
            data-bs-toggle='modal'
            data-bs-target='#InfoModalAnodenbauR2RCreate'
          >Material erstellen </button>
          <InfoModal
              modalID='InfoModalAnodenbauR2RCreate'
              infoText= {infotext}
              confirmationText='ok'
          />
        {loadFiles && anodeConstructionData ?
          <div className='margin-top-20 paket-information-container row'>
            <div className='column'>
              <FileUploadAccordeon
                handleConfirm={submitModalCloseAccordion}
                handleReloadPage={handleReloadPage}
                files={files}
              />
            </div>
          </div>
          : <></>
        }
        <InfoModal
          modalID='ErrorDownloadFailed'
          infoText='Download fehlgeschlagen'
          confirmationText='Ok'
        />
      </>
      :<></>}
    </div>
  );
};

interface selectAnodeR2RModalProps{
  listOfAnodes: IBackendAnodesR2R[];
  modalID: string;
  handleSelect: (anode:IBackendAnodesR2R | null) => void;
};

function SelectAnodeR2RModalProps ({
  listOfAnodes,
  modalID,
  handleSelect
}: selectAnodeR2RModalProps) {
  const [selectedAnode, setSelectedAnode] = useState<IBackendAnodesR2R | null>(null);
  const [inputs, setInputs] = useState(listOfAnodes[0].name);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    setInputs(value);
    let anode = listOfAnodes.find(x => x.name === value);
    if(anode){
      setSelectedAnode(anode);
    }
    else{
      setSelectedAnode(null);
    }
  };

  useEffect(() => {
    if(listOfAnodes.length> 0){
      setSelectedAnode(listOfAnodes[0]);
    }
  },[])

  return(
    <div className='modal fade mt-4' id={modalID} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-middle">
        <div className="modal-content modal-background-lightgrey small-modal">
          <div className="modal-header pb-0">
            <p className='h3'>Anode auswählen</p>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className='modal-body pt-0'>
            <label htmlFor="anodeNamesR2R" className="form-label ms-1 mb-0">Namen</label>
            <input 
              className="form-control input-lg text-md border-bottom-primary margin-top-0" 
              list="selectAnode" 
              id="anode" 
              placeholder={'Anode wählen...'}
              value={inputs}
              name='anode'
              onChange={(event) => handleChange(event)}
            />
            <datalist id="selectAnode">
              {listOfAnodes.map((option: IBackendAnodesR2R) => (
                <option key={option.name} value={option.name}>
                  {option.name}
                </option>
              ))}
            </datalist>
          </div>
          <div className='modal-footer pt-0'>
            <button 
              type="button" 
              className="btn-main margin-top-5" 
              data-bs-dismiss="modal"> Abbrechen
            </button>
            <button
              className='btn-main margin-top-5'
              type='button'
              disabled={selectedAnode === null}
              onClick={event => handleSelect(selectedAnode)} 
              data-bs-dismiss="modal"
              > Auswählen
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};
