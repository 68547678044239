import {useEffect} from 'react'
import {
  NavigateOptions,
  useNavigate,
  useLocation,
} from 'react-router-dom';


interface AblaufDiagrammProps {
  ablauf: {
    title: string;
    notes: string[];
    status: string;
    lineState: string;
    link: string;
    linkProps: NavigateOptions;
  }[];
  disableLinks: boolean;
};

const Ablaufdiagramm = ({ ablauf, disableLinks=false }: AblaufDiagrammProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  return (
    <div>
      <div className='ablauf-container scrollbar-horizontal '>
        {ablauf.map((item, index) => {

            let circleState = ""; 
            switch(item.status) {
              case "aktiv":
                circleState = "in-progress";
                break;
              case "abgeschlossen":
                circleState = "completed";
                break;
              case "gesperrt":
              case "gelöscht":
                circleState = "locked";
                break;
              }

            if (index === ablauf.length - 1) {
              return (
                <div className='height-fit-content ablauf-circle-container'>
                  <div className='circle-container'>
                    <div
                      style={{ cursor: disableLinks ? 'cursor' : 'pointer' }}
                      className={`checkbox ${circleState}`}
                      onClick={() => {
                          if(disableLinks) return;
                          navigate(item.link, {
                            state: { 
                                ...item.linkProps.state
                              },
                            });
                          }}
                    >
                      <p>{index}</p>
                    </div>
                  </div>
                  <div className='ablauf-text-container'>
                    <p className='margin-top-25'>{item.title}</p>
                    {item.notes.map((note: string) => {
                      if (note === '') return;
                      return <p> - {note}</p>;
                    })}
                  </div>
                </div>
              );
            }

          return (
            <div className=' height-fit-content ablauf-circle-container'>
              <div className='circle-container'>
                <div
                  style={{ cursor: disableLinks ? 'cursor' : 'pointer' }}
                  className={`checkbox ${circleState}`}
                  onClick={() => {
                      if(disableLinks) return;
                      navigate(item.link, {
                        state: { 
                            ...item.linkProps.state
                          },
                        });
                      }}
                >
                  <p>{index}</p>
                  <div className={`line-back ${item.lineState}`}></div>
                </div>
              </div>
              <div className='ablauf-text-container'>
                <p className='margin-top-25'>{item.title}</p>
                {item.notes.map((note: string) => {
                  return <p> - {note}</p>;
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Ablaufdiagramm;
