import { NavigateOptions,useLocation } from 'react-router-dom';
import Ablaufdiagramm from './Ablaufdiagramm';
import {
  IBackendBatteryConstructionGet,
  IBackendBatteryTestGet,
  IBackendMaterialList,
  IBackendPlannedSteps,
  IBackendWorkBlock,
} from '../backend/types/__ResponseStructures';
import { useEffect, useState } from 'react';
import { ApiWorkBlock } from '../backend/ApiWorkBlock';
import { ApiAnodeConstruction } from '../backend/ApiAnodeConstruction';
import { ApiBatteryConstruction } from '../backend/ApiBatteryConstruction';
import { __BatteryConstructionResponse, __PlannedStepsConverted } from '../backend/types/__GeneralStructures';
import { ApiBatteryTest } from '../backend/ApiBatteryTest';
import { ApiMaterial } from '../backend/ApiMaterial';
import { ApiAnodeConstructionR2R } from '../backend/ApiAnodeConstructionR2R';
import { ApiBackendData } from '../backend/ApiBackendData';


interface AblaufConverterWithIDProps {
  blockID: string;
  responsibleUser: string[];
  materialsList: IBackendMaterialList[];
};
interface IAblauf {
  title: string;
  notes: string[];
  status: string;
  lineState: string;
  link: string;
  linkProps: NavigateOptions;
};

export default function AblaufConverterWithID({
  blockID,
  responsibleUser,
  materialsList
}: AblaufConverterWithIDProps) {
  const location = useLocation();
  const [block, setBlock] = useState<IBackendWorkBlock>();
  const [blockSteps, setBlockSteps] = useState<IBackendPlannedSteps>();
  const [blockLoaded, setBlockLoaded] = useState(false);
  const [blockStepsLoaded, setBlockStepsLoaded] = useState(false);
  const [ablaufCreated, setAblaufCreated] = useState(false);
  const [newAblauf, setNewAblauf] = useState<IAblauf[]>([]);
  const [materials, setMaterials] = useState<IBackendMaterialList[]>([]);


  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  let halbzellenList = ['Halbzelle'];
  let vollzellenList = enums.CellType.filter(x=> x!== 'Halbzelle');

  const initialize = async() =>{
    loadData();
  };
  const loadData = async() =>{
    await ApiMaterial.ListAll().then((data)=>{
      setMaterials(data)
    });
    await ApiWorkBlock.Get(blockID)
        .then(async(data) => {
          await setBlock(data);
          await setBlockLoaded(true);
    });

    let data = await ApiWorkBlock.GetPlannedSteps(blockID);
    await setBlockSteps(data);
    await setBlockStepsLoaded(true);
  };

  useEffect(() => {
    setMaterials(materialsList);
    if (!ablaufCreated) {
      initialize();
    }
  },[]);
  useEffect(()=>{
    if(blockStepsLoaded){
      createTable();
      setAblaufCreated(true);
    }
  },[blockStepsLoaded]);


  const newString = (someString: string) => {
    return JSON.parse(someString) as __PlannedStepsConverted;
  };
  const createTable = async () => {
    if (!blockSteps) return;
    let plannedStepsAsString: string = block ? block.plannedSteps : '';
    const myPlannedSteps: __PlannedStepsConverted = JSON.parse(plannedStepsAsString) as __PlannedStepsConverted;
    /**
     * Anodenbau S2S
     */
    if (myPlannedSteps.anodeConstructionSheetToSheet) {
        // anode construction exists -> edit
        if(block?.anodeConstruction) {
          await ApiAnodeConstruction.Get(block?.anodeConstruction.id)
          .then((data) => {
            setNewAblauf((prevAblauf) => [
              ...prevAblauf,
              {
                title: 'Anodenbau',
                notes: ['Sheet to Sheet'],
                status: blockSteps.anodeConstructionSheetToSheet.state
                  ? blockSteps.anodeConstructionSheetToSheet.state
                  : 'inPlanung',
                lineState:
                  blockSteps.anodeConstructionSheetToSheet.state === 'abgeschlossen'
                    ? 'completed'
                    : blockSteps.anodeConstructionSheetToSheet.state === 'aktiv'
                    ? 'in-progress'
                    : blockSteps.anodeConstructionSheetToSheet.state === 'gesperrt'
                    ? 'locked'
                    : 'upcoming',
                link: '/anodenbau/sheetToSheet',
                linkProps: { 
                  state: { 
                  workblockID: blockID, 
                  data: data
                }},
              },
            ]);
          });
        }
        // anode construction not exists -> create
        else {
          setNewAblauf((prevAblauf) => [
            ...prevAblauf,
            {
              title: 'Anodenbau',
              notes: ['Sheet to Sheet'],
              status: blockSteps.anodeConstructionSheetToSheet.state
                ? blockSteps.anodeConstructionSheetToSheet.state
                : 'inPlanung',
              lineState:
                blockSteps.anodeConstructionSheetToSheet.state === 'abgeschlossen'
                  ? 'completed'
                  : blockSteps.anodeConstructionSheetToSheet.state === 'aktiv'
                  ? 'in-progress'
                  : blockSteps.anodeConstructionSheetToSheet.state === 'gesperrt'
                  ? 'locked'
                  : 'upcoming',
              link: '/anodenbau/sheetToSheet',
              linkProps: { 
              state: { 
                workblockID: blockID,
                data: null, 
                selectedIndex: location.state.selectedIndex,
               }
              },
            },
          ]);
      //}
      }
    };

    /**
     * Anodenbau R2R
     */
    // anode construction r2r exists -> edit
    if (myPlannedSteps.anodeConstructionR2R !== undefined && myPlannedSteps.anodeConstructionR2R !== null) {
      let lengthOfR2R = myPlannedSteps.anodeConstructionR2R.length;
      if(block?.anodeConstructionR2R) {
          await ApiAnodeConstructionR2R.Get(block?.anodeConstructionR2R.id)
          .then((data) => {
          setNewAblauf((prevAblauf) => [
            ...prevAblauf,
            {
              title: 'Anodenbau',
              notes: [
                'Roll to Roll'
              ],
              status: blockSteps.anodeConstructionR2R.state
                ? blockSteps.anodeConstructionR2R.state
                : 'inPlanung',
              lineState:
                blockSteps.anodeConstructionR2R.state === 'abgeschlossen'
                  ? 'completed'
                  : blockSteps.anodeConstructionR2R.state === 'aktiv'
                  ? 'in-progress'
                  : blockSteps.anodeConstructionR2R.state === 'gesperrt'
                  ? 'locked'
                  : 'upcoming',
              link: '/anodenbau/rollToRoll',
              linkProps: { state:{
                    workblockID: blockID, 
                    workblockName: block?.name,
                    workpackageID: block?.workpackage.id,
                    workpackageName: block?.workpackage.name,
                    data: data, 
                    selectedIndex: location.state.selectedIndex,
                  }
              }
            },
          ]);
          })
      }
      // anode construction r2r not exists -> create 
      else {
        setNewAblauf((prevAblauf) => [
          ...prevAblauf,
          {
            title: 'Anodenbau',
            notes: [
              'Roll to Roll'
            ],
            status: blockSteps.anodeConstructionR2R.state
              ? blockSteps.anodeConstructionR2R.state
              : 'inPlanung',
            lineState:
              blockSteps.anodeConstructionR2R.state === 'abgeschlossen'
                ? 'completed'
                : blockSteps.anodeConstructionR2R.state === 'aktiv'
                ? 'in-progress'
                : blockSteps.anodeConstructionR2R.state === 'gesperrt'
                ? 'locked'
                : 'upcoming',
            link: '/anodenbau/rollToRoll',
            linkProps: { state:{
                  workblockID: blockID, 
                  workblockName: block?.name,
                  workpackageID: block?.workpackage.id,
                  workpackageName: block?.workpackage.name,
                  data: null, 
                  selectedIndex: location.state.selectedIndex,
                }
            }
          },
        ]);
      }
    };

    /**
     * Batteriebau
     */
    let hzConstruction : IBackendBatteryConstructionGet | null = null;
    let vzConstruction : IBackendBatteryConstructionGet | null = null;
    let construction = block?.batteryConstruction;

    /**Existing BatteryConstruction : Find Kind */
    if(construction && construction.length !== 0 ) {
      if(construction && construction.length >= 1) {
        let data0 = await ApiBatteryConstruction.Get(block?.batteryConstruction[0].id);
        if(data0.fixedBatteryData.typeID === "Halbzelle") {
          hzConstruction = data0;
        }
        else {
          vzConstruction = data0;
        }
      }
      if(construction && construction.length >= 2) {
        let data1 = await ApiBatteryConstruction.Get(block?.batteryConstruction[1].id);
        if(data1.fixedBatteryData.typeID === "Halbzelle") {
          hzConstruction = data1;
        }
        else {
          vzConstruction = data1;
        }
      }
    };

    // battery construction HZ exists -> edit
    if (myPlannedSteps.batteryConstruction?.HZ?.type) {
      setNewAblauf((prevAblauf) => [
        ...prevAblauf,
        {
          title: 'Batteriebau',
          notes: [
            'Halbzelle',
            'Anzahl HZ: ' + myPlannedSteps.batteryConstruction?.HZ?.number,
          ],
          status: blockSteps.batteryConstruction.HZ?.state
            ? blockSteps.batteryConstruction.HZ?.state
            : 'in-progress',
          lineState:
            blockSteps.batteryConstruction.HZ?.state === 'abgeschlossen'
              ? 'completed'
              : blockSteps.batteryConstruction.HZ?.state === 'aktiv'
              ? 'in-progress'
              : blockSteps.batteryConstruction.HZ?.state === 'gesperrt'
              ? 'locked'
              : 'upcoming',
          link: hzConstruction === null ? '/batteriebau/neu' : '/batteriebau/details',
          linkProps: { 
            state: { 
              workblockID: blockID, 
              data: hzConstruction === null 
                ? block?.batteryConstruction 
                : hzConstruction, 
              materials: materials,
              halbzellenList: halbzellenList,
              vollzellenList: vollzellenList,
              type: 'HZ'
            }
          },
        },
      ]);

    };
  
    // battery construction VZ exists -> edit
    if (myPlannedSteps.batteryConstruction?.VZ?.type) {

      setNewAblauf((prevAblauf) => [
        ...prevAblauf,
        {
          title: 'Batteriebau',
          notes: [
            'Vollzelle',
            'Anzahl VZ: ' + myPlannedSteps.batteryConstruction?.VZ?.number,
            'Anzahl PZ: ' + myPlannedSteps.batteryConstruction?.VZ?.numberPZ,
          ],
          status: blockSteps.batteryConstruction.VZ?.state
            ? blockSteps.batteryConstruction.VZ?.state
            : 'in-progress',
          lineState:
            blockSteps.batteryConstruction.VZ?.state === 'abgeschlossen'
              ? 'completed'
              : blockSteps.batteryConstruction.VZ?.state === 'aktiv'
              ? 'in-progress'
              : blockSteps.batteryConstruction.VZ?.state === 'gesperrt'
              ? 'locked'
              : 'upcoming',
          link: vzConstruction === null ? '/batteriebau/neu' : '/batteriebau/details',
          linkProps: { 
            state: { 
              workblockID: blockID, 
              data: vzConstruction === null 
                ? block?.batteryConstruction 
                : vzConstruction,
              materials: materials,
               halbzellenList: halbzellenList,
              vollzellenList: vollzellenList,
              type: 'VZ'
            } 
          },
        },
      ]);
      
    };


    
    /**
     * Batterietest
     */
    let hzTest : IBackendBatteryTestGet | null = null;
    let vzTest : IBackendBatteryTestGet | null = null;
    let test = block?.batteryTest;
    /**Existing Batterytest : Find Kind */
    if(test && test.length !== 0 ) {
      if(test && test.length >= 1) {
        let data0 = await ApiBatteryTest.Get(block?.batteryTest[0].id);
        if(data0.batteryTestWishes[0].type.name === "Halbzelle") {
          hzTest = data0;
        }
        else {
          vzTest = data0;
        }
      }
      if(test && test.length === 2) {
        let data1 = await ApiBatteryTest.Get(block?.batteryTest[1].id);
        if(data1.batteryTestWishes[0].type.name === "Halbzelle") {
          hzTest = data1;
        }
        else {
          vzTest = data1;
        }
      }
    };

    // battery test HZ exists -> edit
    if (myPlannedSteps.batteryTest?.HZ?.type) {
      setNewAblauf((prevAblauf) => [
        ...prevAblauf,
        {
          title: 'Batterietest',
          notes: ['Halbzelle'],
          status: blockSteps.batteryTest?.HZ.state
            ? blockSteps.batteryTest?.HZ.state
            : 'in-progress',
          lineState:
            blockSteps.batteryTest?.HZ.state === 'abgeschlossen'
              ? 'completed'
              : blockSteps.batteryTest?.HZ.state === 'aktiv'
              ? 'in-progress'
              : blockSteps.batteryTest?.HZ.state === 'gesperrt'
              ? 'locked'
              : 'upcoming',
          link: hzTest === null ? '/batterietest/neu' : '/batterietest/details',
          linkProps: {
            state: { 
              workblockID: blockID, 
              workblockName: block?.name,
              workpackageID: block?.workpackage.id,
              workpackageName: block?.workpackage.name,
              user: [responsibleUser], 
              data: hzTest === null ? block?.batteryTest : hzTest, 
              //batteries: batteries,
              halbzellenList: halbzellenList,
              vollzellenList: vollzellenList,
              type: 'HZ'
             },
          },
        },
      ]);

    };

    // battery test VZ exists -> edit
    if (myPlannedSteps.batteryTest?.VZ?.type) {
      setNewAblauf((prevAblauf) => [
        ...prevAblauf,
        {
          title: 'Batterietest',
          notes: ['Vollzelle'],
          status: blockSteps.batteryTest?.VZ.state
            ? blockSteps.batteryTest?.VZ.state
            : 'inPlanung',
          lineState:
            blockSteps.batteryTest?.VZ.state === 'abgeschlossen'
              ? 'completed'
              : blockSteps.batteryTest?.VZ.state === 'aktiv'
              ? 'in-progress'
              : blockSteps.batteryTest?.VZ.state === 'gesperrt'
              ? 'locked'
              : 'upcoming',
          link: vzTest === null ? '/batterietest/neu' : '/batterietest/details',
          linkProps: {
            state: { 
              workblockID: blockID,
              workblockName: block?.name,
              workpackageID: block?.workpackage.id,
              workpackageName: block?.workpackage.name,
              user: [responsibleUser], 
              data: vzTest === null ? block?.batteryTest : vzTest,
              //batteries: batteries,
              halbzellenList: halbzellenList,
              vollzellenList: vollzellenList,
              type: 'VZ'
            },
          },
        },
      ]);
    };
    
    /**
     * Charakterisierung
     * Umbenennungen:
        Hafttest -> Sonstiges
        Post Mortem -> PM Foto
        Raman -> PM REM
        Mikroskopie -> LiMi
     */
    if (myPlannedSteps.analysis) {
      let notes = [
        myPlannedSteps.analysis.microscopy ? 'LiMi' : '',
        myPlannedSteps.analysis.rem ? 'REM' : '',
        myPlannedSteps.analysis.xrd ? 'XRD' : '',
        myPlannedSteps.analysis.raman ? 'PM REM' : '',
        myPlannedSteps.analysis.postMortemAnalysis ? 'PM Foto' : '',
        myPlannedSteps.analysis.adhesionTest ? 'Sonstiges' : '',
      ];
      let adjustedCharacterlist:string[] = [];
      let characteristicID:string = "";
      let dataCharacteristic : any = null;

      //get characteristic ID
      await ApiWorkBlock.Get(blockID).then((data)=>{
        dataCharacteristic = data.characteristics;
        characteristicID = data.characteristics?.id ?? '';
        adjustedCharacterlist.push(characteristicID);
        adjustedCharacterlist.push(blockID);//actually should be name??
        let characteristicsList:string = notes.join(', '); 
        adjustedCharacterlist.push(characteristicsList);
      })
      setNewAblauf((prevAblauf) => [
        ...prevAblauf,
        {
          title: 'Charakterisierung',
          notes: notes,
          status: blockSteps.analysis.state
            ? blockSteps.analysis.state
            : 'inPlanung',
          lineState:
            blockSteps.analysis.state === 'abgeschlossen'
              ? 'completed'
              : blockSteps.analysis.state === 'aktiv'
              ? 'in-progress'
              : blockSteps.analysis.state === 'gesperrt'
              ? 'locked'
              : 'upcoming',
          link: '/charakterisierung/ablauf',
          linkProps: { 
            state: { 
              workblockID: blockID,
              workblockName: block?.name,
              workpackageID: block?.workpackage.id,
              workpackageName: block?.workpackage.name,
              characteristicsList: adjustedCharacterlist,
              characteristicID: characteristicID,
              characteristic: dataCharacteristic
          } 
        },
        },
      ]);
    }
  };
  
  if (!blockLoaded ?? !blockStepsLoaded) {
    return <></>;
  } else {
    return (
      <>  
          <Ablaufdiagramm ablauf={newAblauf} disableLinks={false}/>
      </>
    );
  }
}
