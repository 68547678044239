import './App.scss';

import { BrowserRouter, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
//import Notfound from './pages/notfound/notfound'
import Home from './pages/Home/home';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Auth/Login';
import Header from './components/Header';
import logo from './assets/Logo.png';
import Sidebar from './components/Sidebar';

import ArbeitsbloeckeUebersicht from './pages/Arbeitsbloecke/ArbeitsbloeckeUebersicht';
import ArbeitsblockDetail from './pages/Arbeitsbloecke/ArbeitsblockDetail';
import ArbeitsblockNeu from './pages/Arbeitsbloecke/ArbeitsblockNeu';
import ArbeitsblockErgaenzung from './pages/Arbeitsbloecke/ArbeitsblockErgaenzung';

import ArbeitspaketDetail from './pages/Arbeitspakete/ArbeitspaketDetail';
import ArbeitspaketNeu from './pages/Arbeitspakete/ArbeitspaketNeu';
import ArbeitspaketUebersicht from './pages/Arbeitspakete/ArbeitspaketUebersicht';
import ArbeitspaketUeberpruefen from './pages/Arbeitspakete/ArbeitspaketUeberpruefen';
import ArbeitspaketZusammenfassung from './pages/Arbeitspakete/ArbeitspaketZusammenfassung';

import AnodenbauRollToRoll from './pages/Anodenbau/AnodenbauRollToRoll';
import AnodenbauSheetToSheet from './pages/Anodenbau/AnodenbauSheetToSheet';
import Anodenbau from './pages/Anodenbau/Anodenbau';

import Batteriebau from './pages/Batteriebau/Batteriebau';

import BatteriebauNeu from './pages/Batteriebau/BatteriebauNeu';
import BatterietestNeu from './pages/Batterietest/BatterietestNeu';
import BatterietestDetails from './pages/Batterietest/BatterietestDetails';
import Batterietest from './pages/Batterietest/Batterietest';

import Benutzerverwaltung from './pages/Benutzerverwaltung/Benutzerverwaltung';

import Charakterisierung from './pages/Charakterisierung/Charakterisierung';
import CharakterisierungAblauf from './pages/Charakterisierung/CharakterisierungAblauf';
import REM from './pages/Charakterisierung/REM';
import XRD from './pages/Charakterisierung/XRD';

import Inventar from './pages/Inventar/Inventar';
import NeuesMaterial from './pages/Inventar/NeuesMaterial';
import EditMaterials from './pages/Inventar/MaterialDetails';
import Raman from './pages/Charakterisierung/Raman';
import Mikroskopie from './pages/Charakterisierung/Mikroskopie';
import PostMortemVisuell from './pages/Charakterisierung/PostMortemVisuell';
import Hafttest from './pages/Charakterisierung/Hafttest';
import ArbeitspaketPlanung from './pages/Arbeitspakete/ArbeitspaketPlanung';
import BatteriebauDetail from './pages/Batteriebau/BatteriebauDetail';
import { ApiAuth } from './backend/ApiAuth';
import { IBackendUser } from './backend/types/__ResponseStructures';
import GesamtUebersicht from './pages/Gesamtuebersicht/GesamtUebersicht';
import BatterietestDetailWunsch from './pages/Batterietest/BatterietestDetailWunsch';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const hideNavbar = location.pathname === '/login';

  const [currentUser, setCurrentUser] = useState<IBackendUser|null>(null);

  useEffect(() => {
    // Eine Funktion, die den aktuellen Benutzer von einem Server oder einem anderen Ort abruft
    ApiAuth.GetMyself()
    .then(data => 
      setCurrentUser(data)
    )
    .catch(
      _=>{}
    );
  }, []);

  const logout = () => {
    setCurrentUser(null);
    ApiAuth.Logout()
    .then(data => {
      localStorage.removeItem('token');
      navigate('/login',{replace:true});
    });
  }
  const handleLogin = (newUser:IBackendUser) => {
    setCurrentUser(newUser);
  }

  return (
    <>
      {hideNavbar ? <></> : <Header image={logo} currentUser={currentUser} logoutHandler={logout} />}
      <div className='sidebar-content-container '>
        {hideNavbar ? <></> : <Sidebar currentUser={currentUser} />}
        <Routes>
          <Route
            path='/arbeitsbloecke/details'
            element={<ArbeitsblockDetail />}
          />
          <Route path='/arbeitsbloecke/neu' element={<ArbeitsblockNeu />} />
          <Route
            path='/arbeitsbloecke/ergaenzung'
            element={<ArbeitsblockErgaenzung />}
          />
          <Route
            path='/arbeitsbloecke'
            element={<ArbeitsbloeckeUebersicht />}
          />
          <Route
            path='/arbeitspakete/details'
            element={<ArbeitspaketDetail />}
          />
          <Route path='/arbeitspakete/neu' element={<ArbeitspaketNeu />} />
          <Route
            path='/arbeitspakete/pruefen'
            element={<ArbeitspaketUeberpruefen />}
          />
          <Route
            path='/arbeitspakete/zusammenfassung'
            element={<ArbeitspaketZusammenfassung />}
          />
          <Route path='/arbeitspakete' element={<ArbeitspaketUebersicht />} />
          <Route path='/planung' element={<ArbeitspaketPlanung />} />
          <Route
            path='/anodenbau/rollToRoll'
            element={<AnodenbauRollToRoll />}
          />
          <Route
            path='/anodenbau/sheetToSheet'
            element={<AnodenbauSheetToSheet />}
          />
          <Route path='/anodenbau' element={<Anodenbau />} />
          <Route path='/batteriebau/neu' element={<BatteriebauNeu />} />
          <Route path='/batteriebau/details' element={<BatteriebauDetail />} />
          <Route path='/batteriebau' element={<Batteriebau />} />
          <Route
            path='/batterietest/neu'
            element={<BatterietestNeu />}
          />
          <Route
            path='/batterietest/details'
            element={<BatterietestDetails />}
          />
          <Route
            path='/batterietest/detailswunsch'
            element={<BatterietestDetailWunsch />}
          />
          <Route path='/batterietest' element={<Batterietest />} />
          <Route path='/benutzer' element={<Benutzerverwaltung />} />
          <Route
            path='/charakterisierung/ablauf'
            element={<CharakterisierungAblauf />}
          />
          <Route path='/charakterisierung/hafttest' element={<Hafttest />} />
          <Route
            path='/charakterisierung/mikroskopie'
            element={<Mikroskopie />}
          />
          <Route
            path='/charakterisierung/postMortemVisuell'
            element={<PostMortemVisuell />}
          />
          <Route path='/charakterisierung/raman' element={<Raman />} />
          <Route path='/charakterisierung/rem' element={<REM />} />
          <Route path='/charakterisierung/xrd' element={<XRD />} />
          <Route path='/charakterisierung' element={<Charakterisierung />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/login' element={<Login handleLogin={handleLogin}/>} />
          <Route path='/material/neu' element={<NeuesMaterial />} />
          <Route path='/material' element={<Inventar />} />
          <Route path='material/details' element={<EditMaterials/>}/>
          <Route path='/' element={<Home />} />
          <Route path='/gesamtuebersicht' element={<GesamtUebersicht/>}/>
        </Routes>
      </div>
    </>
  );
}

export default App;
