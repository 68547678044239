import { ChangeEvent,useEffect, useState } from 'react';
import Ablaufdiagramm from '../../components/Ablaufdiagramm';
import { useNavigate, useLocation,NavigateOptions } from 'react-router-dom';
import { ApiCharacteristics } from '../../backend/ApiCharacteristics';
import { __FileResponse, __PlannedStepsConverted } from '../../backend/types/__GeneralStructures';
import FileUploadAccordeon from '../../components/FileUpload';
import { ApiFile } from '../../backend/ApiFiles';
import { ApiBackendData } from '../../backend/ApiBackendData';
import { IBackendCharacteristics } from '../../backend/types/__ResponseStructures';
import { useUserList } from '../../hooks/UserHooks';

export default function CharakterisierungAblauf() {
  const navigate = useNavigate();
  const location = useLocation();

  const [userListDict,dataLoaded] = useUserList();

  const [files, setFiles] = useState<__FileResponse[]>([]);
  const [characteristic, setCharacteristic] = useState<IBackendCharacteristics|null>(null)
  // const [openModalAccordion, setOpenModalAccordion] = useState(false);
  // let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  // const [newStateFailed, setNewStateFailed] = useState(false);
  // const [modalInfoText, setModalNavigateText] = useState('');
  const [initialize, setInitialize] = useState(false);
  const [characteristicArray, setCharacteristicArray] = useState<{
    title: string;
    notes: string[];
    status: string;
    lineState: string;
    link: string;
    linkProps:NavigateOptions;
  }[]>([])
  const [inputs, setInputs] = useState(
    characteristic 
      ? {state: characteristic.state.name}
      : {state: 'aktiv'}
  );


  useEffect(() => {
    document.title = 'Charakterisierung';
    loadData();
  },[]);

  useEffect(()=>{
    loadData();
  },[inputs]);

  const loadData = () =>{
    if(characteristic === null) {
      ApiCharacteristics.Get(location.state.characteristicID)
      .then(async (data) => {
        const myPlannedSteps = JSON.parse(data.workblock.plannedSteps) as __PlannedStepsConverted;
        await setCharacteristic(data);
        const fileList = data.files?? []
        setFiles(fileList);
        await setInputs({state:data.state.name});
        setInitialize(true);

        let newCharacteristicArray : any[] = [];
        location.state.characteristicsList[2]
        .split(', ')
        .forEach((characteristic: string) => {
          let link = '';
          let typeOfCharacteristicData: any = null;
          let state = 'upcoming';
          let addLink = true;
          /**
           * Charakterisierung
           * Umbenennungen:
              Hafttest -> Sonstiges
              Post Mortem -> PM Foto
              Raman -> PM REM
              Mikroskopie -> LiMi
          */
          switch (characteristic) {
            case 'Sonstiges':
              link = 'hafttest';
              typeOfCharacteristicData = data.adhesionTest;
              if(data.adhesionTest || myPlannedSteps.analysis?.adhesionTest) {
                state = data.adhesionTest ? data.adhesionTest.stateID:  'inPlanung';
              }
              break;
            case 'LiMi':
              link = 'mikroskopie';
              typeOfCharacteristicData = data.microscopy;
              if(data.microscopy || myPlannedSteps.analysis?.microscopy) {
                state = data.microscopy ? data.microscopy.stateID:  'inPlanung';
              }
              break;
            case 'REM':
              link = 'rem';
              typeOfCharacteristicData = data.rem;
              if(data.rem || myPlannedSteps.analysis?.rem) {
                state = data.rem ? data.rem.stateID:  'inPlanung';
              }
              break;
            case 'XRD':
              link = 'xrd';
              typeOfCharacteristicData = data.xrd;
              if(data.xrd || myPlannedSteps.analysis?.xrd) {
                state = data.xrd ? data.xrd.stateID:  'inPlanung';
              }
              break;
            case 'PM REM':
              link = 'raman';
              typeOfCharacteristicData = data.raman;
              if(data.raman || myPlannedSteps.analysis?.raman) {
                state = data.raman ? data.raman.stateID:  'inPlanung';
              }
              break;
            case 'PM Foto':
              link = 'postMortemVisuell';
              typeOfCharacteristicData = data.postMortemAnalysis;
              if(data.postMortemAnalysis || myPlannedSteps.analysis?.postMortemAnalysis) {
                state = data.postMortemAnalysis ? data.postMortemAnalysis.stateID:  'inPlanung';
              }
              break;
            case ' ':
              addLink = false;
              break;
            case '':
              addLink = false;
              break;
          }
          /*
          
              status: blockSteps.analysis.state
                ? blockSteps.analysis.state
                : 'inPlanung',
              lineState:
                blockSteps.analysis.state === 'abgeschlossen'
                  ? 'completed'
                  : blockSteps.analysis.state === 'aktiv'
                  ? 'in-progress'
                  : blockSteps.analysis.state === 'gesperrt'
                  ? 'locked'
                  : 'upcoming',

                  
              status: 'upcoming',
              lineState: 'upcoming',
          */
          if(addLink){
            newCharacteristicArray.push({
              title: characteristic,
              notes: [],
              status: state ? state : 'inPlanung',
              lineState: state === 'abgeschlossen'
                  ? 'completed'
                  : state === 'aktiv'
                  ? 'in-progress'
                  : state === 'gesperrt'
                  ? 'locked'
                  : 'upcoming',
              link: `/charakterisierung/${link}`,
              linkProps:{
                state:{
                  workblockID: location.state.workblockID,
                  workblockName: location.state.workblockName,
                  workpackageID: location.state.workpackageID,
                  workpackageName: location.state.workpackageName,
                  characteristicsID: location.state.characteristicID,
                  data : typeOfCharacteristicData
                }
                
              }
            });
          }
        });
        
        setCharacteristicArray(newCharacteristicArray);
      })
    }
  }
  // const handleModalOpenAccordion = (displayModal: React.ReactNode) => {
  //   setOpenModalAccordion(!openModalAccordion);
  // };
  const handleReloadPage = async () => {
    console.log('reload')
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  // const handleInfoModaClose = () =>{
  //   setNewStateFailed(false);
  //   let overlay = document.getElementById('overlay-root');
  //   if(overlay){
  //     overlay.style.display = 'none';
  //   }
  // }
  // const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const { name, value } = event.target;
  //   if(characteristic){
  //     let requestObject = {id:characteristic.id}
  //   switch (value){
  //     case 'aktiv': 
  //       ApiCharacteristics.Activate(requestObject)
  //         .then((data)=>{
  //           setCharacteristic(null);
  //           setInputs({ ...inputs, [name]: value });
  //         })
  //         .catch(()=>{
  //           setModalNavigateText('Aktualisierung des Status ist fehlgeschlagen.');
  //           setNewStateFailed(true);
  //         });
  //       break;
  //     case 'abgeschlossen':
  //       ApiCharacteristics.Close(requestObject)
  //         .then((data)=>{
  //           setInputs({ ...inputs, [name]: value });
  //         })
  //         .catch(()=>{
  //           setModalNavigateText('Aktualisierung des Status ist fehlgeschlagen.');
  //           setNewStateFailed(true);
  //         });
  //       break;
  //     case 'gesperrt':
  //       ApiCharacteristics.Lock(requestObject)
  //       .then((data)=>{
  //         setInputs({ ...inputs, [name]: value });
  //       })
  //       .catch(()=>{
  //         setModalNavigateText('Aktualisierung des Status ist fehlgeschlagen.');
  //         setNewStateFailed(true);
  //       });
  //       break;
  //     case 'gelöscht':
  //       ApiCharacteristics.Delete(requestObject)
  //       .then((data)=>{
  //         setInputs({ ...inputs, [name]: value });
  //       })
  //       .catch(()=>{
  //         setModalNavigateText('Aktualisierung des Status ist fehlgeschlagen.');
  //         setNewStateFailed(true);
  //       });
  //       break;
  //     }
  //   }
  // };
  const submitModalCloseAccordion = (response:FormData)  =>{
    sendFileData(response);
    // send formdata to backend
    return response;
  };
  const sendFileData = async(data:FormData) =>{
    if(characteristic){
      await ApiFile.Upload(data,'/characteristics',characteristic.id);
    }
  };
  // TODO: Add button to go to Arbeitspakete
  return (
    <div className='content-main-container'>
    {/* {newStateFailed ? (
      <Modal
        handleModal={handleInfoModaClose}
        content = {
          <>
            <div className='confirmation-modal'>
              <p>{modalInfoText}</p>
                <div className='row'>
                <input
                  type='button'
                  name='ok'
                  id='ok'
                  value={'ok'}
                  className='btn-main margin-top-15 margin-left-15'
                  onClick={event => handleInfoModaClose()}
                />
              </div>
            </div>
          </>
        }
      />
      ) : (
      <></>
    )}  */}
    {initialize ?
      <>
        <h1 className='margin-bottom-25'>Charakterisierung</h1>
        <div className='row'>
          <form action='' className='margin-top-15'>
            <div className='col-6'>
              <div className='row'>
                  <p className='h5 fw-bold'>Arbeitspaket: {location.state.workpackageName ?? ''}</p>
              </div>
              <div className='row'>
                <p className='h5 fw-bold'>Arbeitsblock: {location.state.workblockName ?? ''}</p>
              </div>
            </div>
            {/*<div className='row my-3'>
              <div className='col-3'>
                <label htmlFor='state'>Status:</label>
                <div style={{ visibility: 'hidden', height: 0 }}>
                  <InputField
                    value={inputs.state}
                    inputType='text'
                    name='state'
                    placeholder=''
                    changeHandler={handleChange}
                    inputClass='text-md border-bottom-primary margin-bottom-10'
                  />
                </div>
                <select
                  name='state'
                  value={inputs.state}
                  onChange={(event) => handleOptionChange(event)}
                  className='input-lg text-md border-bottom-primary margin-top-5'
                >
                  {enums.WorkBlockState.map((option: string) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
                </div>*/}
          </form>
        </div>
        <div className='row'>
          {<Ablaufdiagramm ablauf={characteristicArray} disableLinks={false}/> }
        </div>
        <div className='row mt-2'>
          <div className='col'>
            <input
              type='button'
              value='Zurück'
              className='btn-main margin-top-25'
              onClick={() => navigate(-1)}
            />
          </div>
          <div className='col'>
            {false?
              <div className='margin-top-20 paket-information-container row'>
                <div className='column'>
                  <FileUploadAccordeon
                    handleConfirm={submitModalCloseAccordion}
                    handleReloadPage={handleReloadPage}
                    files={files}
                  />
                </div>
              </div>
              :<></>
            }
          </div>
        </div>
      </>
    :<></>
    }
    </div>
  );
}
