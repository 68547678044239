import { ChangeEvent,useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
//import { enums } from '../../data';
import { ApiBackendData } from '../../backend/ApiBackendData';
import InputField from '../../UI/InputField';
import { TypeofCharacteristic, __PostMortemAnalysisBatteryDataConverted, __PostMortemAnalysisResponse, __FileResponse } from '../../backend/types/__GeneralStructures';
import { ApiCharacteristics } from '../../backend/ApiCharacteristics';
import { __PostMortemAnalysisCreate, __PostMortemAnalysisEdit } from '../../backend/types/__RequestStructures';
import { ApiBatteryConstruction } from '../../backend/ApiBatteryConstruction';
import Config from '../../util/Config';
import { ConfirmationModal, InfoModal, NavigationModal } from '../../components/Modal';
import Loading from '../../components/Ladebalken';
import FileUploadAccordeon from '../../components/FileUpload';
import { ApiFile } from '../../backend/ApiFiles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useUserList } from '../../hooks/UserHooks';


type InputElementTable = {
    id: string,
    number: string,
    batterieName: string,
    batterieID:string,
    maeanderAuspraegung: string,
    farbbildAnode: string,
    zustandAnode: string,
    zustandKath: string,
    zustandSep: string,
    comment: string,
    [key: string]: string; // add an index signature
};
interface CreateTableEntryProps {
  inputElement: any;
  index: number;
  keyName: string;
  subIndex: number;
  handleChangeTable: (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
};
const createChangeHandler =
  <T extends { [key: string]: string }>(
    table: T[],
    setInputs: React.Dispatch<React.SetStateAction<T[]>>
  ) =>
  (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    const list = [...table];
    list[index] = { ...list[index], [name]: value };
    setInputs(list);
  };

export function CreateTableEntry({
  inputElement,
  index,
  keyName,
  handleChangeTable,
}: CreateTableEntryProps) {
  return (
    <InputField
      inputType='text'
      name={keyName}
      placeholder={''}
      value={inputElement}
      changeHandler={(event) => handleChangeTable(event, index)}
      disabled={false}
      inputClass='text-center   cut-text-overflow margin-top-5'
    />
  );
};
/**
   * Charakterisierung
   * Umbenennungen:
      Hafttest -> Sonstiges
      Post Mortem -> PM Foto
      Raman -> PM REM
      Mikroskopie -> LiMi
  */
export default function PostMortemVisuell() {
  // call Apis for basic data
  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  const navigate = useNavigate();
  const location = useLocation();
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [initialized, setInitialization] = useState(false);
  const characteristicsID:string = location.state.characteristicsID;
  const [userListDict, userDataLoaded] = useUserList();

  const [characteristic, setCharacteristicData] = useState<__PostMortemAnalysisResponse>({
    id: '',
    platformID: '',
    responsibleUser: [],
    date: '',
    stateID: '',
    characteristicID: '',
    analysis:[],
    files: []
  });
  const [files, setFiles] = useState<__FileResponse[]>([]);
  const [loadFiles, setLoadFiles] = useState(false);

  let currentFullDate = new Date();
  let month: number|string = currentFullDate.getMonth()+1;
  let day: number|string = currentFullDate.getDay() +1;
  if(day< 10){
    day = '0'+day.toString();
  }
  else{
    day = day.toString();
  }
  if (month < 10){
    month = '0' + month.toString();
  }
  else{
    month = month.toString();
  }
  let cur =  [currentFullDate.getFullYear().toString(), month,day].join('-');
  const [currentDate, setCurrentDate] = useState<string>(cur);
  const [inputsHeader,setInputsHeader] = useState(
    {workBlockID: location.state.workblockName 
      ? location.state.workblockName
      : '',
    packageID: location.state.workpackageName
      ? location.state.workpackageName
      : '',
    responsibleUser: location.state.data !== null
      ? location.state.data.responsibleUser[0].displayName
      : '',
    userList: [''],
    status: location.state.data 
      ? location.state.data.state ? 
        location.state.data.state.name
        : enums.WorkBlockState[0]
      : enums.WorkBlockState[0],
    date: currentDate,
    }
  );
  const [listOfBatteries, setBatteryList] = useState<{ id: string; name: string }[]>([]);
  
  const [deletedData, setDeletedData] = useState<string[]>([]);
  const [inputsTable, setInputsTable] = useState<InputElementTable[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [validatedInputs, setValidatedInputs] = useState(false);

  //Handle Functions
  const createInitialTableData = (test:__PostMortemAnalysisResponse): InputElementTable[] => {
    let idx = 0;
    let filteredTemp = test.analysis.filter(x => x.batteryTake !== null);
    let temp:Array<InputElementTable> = filteredTemp.map((data: any) => {
      idx += 1;
      return {
        id: data.id?? '',
        number: idx.toString(),
        batterieName: data.batteryTake.name?? '',
        batterieID: data.batteryTake.id ?? '',
        maeanderAuspraegung: data.meanderExpresssion.name,
        farbbildAnode: data.colorImgAnode ? 'Ja' : 'Nein',
        zustandAnode: data.stateAnodeID,
        zustandKath: data.stateCathodeID,
        zustandSep: data.stateSeparatorID,
        comment: data.annotation,
      }
    });
    return temp;
  };
  const loadData = async() =>{
    // get data for post mortem analysis
    await ApiCharacteristics.Get(characteristicsID).then(async(data)=>{
      if(data.postMortemAnalysis){
        let date = currentDate;
        if(data.postMortemAnalysis.analysis.length > 0 ){
          let temp = new Date(data.postMortemAnalysis.analysis[0].date.toString());
          let month: number|string = temp.getMonth();
          let day: number |string = temp.getDay();
          if(month<10){
            month = '0'+month.toString();
          }
          else{
            month = month.toString();
          }
          if(day < 10){
            day = '0' + day.toString();
          }
          else{
            day = day.toString();
          }
          date =  [temp.getFullYear().toString(), month,day].join('-');
        }
        let testData:__PostMortemAnalysisResponse = {
          id: data.postMortemAnalysis.id,
          platformID: data.postMortemAnalysis.platformID,
          responsibleUser: data.postMortemAnalysis.responsibleUser,
          date: date,
          stateID: data.postMortemAnalysis.stateID,
          characteristicID: data.postMortemAnalysis.characteristicID,
          analysis: data.postMortemAnalysis.analysis,
          files: data.postMortemAnalysis.files ?? []
        }
        let respUser = testData.responsibleUser.map(x => x.displayName) ?? [userListDict[0].displayName] ;
        setCharacteristicData(testData);

        let initialData = await createInitialTableData(testData);
        setInputsTable(initialData);
        let inputsHeaderWithUserList = {...inputsHeader};
        inputsHeaderWithUserList.userList = respUser;
        inputsHeaderWithUserList.status = data.postMortemAnalysis.stateID ?? enums.WorkBlockState[0];
        inputsHeaderWithUserList.responsibleUser = respUser[0];
        inputsHeaderWithUserList.date = data.postMortemAnalysis.analysis.length > 0 ? (data.postMortemAnalysis.analysis[0].date.toString()).split('T')[0] : currentDate;
        setInputsHeader(inputsHeaderWithUserList);
      }
      else{
        let inputsHeaderWithUserList = {...inputsHeader};
        inputsHeaderWithUserList.userList = [userListDict[0].displayName];
        inputsHeaderWithUserList.responsibleUser = userListDict[0].displayName;
        setInputsHeader(inputsHeaderWithUserList); 
      }
    });
    await ApiBatteryConstruction.ListBatteries().then(async(data)=>{
      let list:{id:string,name:string}[] = [];
      for(let item of data){
        let key = item.name;
        let value = item.id
        list.push({
          id:value,
          name: key
        });
      };
      if(list.length < 1){
        //no battery exists, navigate to batterie construction or back
        let errorModalBattery = document.getElementById("ErrorMissingBatterie");
        if(!errorModalBattery) return;
        $('#ErrorMissingBatterie').modal('show');
      }
      await setBatteryList(list);
    });
    setInitialization(true);
    setOpenLoadingModal(false);
    await validateInputFields();
  };
  const loadFileData = async()=>{
    await ApiCharacteristics.Get(characteristicsID).then((data)=>{
      if(data.postMortemAnalysis){
        setFiles(data.postMortemAnalysis?.files ?? []);
      }
    });
    setLoadFiles(true);
  };
  const validateInputFields = () => {
    let batterynamesList:string[] = [];
    for(let name of inputsTable.map(x=>x.batterieName)){
      if(batterynamesList.includes(name)){
        setValidatedInputs(false);
        return
      }
      batterynamesList.push(name);
    } 
    for(let entry of inputsTable) {
      if(entry.batterieID == ""){
        setValidatedInputs(false);
        return;
      }
      if(entry.batteriename == ""){
        setValidatedInputs(false);
        return;
      }
    }
    setValidatedInputs(true);
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputsHeader((values) => ({ ...values, [name]: value }));
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    let inputs = {...inputsHeader};
    if(name === 'responsibleUser'){
      inputs.userList.push(value);
      inputs.responsibleUser = value;
      setInputsHeader(inputs);
    }
    else if(name === 'status' && value === 'abgeschlossen'){
      let newInputs = {...inputsHeader};
      let currentFullDate = new Date();
      let month: number|string = currentFullDate.getMonth()+1;
      if (month < 10){
        month = '0' + month.toString();
      }
      else{
        month = month.toString();
      }
      let date =  [currentFullDate.getFullYear().toString(), month,currentFullDate.getDate().toString()].join('-');
      newInputs.date = date
      newInputs.status = value;
      setInputsHeader(newInputs);
    }
    else{
      setInputsHeader((prevState) => ({ ...prevState, [name]: value}));
    }
  };
  const handleOptionChangeTable = (event: React.ChangeEvent<HTMLSelectElement>,index:number) => {
    setSelectedOption(event.target.value);
    setSelectedOption(selectedOption);
    const { name, value } = event.target;
    setInputsTable(inputsTable.map((input, i) =>
        i === index ? { ...input, [name]: value } : input
      )
    );
  };
  const handleOptionChangeTableBattery = (value:string, index: number, id:string) => {
    const name = 'batterieName';
    if(id === ''){
      if(listOfBatteries.map(x => x.name).includes(value)){
        let battery = listOfBatteries[listOfBatteries.map(x => x.name).indexOf(value)];
        id=battery.id;
      }
    }
    setInputsTable(inputsTable.map((input, i) =>
      i === index ? { ...input, [name]: value,['batterieID'] :id } : input
      )
    );
  };
  const handleInputChangeTable = (
    event: ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    setInputsTable((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index ? { ...input, [name]: value } : input
      )
    );
  };
  const handleAddVisuell = () => {
    const emptyTable: InputElementTable ={
      id: '',
      number: (1).toString() ?? '0',
      batterieName: '',
      batterieID: '',
      maeanderAuspraegung: enums.MeanderExpressionState[0],
      farbbildAnode: 'Ja',
      zustandAnode: enums.PmAnalysisState[0],
      zustandKath: enums.PmAnalysisState[0],
      zustandSep: enums.PmAnalysisState[0],
      comment: '',
  };
    let newTable = JSON.parse(JSON.stringify(emptyTable)); // copy
    newTable.number = (inputsTable.length + 1).toString();

    setInputsTable([...inputsTable, newTable]);
  };
  const handleSave = () => {
    setOpenLoadingModal(true);
    let user:string[] = inputsHeader.userList.map(name => userListDict[userListDict.map(x=>x.displayName).indexOf(name)].loginName);
    let lastUserLoginName = user[user.length-1];

    let temp: __PostMortemAnalysisBatteryDataConverted[] = inputsTable.map((items)=>{
      let newDate = new Date(inputsHeader.date); 
      return{
          id: items.id?? '',
          responsibleUser: user[0] ?? userListDict[0].loginName,
          date: newDate,
          battery: items.batterieID !== '' ? items.batterieID : listOfBatteries[0].id,
          meanderExpression: items.maeanderAuspraegung ?? enums.MeanderExpressionState[0],
          colorImgAnode: items.farbbildAnode === 'Ja',
          stateAnode: items.zustandAnode?? enums.PmAnalysisState[0],
          stateCathode: items.zustandKath??enums.PmAnalysisState[0],
          stateSeparator: items.zustandSep?? enums.PmAnalysisState[0],
          annotation: items.comment
        }
      });
    if(characteristic.id && characteristic.id!== ''){
      let requestData: __PostMortemAnalysisEdit ={
        id: characteristic.id ?? '',
        date: new Date(inputsHeader.date),
        changes:{
          evaluation: null, /////,
          state: inputsHeader.status,
          responsibleUser: lastUserLoginName ?? userListDict[0].loginName,
        },
        analysis: temp,
        deletedData: deletedData
      }
      ApiCharacteristics.EditPostMortemAnalysis(requestData)
      .then(()=>{
        setInitialization(false);
        loadData();
      })
      .catch(e=>{
        console.log(e);
      });
    }
    else{
      let requestData: __PostMortemAnalysisCreate = {
        platform: Config.Platform,
        characteristicID: characteristicsID,
        postMortem:{
          evaluation: null, /////,
          state: inputsHeader.status,
          responsibleUser: lastUserLoginName,
        },
        analysis: temp
      }
      ApiCharacteristics.CreatePostMortemAnalysis(requestData)
      .then(()=>{
        setInitialization(false);
        loadData();
      })
      .catch(e=>{
        console.log(e);
      });
    }
  };
  const handleGenerateDocument = () => {
    setOpenLoadingModal(true);
    let id = characteristicsID; // the active characteristics data
    ApiCharacteristics.GeneratePdf({id:id, type: TypeofCharacteristic.postmortem}).then((url) => {
      window.open(url, '_blank');
      setOpenLoadingModal(false);
    })
    .catch((e:unknown)=>{
      setOpenLoadingModal(false);
      //open error modal to show fail
      let errorModal = document.getElementById("ErrorDownloadFailed");
      if(!errorModal) return;
      $('#ErrorDownloadFailed').modal('show');
    });
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleReloadPage = async () => {
    setLoadFiles(false);
  };
  const submitModalCloseAccordion = (response:FormData)  =>{
    sendFileData(response);
    // send formdata to backend
    return response;
  };
  const sendFileData = async(data:FormData) =>{
    const id = characteristic.id;
    if(characteristic.id !== ''){
      await ApiFile.UploadFileForCharacteristics(data,'/characteristics/uploadpostmortemanalysis',id).then(()=>{setLoadFiles(false);});
    }
  };
  
  //Use Effects
  useEffect(() => {
    document.title = 'PM Foto';
  },[]);
  useEffect(()=>{
    setCurrentDate(cur);
    if(characteristicsID && !initialized && userDataLoaded){
     setOpenLoadingModal(true);
     loadData();
    }
    if(characteristicsID && !loadFiles){
     loadFileData();
   }
  },[userDataLoaded]);
  useEffect(()=>{
    loadFileData();
  },[loadFiles]);
  useEffect(() => {
    validateInputFields();
  }, [inputsTable]) // Aktualisiert immer dann, wenn sich batteryData ändert

  return (
    <div className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
       
      {initialized ?
        <>
          <h1>PM Foto</h1>
          <form action='' className='margin-top-15'>
            <div className='col-6'>
              <div className='row ps-1'>
                  <p className='h6'>Arbeitspaket: {inputsHeader.packageID}</p>
                </div>
                <div className='row ps-1'>
                <p className='h6'>Arbeitsblock: {inputsHeader.workBlockID}</p>
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col'>
                <label htmlFor='responsibleUser'>Verantwortlicher:</label>
                <div style={{ visibility: 'hidden', height: 0 }}>
                  <InputField
                    inputType='text'
                    name='responsibleUser'
                    placeholder= {``}
                    value={inputsHeader.responsibleUser}
                    changeHandler={handleChange}
                    disabled={false}
                    inputClass='text-center   border-bottom-primary cut-text-overflow margin-top-5'
                  />
                </div>
                <select
                  name='responsibleUser'
                  value={inputsHeader.responsibleUser}
                  onChange={(event) => handleOptionChange(event)}
                  className='input-lg   border-bottom-primary margin-top-5'
                >
                  {userListDict.map(option => (
                    <option key={option.displayName} value={option.displayName} hidden={!option.stateIsActve}>
                      {option.displayName}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col'>
                <label htmlFor='status'>Status:</label>
                <div style={{ visibility: 'hidden', height: 0 }}>
                  <InputField
                    inputType='text'
                    name='status'
                    placeholder='aktiv'
                    value={inputsHeader.status}
                    changeHandler={handleChange}
                    disabled={false}
                    inputClass='text-center   border-bottom-primary cut-text-overflow margin-top-5'
                  />
                </div>
                <select
                  name='status'
                  value={inputsHeader.status}
                  onChange={(event) => handleOptionChange(event)}
                  className='input-lg   border-bottom-primary margin-top-5'
                >
                  {enums.WorkBlockState.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col'>
                <label htmlFor='date'>Datum:</label>
                <InputField
                  inputType='date'
                  name='date'
                  placeholder= {inputsHeader.date}
                  value={inputsHeader.date}
                  changeHandler={handleChange}
                  disabled={false}
                  inputClass='text-center   border-bottom-primary cut-text-overflow margin-top-5'
                />
              </div>
            </div>
          </form>
          <div className="table-responsive margin-top-15">
            <table className='table main-table table-hover align-middle' style={{minWidth:'100%'}}>
              <thead>
                <tr className=" align-middle">
                  <th></th>
                  <th>#</th>
                  <th>Batteriename</th>
                  <th>Mäanderausprägung</th>
                  <th>Farbbild Anode</th>
                  <th>Zustand Anode</th>
                  <th>Zustand Kathode</th>
                  <th>Zustand Separator</th>
                </tr>
              </thead>
              <tbody>
                {inputsTable.map((input, index) => (
                  <>
                  <tr key={index}>
                    <td rowSpan={2} style={{maxWidth:'46px'}}>
                      <button
                        className='btn-delete'
                        type='button'
                        data-bs-toggle='modal'
                        data-bs-target={'#PostMortem_DeleteModal_'+String(index)}
                      ><FontAwesomeIcon
                        icon={faXmark}
                        size='sm'
                        />
                      </button>
                      <ConfirmationModal
                        modalID={'PostMortem_DeleteModal_'+String(index)}
                        modalText='Soll der Eintrag wirklich gelöscht werden?'
                        confirmationText='löschen'
                        handleConfirm={() => {
                          if(inputsTable[index].id!== ''){
                            deletedData.push(inputsTable[index].id);
                          }
                          setInputsTable((prevTable) => {
                            const newTable = [...prevTable];
                            newTable.splice(index, 1);
                            return newTable;
                          });
                          }}
                      />
                    </td>
                    
                    <td>{input.number}</td>
                    <td style={{minWidth:'230px'}}>
                      <input 
                        className={`form-control input-lg text-md margin-right-5 ${input.batterieID==='' ? 'border border-danger border-bottom-error' : 'border-bottom-primary'}`}
                        list="selectBatteryPM" 
                        id="batterieName" 
                        placeholder={'Batterie wählen...'}
                        value={input.batterieName}
                        name='batterieName'
                        onChange={(event) => handleOptionChangeTableBattery(event.target.value,index, '')}
                      />
                      <datalist id="selectBatteryPM">
                        {listOfBatteries.map((option: { id: string; name: string }) => (
                          <option id={option.id} value={option.name}
                            onClick={(event) => handleOptionChangeTableBattery(option.name,index, option.id)}
                          >
                            {option.name}
                          </option>
                        ))}
                      </datalist>
                    </td>
                    <td style={{minWidth:'180px'}}>
                      <select
                        name='maeanderAuspraegung'
                        value={input.maeanderAuspraegung}
                        onChange={(event) => handleOptionChangeTable(event, index)}
                        className='input-lg text-center   border-bottom-primary  margin-top-5'
                      >
                        {enums.MeanderExpressionState.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td style={{width:'120px'}}>
                      <select
                        name='farbbildAnode'
                        value={input.farbbildAnode}
                        onChange={(event) => handleOptionChangeTable(event, index)}
                        className='input-lg text-center   border-bottom-primary  margin-top-5'
                      >
                        {['Ja', 'Nein'].map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td style={{minWidth:'230px'}}>
                      <select
                        name='zustandAnode'
                        value={input.zustandAnode}
                        onChange={(event) => handleOptionChangeTable(event, index)}
                        className='input-lg text-center   border-bottom-primary  margin-top-5'
                      >
                        {[...enums.PmAnalysisState, '-'].map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td style={{minWidth:'230px'}}>
                      <select
                        name='zustandKath'
                        value={input.zustandKath}
                        onChange={(event) => handleOptionChangeTable(event, index)}
                        className='input-lg text-center   border-bottom-primary  margin-top-5'
                      >
                        {[...enums.PmAnalysisState, '-'].map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td style={{minWidth:'230px'}}>
                      <select
                        name='zustandSep'
                        onChange={(event) => handleOptionChangeTable(event, index)}
                        className='input-lg text-center   border-bottom-primary  margin-top-5'
                        value={input.zustandSep}
                      >
                        {[...enums.PmAnalysisState, '-'].map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={7}>
                      <textarea
                        style={{ width: '100%', margin: '0', lineHeight: 'auto' }}
                        name='comment'
                        id='comment'
                        value={input.comment}
                        onChange={(e) => handleInputChangeTable(e,index)}
                      />
                    </td>
                  </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
          <div className='grid colum-gap-3 float-none'>
            <input
              className='btn-main margin-top-5'
              type='button'
              value='+ PM Foto hinzufügen'
              onClick={handleAddVisuell}
            />
          </div>
          

          <div className='grid colum-gap-3 float-start'>
            <div className='row mt-2'>
              <div className='col'>
                <input
                  className='btn-main'
                  type='button'
                  value='Zurück'
                  onClick={handleBack}
                />
              </div>
              <div className='col'>
                <input
                  className='btn-main col'
                  type='button'
                  value='Speichern'
                  onClick={handleSave}
                  disabled={!validatedInputs}
                />
              </div> 
              <div className='col'>
                {characteristic.id && characteristic.id!== '' ?
                  <input
                  className='btn-main col'
                  type='button'
                  value='Dokument generieren'
                  onClick={handleGenerateDocument}
                />
                :(<></>)
                }
              </div>       
            </div>
            <div className='row mt-2'>
            { inputsTable.map(x => x.batterieID).includes('')
              ? <p className='text-err-color-font'>Batterien müssen angeklickt werden!</p>
              : <></>
            }
          </div>
          </div>
          
          <div className='clearfix'></div> {/*cleart float*/}

          {loadFiles && characteristic.id !== '' ?
          <div className='float-none'>
            <div className='mt-4 paket-information-container row'>
              <div className='column'>
                <FileUploadAccordeon
                  handleConfirm={submitModalCloseAccordion}
                  handleReloadPage={handleReloadPage}
                  files={files}
                />
              </div>
            </div>
          </div>
          
          : <></>
          }
          <InfoModal
            modalID='ErrorDownloadFailed'
            infoText='Download fehlgeschlagen'
            confirmationText='Ok'
          />
          <NavigationModal 
            modalID='ErrorMissingBatterie'
            infoText='Bitte erstellen Sie vor dem Testen mindestens eine Batterie!'
            confirmationText='Ok'
            path='/batteriebau'
          />
        </>
      : <></>
    }
    </div>
  );
};
