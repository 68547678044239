import { useEffect, useState } from 'react';
import { useNavigate, useLocation, NavigateOptions } from 'react-router-dom';
import { ApiWorkPackage } from '../../backend/ApiWorkPackage';
import { __WorkBlockCreate, __WorkPackageCreate } from '../../backend/types/__RequestStructures';
import { __PlannedStepsConverted } from '../../backend/types/__GeneralStructures';
import Ablaufdiagramm from '../../components/Ablaufdiagramm';
import { ApiWorkBlock } from '../../backend/ApiWorkBlock';
import Config from '../../util/Config';
import Loading from '../../components/Ladebalken';
import { useUserList } from '../../hooks/UserHooks';
interface IArbeitsblock {
  title: string;
  notes: string[];
  status: string;
  lineState: string;
  link: string;
  linkProps: NavigateOptions;
};

interface IArbeitspaketInput {
  paketName: string;
  initiator: string;
  locationOfSynthesis: string;
  descriptionForWorkblock: string;
  description: string;
  anzahlSheets: number;
  anzahlSheetsR2R: number;
  anzahlHalbzellen: number;
  anzahlVollzellen: number;
  anzahlPouchzellen: number;
  anodenbauCheckbox: boolean;
  sheet2sheetCheckbox: boolean;
  roll2rollCheckbox: boolean;
  batteriebauCheckbox: boolean;
  halbzellenCheckbox: boolean;
  vollzellenCheckbox: boolean;
  batterietestCheckbox: boolean;
  halbzellenTestCheckbox: boolean;
  vollzellenTestCheckbox: boolean;
  charakterisierungCheckbox: boolean;
  adhesionCheckbox: boolean;
  microscopyCheckbox: boolean;
  remCheckbox: boolean;
  xrdCheckbox: boolean;
  ramanCheckbox: boolean;
  postMortemAnalysisCheckbox: boolean;
  anodenmaterial: string;
};
/**
   * const plannedSteps: __PlannedStepsConverted = {
    anodeConstructionSheetToSheet: { number: 3 },
    anodeConstructionR2R: null,
    batteryConstruction: null,
    batteryTest: null,
    analysis: {
      adhesionTest: true,
      microscopy: true,
      postMortemAnalysis: false,
      raman: false,
      rem: true,
      xrd: true,
    },
  };
  const blockSteps: IBackendPlannedSteps = {
    anodeConstructionSheetToSheet: {
      planned: true,
      state: 'aktiv',
    },
    anodeConstructionR2R: {
      planned: false,
    },
    batteryConstruction: { VZ: { planned: false }, HZ: { planned: false } },
    batteryTest: {
      VZ: { planned: false },
      HZ: { planned: false },
    },
    analysis: {
      adhesionTest: { planned: true, state: 'aktiv' },
      microscopy: { planned: true, state: 'aktiv' },
      planned: true,
      postMortemAnalysis: { planned: false },
      raman: { planned: false },
      rem: { planned: true, state: 'aktiv' },
      state: 'aktiv',
      xrd: { planned: true, state: 'aktiv' },
    },
  };
   */



  
export default function ArbeitspaketUeberpruefen() {
  const location = useLocation();
  const [userListDict, userDataLoaded] = useUserList();

  const [componentMounted, setComponentMounted] = useState(false);
  const [arbeitsbloecke, setArbeitsbloecke] = useState<IArbeitsblock[][]>(location.state.arbeitsbloecke ?? [[]]);
  const [inititalArbeitsbloecke, setInitialBlocks] = useState<IArbeitsblock[][]>(location.state.inititalArbeitsbloecke?? []);
  const iniAddedBlocks = location.state.addedblock ?? [];
  const [addedBlock, setAddedBlocks] = useState<IArbeitsblock[][]>(iniAddedBlocks);
  const [addedBlockData, setAddedBlockDate] = useState<IArbeitspaketInput[]>(location.state.addedBlockData??[]);
  const arbeitspaket: IArbeitspaketInput = location.state.arbeitspaket;
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const navigate = useNavigate();
  const [checkNewPlannedBlock, setCheck] = useState(location.state.check?? true);

  useEffect(() => {
    if (!componentMounted  && ! location.state.arbeitsbloecke) {
      document.title = 'Arbeitspaket überprüfen';
      setOpenLoadingModal(true);
      createInitialArbeitsbloecke();
    }
    if(!checkNewPlannedBlock  && location.state.planned){
      setOpenLoadingModal(true);
      addArbeitlsbloecke();
      setCheck(true);
    }
  },[]);

const createInitialArbeitsbloecke =() => {
  if (componentMounted) return;
  let newArbeitsbloecke_temp:IArbeitsblock[] = [];
  let newArbeitsbloecke:IArbeitsblock[][] = [[]];
  setComponentMounted(true);
  //check firt batterieconstruction , -test and analysis and add to newArbeitsblöcke
   if (arbeitspaket.batteriebauCheckbox) {
    if (arbeitspaket.halbzellenCheckbox) {
      const temp = {
        title: 'Batteriebau',
        notes: ['Halbzelle', `Anzahl: ${arbeitspaket.anzahlHalbzellen}`],
        status: 'upcoming',
        lineState: 'upcoming',
        link: '/batteriebau/neu',
        linkProps: {
          state: {
            selectedIndex: location.state.selectedIndex,
          },
        },
      };
      newArbeitsbloecke_temp.push(temp);
    }
    if (arbeitspaket.vollzellenCheckbox) {
      const temp = {
        title: 'Batteriebau',
        notes: [
          'Vollzelle',
          `Anzahl VZ: ${arbeitspaket.anzahlVollzellen}`,
          `Anzahl PZ: ${arbeitspaket.anzahlPouchzellen}`,
        ],
        status: 'upcoming',
        lineState: 'upcoming',
        link: '/batteriebau/neu',
        linkProps: {
          state: {
            selectedIndex: location.state.selectedIndex,
          },
        },
      };
      newArbeitsbloecke_temp.push(temp);
    }
  }
  if (arbeitspaket.batterietestCheckbox) {
    if (arbeitspaket.halbzellenTestCheckbox) {
      const temp = {
        title: 'Batterietest',
        notes: ['Halbzelle'],
        status: 'upcoming',
        lineState: 'upcoming',

        link: '/batterietest/neu',
        linkProps: {
          state: {
            selectedIndex: location.state.selectedIndex,
          },
        },
      };
      newArbeitsbloecke_temp.push(temp);
    }
    if (arbeitspaket.vollzellenTestCheckbox) {
      const temp = {
        title: 'Batterietest',
        notes: ['Vollzelle'],
        status: 'upcoming',
        lineState: 'upcoming',

        link: '/batterietest/neu',
        linkProps: {
          state: {
            selectedIndex: location.state.selectedIndex,
          },
        },
      };
      newArbeitsbloecke_temp.push(temp);
    }
  }
  if (arbeitspaket.charakterisierungCheckbox) {
    /**
     * Charakterisierung
     * Umbenennungen:
        Hafttest -> Sonstiges
        Post Mortem -> PM Foto
        Raman -> PM REM
        Mikroskopie -> LiMi
    */
    const tempArray = [];
    if (arbeitspaket.microscopyCheckbox) {
      tempArray.push('LiMi');
    }
    if (arbeitspaket.remCheckbox) {
      tempArray.push('REM');
    }
    if (arbeitspaket.xrdCheckbox) {
      tempArray.push('XRD');
    }
    if (arbeitspaket.ramanCheckbox) {
      tempArray.push('PM REM');
    }
    if (arbeitspaket.postMortemAnalysisCheckbox) {
      tempArray.push('PM Foto');
    }
    if (arbeitspaket.adhesionCheckbox) {
      tempArray.push('Sonstiges');
    }
    if (tempArray.length > 0) {
      const temp = {
        title: 'Charakterisierung',
        notes: tempArray,
        status: 'upcoming',
        lineState: 'upcoming',

        link: '/charakterisierung/ablauf',
        linkProps: {
          state: {
            selectedIndex: location.state.selectedIndex,
          },
        },
      };
      newArbeitsbloecke_temp.push(temp);
    }
  }

  if(arbeitspaket.anodenbauCheckbox && arbeitspaket.sheet2sheetCheckbox){
    const temp = [...newArbeitsbloecke_temp];      
      temp.splice(0, 0,{
        title: 'Anodenbau',
        notes: ['Sheet to Sheet'],
        status: 'upcoming',
        lineState: 'upcoming',

        link: '/anodenbau/sheetToSheet',
        linkProps: {
          state: {
            selectedIndex: location.state.selectedIndex,
          },
        },
      });

      for (let i = 0; i < arbeitspaket.anzahlSheets; i++) {
        newArbeitsbloecke.push(temp);
      }
  }
  if(arbeitspaket.anodenbauCheckbox && arbeitspaket.roll2rollCheckbox){
    const temp = [...newArbeitsbloecke_temp];      
      temp.splice(0, 0,{
        title: 'Anodenbau',
        notes: ['Roll to Roll'],
        status: 'upcoming',
        lineState: 'upcoming',

        link: '/anodenbau/rollToRoll',
        linkProps: {
          state: {
            selectedIndex: location.state.selectedIndex,
          },
        },
      });

      for (let i = 0; i < arbeitspaket.anzahlSheetsR2R; i++) {
        newArbeitsbloecke.push(temp);
      }
  }
  if(!arbeitspaket.anodenbauCheckbox){
    setInitialBlocks([newArbeitsbloecke_temp]);
    setArbeitsbloecke([newArbeitsbloecke_temp]);
  }
  else{
    setInitialBlocks(newArbeitsbloecke);
    setArbeitsbloecke(newArbeitsbloecke)
  }
  setOpenLoadingModal(false);
};
const addArbeitlsbloecke = () =>{
  addedBlockData.push(location.state.planned);

  let arbeitsbloeckeOld: IArbeitsblock[][] = [[]]
  let tempArbeitsblock: IArbeitsblock[][] = [];
  let newblock: IArbeitspaketInput = location.state.planned;
  setArbeitsbloecke(arbeitsbloecke.concat(addedBlock));
  if (newblock.anodenbauCheckbox) {
    if (newblock.sheet2sheetCheckbox) {
      const temp = arbeitsbloeckeOld[0];      
      temp.push({
        title: 'Anodenbau',
        notes: ['Sheet to Sheet'],
        status: 'upcoming',
        lineState: 'upcoming',
        link: '/anodenbau/sheetToSheet',
        linkProps: {
          state: {
            selectedIndex: location.state.selectedIndex,
          },
        },
      });
      tempArbeitsblock.push(temp);
    }
  
    if (newblock.roll2rollCheckbox) {
      const temp = arbeitsbloeckeOld[0];
      temp.push({
        title: 'Anodenbau',
        notes: ['Roll to Roll', `Laenge: ${newblock.anzahlSheetsR2R}`],
        status: 'upcoming',
        lineState: 'upcoming',
        link: '/anodenbau/rollToRoll',
        linkProps: {
          state: {
            selectedIndex: location.state.selectedIndex,
          },
        },
      });
      tempArbeitsblock.push(temp);
    }
  }
  if (newblock.batteriebauCheckbox) {
    if (newblock.halbzellenCheckbox) {
      const temp = arbeitsbloeckeOld[0];
      temp.push({
        title: 'Batteriebau',
        notes: ['Halbzelle', `Anzahl: ${newblock.anzahlHalbzellen}`],
        status: 'upcoming',
        lineState: 'upcoming',
        link: '/batteriebau/neu',
        linkProps: {
          state: {
            selectedIndex: location.state.selectedIndex,
          },
        },
      });
      tempArbeitsblock.push(temp);
    }
    if (newblock.vollzellenCheckbox) {
      const temp = arbeitsbloeckeOld[0];
      temp.push({
        title: 'Batteriebau',
        notes: [
          'Vollzelle',
          `Anzahl VZ: ${newblock.anzahlVollzellen}`,
          `Anzahl PZ: ${newblock.anzahlPouchzellen}`,
        ],
        status: 'upcoming',
        lineState: 'upcoming',
        link: '/batteriebau/neu',
        linkProps: {
          state: {
            selectedIndex: location.state.selectedIndex,
          },
        },
      });
      tempArbeitsblock.push(temp);
    }
  }
  if (newblock.batterietestCheckbox) {
    if (newblock.halbzellenTestCheckbox) {
      const temp = arbeitsbloeckeOld[0];
      temp.push({
        title: 'Batterietest',
        notes: ['Halbzelle'],
        status: 'upcoming',
        lineState: 'upcoming',

        link: '/batterietest/neu',
        linkProps: {
          state: {
            selectedIndex: location.state.selectedIndex,
          },
        },
      });
      tempArbeitsblock.push(temp);
    }
    if (newblock.vollzellenTestCheckbox) {
      const temp = arbeitsbloeckeOld[0];
      temp.push({
        title: 'Batterietest',
        notes: ['Vollzelle'],
        status: 'upcoming',
        lineState: 'upcoming',

        link: '/batterietest/neu',
        linkProps: {
          state: {
            selectedIndex: location.state.selectedIndex,
          },
        },
      });
      tempArbeitsblock.push(temp);
    }
  }
  if (newblock.charakterisierungCheckbox) {
    /**
     * Charakterisierung
     * Umbenennungen:
        Hafttest -> Sonstiges
        Post Mortem -> PM Foto
        Raman -> PM REM
        Mikroskopie -> LiMi
    */
    const tempArray = [];
    if (newblock.microscopyCheckbox) {
      tempArray.push('LiMi');
    }
    if (newblock.remCheckbox) {
      tempArray.push('REM');
    }
    if (newblock.xrdCheckbox) {
      tempArray.push('XRD');
    }
    if (newblock.ramanCheckbox) {
      tempArray.push('PM REM');
    }
    if (newblock.postMortemAnalysisCheckbox) {
      tempArray.push('PM Foto');
    }
    if (newblock.adhesionCheckbox) {
      tempArray.push('Sonstiges');
    }
    if (tempArray.length > 0) {
      const temp = arbeitsbloeckeOld[0];
      temp.push({
        title: 'Charakterisierung',
        notes: tempArray,
        status: 'upcoming',
        lineState: 'upcoming',
        link: '/charakterisierung/ablauf',
        linkProps: {
          state: {
            selectedIndex: location.state.selectedIndex,
          },
        },
      });
      tempArbeitsblock.push(temp);
    }
  }

  if(newblock.sheet2sheetCheckbox){
    let block: IArbeitsblock[][] = addedBlock;
    let newArbeitsbloecke = arbeitsbloecke;
    const len1 = addedBlock.length;
    const len2 = arbeitsbloecke.length;
    for (let i = 0; i < newblock.anzahlSheets; i++) {
      block[i+len1] = tempArbeitsblock[0];
      newArbeitsbloecke[i+len2] = block[i+len1];

    }
    setArbeitsbloecke(newArbeitsbloecke);
    setAddedBlocks(block)
  }
  else{
    addedBlock[addedBlock.length]=tempArbeitsblock[0];
    setAddedBlocks(addedBlock);
    arbeitsbloecke[arbeitsbloecke.length] = tempArbeitsblock[0];
    setArbeitsbloecke(arbeitsbloecke);
  }
  setOpenLoadingModal(false);
};
const handleCreateArbeitspaket = async () => {
  setOpenLoadingModal(true);
  let testPackage: __WorkPackageCreate = {
    platform: 'IntranetHalle',
    initiator: userListDict[location.state.selectedIndex] ? [userListDict[location.state.selectedIndex].loginName]: [userListDict[0].loginName],
    responsibleUser: [
      userListDict[location.state.selectedIndex].loginName ?? userListDict[0].loginName,
    ],
    shortDescription: arbeitspaket.descriptionForWorkblock,
    projectDescription: arbeitspaket.description,
    descriptionForWorkblock: arbeitspaket.descriptionForWorkblock,
    locationOfSynthesis: arbeitspaket.locationOfSynthesis,
    state: 'aktiv',
    plannedSteps: {
      anodeConstructionSheetToSheet: arbeitspaket.sheet2sheetCheckbox
        ? { number: Number(arbeitspaket.anzahlSheets) }
        : null,
      anodeConstructionR2R: arbeitspaket.roll2rollCheckbox
        ? { length: Number(arbeitspaket.anzahlSheetsR2R) }
        : null,
      batteryConstruction: {
        VZ: arbeitspaket.vollzellenCheckbox
          ? {
              type: 'Vollzelle',
              number: Number(arbeitspaket.anzahlVollzellen),
              numberPZ: Number(arbeitspaket.anzahlPouchzellen),
            }
          : null,
        HZ: arbeitspaket.halbzellenCheckbox
          ? {
              type: 'Halbzelle',
              number: Number(arbeitspaket.anzahlHalbzellen),
              numberPZ: 0,
            }
          : null,
      },
      batteryTest: arbeitspaket.batterietestCheckbox
        ? {
            VZ: arbeitspaket.vollzellenTestCheckbox
              ? {
                  type: 'Vollzelle',
                }
              : null,
            HZ: arbeitspaket.halbzellenTestCheckbox
              ? {
                  type: 'Halbzelle',
                }
              : null,
          }
        : null,
      analysis: (arbeitspaket.adhesionCheckbox || arbeitspaket.microscopyCheckbox || arbeitspaket.remCheckbox || arbeitspaket.xrdCheckbox || arbeitspaket.ramanCheckbox || arbeitspaket.postMortemAnalysisCheckbox) 
        ? {
          adhesionTest: arbeitspaket.adhesionCheckbox,
          microscopy: arbeitspaket.microscopyCheckbox,
          rem: arbeitspaket.remCheckbox,
          xrd: arbeitspaket.xrdCheckbox,
          raman: arbeitspaket.ramanCheckbox,
          postMortemAnalysis: arbeitspaket.postMortemAnalysisCheckbox,
        } 
        : null,
    },
  };

  // create and directly forward to details
  ApiWorkPackage.Create(testPackage).then(async (id: any) => {
    for(let item of addedBlockData){
      let user: string = userListDict[userListDict.map(x=>x.displayName).indexOf(item.initiator)].loginName ?? userListDict[0].loginName;
      let requestData:__WorkBlockCreate ={
        platform: Config.Platform,
        workpackageID: id.workpackageID,
        responsibleUser: [user],
        description: item.description,
        comment: '',
        location: item.locationOfSynthesis,
        state: 'aktiv',
        isAddition: null,
        plannedSteps:{
          anodeConstructionSheetToSheet: item.sheet2sheetCheckbox
          ? {
            number: Number(item.anzahlSheets)
          }
          : null,
          anodeConstructionR2R: item.roll2rollCheckbox
          ? {
            length: Number(item.anzahlSheetsR2R)
          }
          : null,
          batteryConstruction: item.batteriebauCheckbox
          ?{
            VZ: item.vollzellenCheckbox
            ? {
              type: 'Vollzelle',
              number: Number(item.anzahlVollzellen),
              numberPZ: Number(item.anzahlPouchzellen)
            }
            : null,
            HZ: item.halbzellenCheckbox 
            ? {
              type: 'Halbzelle',
              number: Number(item.anzahlHalbzellen),
              numberPZ: 0
            }
            : null
          }
          : null,
          batteryTest: item.batterietestCheckbox
          ? {
            VZ: item.vollzellenTestCheckbox 
            ? {
              type: 'Vollzelle'
            }
            : null,
            HZ: item.halbzellenTestCheckbox
            ? {
              type: 'Halbzelle'
            }
            : null
          }
          : null,
          analysis: item.charakterisierungCheckbox
          ? {
            adhesionTest: item.adhesionCheckbox,
            microscopy: item.microscopyCheckbox,
            rem: item.remCheckbox,
            xrd: item.xrdCheckbox,
            raman: item.ramanCheckbox,
            postMortemAnalysis: item.postMortemAnalysisCheckbox 
          }
          : null 
        }
      };
      if(item.anzahlSheets){
        for(let i=0; i<item.anzahlSheets; i++){
          await ApiWorkBlock.Create(requestData)
        }
      }
      else{
        await ApiWorkBlock.Create(requestData)
      }
    }
    navigate(`/arbeitspakete/details`, {
      state: {
        workpackage: [id.workpackageID]
      },
    });
  });
};

if (!arbeitspaket) {
  return <></>;
}
return (
  <div className='content-main-container'>
    {openLoadingModal ? (
        <Loading/>
      ) : (
        <></>
    )}
    <h1>Neues Arbeitspaket erstellen</h1>
    <div className='mt-2 row'>
      <div className='col-2'>
        <p className='fw-bold'>Name:</p>
      </div>
      <div className='col-2'>
        <p>{arbeitspaket.paketName}</p>
      </div>
      <div className='col-2'>
        <p className='fw-bold'>Syntheseort:</p>
      </div>
      <div className='col-2'>
        <p>{arbeitspaket.locationOfSynthesis}</p>
      </div>
    </div>
    <div className='mt-2 row'>
      <div className='col-2'>
        <p className='fw-bold'>Verantwortlicher:</p>
      </div>
      <div className='col-2'>
        <p>{arbeitspaket.initiator}</p>
      </div>
      <div className='col-2'>
        <p className='fw-bold'>Kurzbeschreibung:</p>
      </div>
      <div className='col-3'>
        <p>{arbeitspaket.descriptionForWorkblock}</p>
      </div>
    </div>
    <div className='mt-2 row'>
      <div className='col-2'>
          <p className='fw-bold'>Projektbeschreibung:</p>
      </div>
      <div className='col-7'>
        <p>{arbeitspaket.description}</p>
      </div>
    </div>
    
    <h2 className='margin-top-25'>Arbeitsblöcke</h2>
    <div className='fit-content'>
      {arbeitsbloecke.map((arbeitsblock, index) => (
        <div className='row overflow-auto pb-3'>
          <div className='col-10'>
            <Ablaufdiagramm ablauf={arbeitsblock} disableLinks={true}/>
          </div>
        </div>
      ))}


      <div className='clearfix'></div> {/*cleart float*/}
      <div className='row arbeitspaket-detail-button-container mt-4'>
        <div className='col'>
          <input
            className='btn-main margin-top-10 mx-2'
            type='button'
            value='Zurück'
            onClick={() => navigate('/arbeitspakete/neu',
              {
              state:{
                arbeitspaket:location.state.arbeitspaket,
                selectedIndex: location.state.selectedIndex,
              }
            })}
          />
          {/* <input
            className='btn-main margin-top-10 mx-2'
            type='button'
            value='Arbeitsblock hinzufügen'
            onClick={() => handleAddWorkblock()}
          /> */}
          <input
            className='btn-main margin-top-10 mx-2'
            type='button'
            value='Erstellen'
            onClick={handleCreateArbeitspaket}
          />
        </div>
      </div>
    </div>
  </div>
);
};
