import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BatteriebauInputModalCreate, BatteriebauInputModalCreateVariaton, BatteriebauInputModalForVariation} from './BatteriebauInputModal';

import { ApiBackendData } from '../../backend/ApiBackendData';
import { ApiMaterial } from '../../backend/ApiMaterial';
import {  IBackendMaterialList } from '../../backend/types/__ResponseStructures';
import { BatteryDataForModal } from './BatteryConstructionData';
import { __BatteryConstructionCreate } from '../../backend/types/__RequestStructures';
import Config from '../../util/Config';
import { __BatteryDataConverted, __BatteryVariationConverted, __BatteryVariationWithMaterialNames, __BatteryWishConverted } from '../../backend/types/__GeneralStructures';
import { ApiBatteryConstruction } from '../../backend/ApiBatteryConstruction';
import InputField from '../../UI/InputField';
import { ConfirmationModal, NavigationModal } from '../../components/Modal';
import { ApiWorkBlock } from '../../backend/ApiWorkBlock';
import Loading from '../../components/Ladebalken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { NamesAndIDOfMaterials } from './BatteriebauInputModal';
import { ApiAuth } from '../../backend/ApiAuth';
import { useUserList } from '../../hooks/UserHooks';

const createChangeHandler =
  <T extends { [key: string]: string }>(
    table: T[],
    setInputs: React.Dispatch<React.SetStateAction<T[]>>
  ) =>
  (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const name = event.target.name;
    const value = String(event.target.value);
    const list = [...table];
    list[index] = { ...list[index], [name]: value };
    setInputs(list);
  };
 type InputVariationTable = {
    anzahl: string;
    bauort: string;
    variation: string;
};
export default function BatteriebauNeu() {
  
  const location = useLocation();
  const navigate = useNavigate();

  // call Apis for basic data
  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  const celltypeList: string[] = location.state 
    ? location.state.type === 'HZ'
      ? location.state.halbzellenList
      : location.state.vollzellenList
    : enums.CellType
  const Location = enums.Location;
  
  const [userListDict,userDataLoaded] = useUserList();

  const [loadMaterial, setLoadMaterial] = useState(false);
  const [materials, setMaterials] = useState<IBackendMaterialList[]>();
  const [materialNames, setMaterialNames] = useState<NamesAndIDOfMaterials>({anode:[],cathode:[],electrolyt:[],feather:[],separator:[],spacer:[],case:[]});
  const[loadNamesOfMaterials, setLoadNamesOfMaterials] = useState(false);
  const [inputs, setInputs] = useState({
    celltype: '',
    constructor: '',
    state: 'aktiv',
    anode: '',
    anodengroesse: 0,
    kathode: '',
    kathodengroesse: 0,
    elektrolyt: '',
    elektrolytvolumen: '',
    elektrolytgesamtvolumen: 0,
    separator: '',
    separatorgroesse: 0,
    separatoranzahl: 0,
    feder: '',
    spacer: '',
    spacergroesse: 0,
    spacerdicke: 0,
    gehaeuse: '',
    checkAnode: true,
    checkCathode: true,
    checkElectrolyt: true,
    checkSeparator: true,
    checkSpacer: true,
    checkFeather: true,
    checkCase: true,
  });
  const [batteryData, setBatteryData] = useState<BatteryDataForModal>({responsibleUser:[],celltype:celltypeList[0],date: new Date(),state:'',anode:null,kathode:null,elektolyt:null,spacer:null,separator:null,feder:null,gehaeuse:null});
  const [variations, setVariations] = useState<__BatteryVariationConverted[]>([]);
  const [packagename, setPackagename] = useState('');
  const [blockname, setBlockname] = useState('');
  const [validatedInputs, setValidatedInputs] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);

  const [missingInputText,setMissingInputText] = useState('Fehlende Daten bitte eingeben!');
  const [missingBatteryData, setMissingBatteryData] = useState(false);
  const [missingVariationData, setMissingVariationData] = useState(false);

  const [selectedOption, setSelectedOption] = useState<string>('');

  //table
  const tableHeadersOne = ['Fest', 'Bestandteil', 'Daten'];
  const tableHeadersTwo = [
    '',
    'Anzahl Batterien',
    'Bauort',
    'Variation',
    'Bearbeiten'
  ];
  const [variationTable, setVariationTable] = useState<InputVariationTable[]>([]);
  const emptyTable: InputVariationTable[] = [
    { anzahl: '1', bauort: Location[0], variation: 'Variation 1' },
  ];
  

  // load Data
  const getPackageName = async() =>{
    const blockId: string = location.state.workblockID;
    await ApiWorkBlock.Get(blockId).then((data)=>{
      setPackagename(data.workpackage.name);
      setBlockname(data.name);
    });
    return;
  };

  //Validations
  const validateInputFields = () => {
    setMissingBatteryData(false);
    setMissingVariationData(false);
    for(let idx = 0; idx< variationTable.length; idx +=1) {
      let entry = variations[idx] ?? undefined;
      if(!entry){
        setValidatedInputs(false);
        setMissingVariationData(true);
        return;
      }
      if(!(entry.anode|| entry.caseData || entry.cathode || entry.electrolyt || entry.feather || entry.separator || entry.spacer)){
        setValidatedInputs(false);
        setMissingVariationData(true);
        return;
      }
    }
    if(inputs.checkAnode && inputs.anode ==""){
      setValidatedInputs(false);
      setMissingBatteryData(true);
      return;
    }
    if(inputs.checkCase && inputs.gehaeuse ==""){
      setValidatedInputs(false);
      setMissingBatteryData(true);
      return;
    }
    if(inputs.checkCathode && inputs.kathode ==""){
      setValidatedInputs(false);
      setMissingBatteryData(true);
      return;
    }
    if(inputs.checkElectrolyt && inputs.elektrolyt ==""){
      setValidatedInputs(false);
      setMissingBatteryData(true);
      return;
    }
    if(inputs.checkFeather && inputs.feder ==""){
      setValidatedInputs(false);
      setMissingBatteryData(true);
      return;
    }
    if(inputs.checkSeparator && inputs.separator ==""){
      setValidatedInputs(false);
      setMissingBatteryData(true);
      return;
    }
    if(inputs.checkSpacer && inputs.spacer ==""){
      setValidatedInputs(false);
      setMissingBatteryData(true);
      return;
    }
    if(batteryData.celltype == null || batteryData.celltype == "" ){
      setValidatedInputs(false);
      return true;
    }
    setValidatedInputs(true);
  };

  //handle functions
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setInputs({ ...inputs, [name]: value });
  };
  const handleOptionChangeTable = (event: React.ChangeEvent<HTMLSelectElement>,index:number) => {
    setSelectedOption(event.target.value);
    setSelectedOption(selectedOption);
    const { name, value } = event.target;
    setVariationTable(variationTable.map((input, i) =>
        i === index ? { ...input, [name]: value } : input
      )
    );
  };
  const submitModalClose = (fixedBatteryData:BatteryDataForModal) => {
    fixedBatteryData.celltype = inputs.celltype;
    fixedBatteryData.responsibleUser.push(inputs.constructor);
    if(fixedBatteryData.anode){
      inputs.anode = fixedBatteryData.anode.anodeName;
      inputs.anodengroesse = fixedBatteryData.anode.anodengroesse;
    }
    if(fixedBatteryData.kathode){
      inputs.kathode = fixedBatteryData.kathode.kathodeName;
      inputs.kathodengroesse = fixedBatteryData.kathode.kathodengroesse;
    }
    if(fixedBatteryData.elektolyt){
      inputs.elektrolyt = fixedBatteryData.elektolyt.elektrolytName;
      inputs.elektrolytvolumen = fixedBatteryData.elektolyt.elektrolytvolumen;
      inputs.elektrolytgesamtvolumen = fixedBatteryData.elektolyt.elektrolytgesamtvolumen;
    }
    if(fixedBatteryData.separator){
      inputs.separator = fixedBatteryData.separator.separatorName;
      inputs.separatorgroesse = fixedBatteryData.separator.separatorgroesse;
      inputs.separatoranzahl = fixedBatteryData.separator.separatoranzahl;
    }
    if(fixedBatteryData.spacer){
      inputs.spacer = fixedBatteryData.spacer.spacerName;
      inputs.spacergroesse = fixedBatteryData.spacer.spacergroesse;
      inputs.spacerdicke = fixedBatteryData.spacer.spacerdicke;
    }
    if(fixedBatteryData.feder){
      inputs.feder = fixedBatteryData.feder.federName;
    }
    if(fixedBatteryData.gehaeuse){
      inputs.gehaeuse = fixedBatteryData.gehaeuse.gehaeuseName;
    }
    setBatteryData(fixedBatteryData);
    validateInputFields();
    return fixedBatteryData;
  };
  const handleChangeTable = createChangeHandler<InputVariationTable>(
    variationTable,
    setVariationTable
  );
  const getVariationNames = (
    variation: __BatteryVariationWithMaterialNames
  ) => {
    let text = '';
    if (variation.dataAnode) {
      text += variation.dataAnode.material.name + ',';
    }
    if (variation.dataSpacer) {
      text += variation.dataSpacer.material.name + ',';
    }
    if (variation.dataCathode) {
      text += variation.dataCathode.material.name + ',';
    }
    if (variation.dataCase) {
      text += variation.dataCase.material.name + ',';
    }
    if (variation.dataElectrolyt) {
      text += variation.dataElectrolyt.material.name + ',';
    }
    if (variation.dataFeather) {
      text += variation.dataFeather.material.name + ',';
    }
    if (variation.dataSeparator) {
      text += variation.dataSeparator.material.name;
    }
    return text;
  };
  const submitModalCloseVariation = (variationData:__BatteryVariationConverted, idx:number) => {
    let varTemp = [...variations];
    if(variationData){
      varTemp[idx] = variationData;
    }
    setVariations(varTemp);
    let convertedVariation:__BatteryVariationWithMaterialNames= {
        id: '',
        platformID: '',
        batteryID: '',
        batteryWishesID:  '',
        dataAnode: variationData.anode?{
          id: '',material: {name:variationData.anode.materialName!, id:variationData.anode.material},diameter: variationData.anode.diameter}:null,
        dataCathode: variationData.cathode?{
          id: '',material: {name:variationData.cathode.materialName!, id:variationData.cathode.material},diameter: variationData.cathode.diameter}:null,
        dataElectrolyt:variationData.electrolyt?{
          id: '',material: {name:variationData.electrolyt.materialName!, id:variationData.electrolyt.material},volume:variationData.electrolyt.volume,volumeText:variationData.electrolyt.volumeText,volumeUnit:variationData.electrolyt.volumeUnit}:null,
        dataSeparator:variationData.separator?{
          id: '',material: {name:variationData.separator.materialName!, id:variationData.separator.material},diameter:variationData.separator.diameter,numberOfSeparators:variationData.separator.numberOfSeperators}:null,
        dataSpacer:variationData.spacer?{
          id: '',material: {name:variationData.spacer.materialName!, id:variationData.spacer.material},diameter: variationData.spacer.diameter,thickness:variationData.spacer?.thickness}:null,
        dataCase:variationData.caseData?{
          id: '',material: {name:variationData.caseData?.materialName!, id:variationData.caseData?.material}}:null,
        dataFeather:variationData.feather?{
          id: '',material: {name:variationData.feather.materialName!, id:variationData.feather.material}}:null,
    }
    let variationNames = getVariationNames(convertedVariation);

    const newTable = variationTable;
    newTable[idx].variation = variationNames;
    setVariationTable(newTable);
    return variationData;
  };
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>,index:number) => {
    setInitialized(false);
    const { name, checked } = event.target;
    setInputs((prevState) => ({ ...prevState, [name]: checked }));
  };
  const handleCreate = () => {
    setOpenLoadingModal(true);
    if(batteryData.celltype == null || batteryData.celltype == "") {
      batteryData.celltype = celltypeList[0];
    }
    let fixBatteryData: __BatteryDataConverted ={
      id: null,
      celltype: batteryData.celltype,
      anode:  null,
      cathode: null,
      electrolyt: null,
      separator: null,
      feather:  null,
      spacer: null,
      case:null
    };
    if(inputs.checkAnode && batteryData.anode){
      fixBatteryData.anode ={
        diameter: batteryData.anode.anodengroesse,
        material: batteryData.anode.anodeId,
      }
    }
    if(inputs.checkCase && batteryData.gehaeuse){
      fixBatteryData.case ={
        material: batteryData.gehaeuse.gehaeuseId
      }
    }
    if(inputs.checkCathode && batteryData.kathode){
      fixBatteryData.cathode ={
        diameter: batteryData.kathode.kathodengroesse,
        material: batteryData.kathode.kathodeId
      }
    }
    if(inputs.checkElectrolyt && batteryData.elektolyt){
      fixBatteryData.electrolyt ={
        volume: batteryData.elektolyt.elektrolytgesamtvolumen,
        material: batteryData.elektolyt.elektrolytId,
        volumeText: batteryData.elektolyt.elektrolytvolumen,
        volumeUnit: 'ul'
      }
    }
    if(inputs.checkFeather && batteryData.feder){
      fixBatteryData.feather ={
        material: batteryData.feder.federId
      }
    }
    if(inputs.checkSeparator && batteryData.separator){
      fixBatteryData.separator ={
        diameter: batteryData.separator.separatorgroesse,
        material: batteryData.separator.separatorId,
        numberOfSeperators: batteryData.separator.separatoranzahl
      }
    }
    if(inputs.checkSpacer && batteryData.spacer){
      fixBatteryData.spacer ={
        diameter: batteryData.spacer.spacergroesse,
        material: batteryData.spacer.spacerId,
        thickness: batteryData.spacer.spacerdicke
      }
    }
    //get wishes
    let wishes: __BatteryWishConverted[]=[];
    let i = 0;
    for(let item of variations){
      for(let idx = 0; idx < Number(variationTable[i].anzahl); idx +=1){
        wishes.push({
          numberOfBatteries: variationTable[i] ? Number(variationTable[i].anzahl) : 0,
          location: variationTable[i] ? variationTable[i].bauort: Location[0],
          battery: [],
          variation: item
        })
      }
      i++;
    }
    let responsibleUser: string[] = [userListDict[userListDict.map(x=>x.displayName).indexOf(inputs.constructor)].loginName];
    let requestData:__BatteryConstructionCreate = {
      platform: Config.Platform,
      battery: [],
      responsibleUser:  responsibleUser,
      evaluation:  null,
      state: inputs.state,
      fixedBatteryData: fixBatteryData,
      batteryWishes: wishes,
      workBlock: location.state.workblockID ?? ''
    };
    ApiBatteryConstruction.Create(requestData)
      .then((batteryDataIDObject)=>{
        ApiBatteryConstruction.Get(batteryDataIDObject.id.batteryConstruction)
          .then((data)=>{
            navigate('/batteriebau/details',{
              state:{
                  data: data,
                  materials: materials,
                  workblockID: requestData.workBlock, 
                  halbzellenList: location.state.halbzellenList,
                  vollzellenList: location.state.vollzellenList,
                  type: location.state.type
              },
              replace:true //the current entry in the history stack gets replaced with the new one.
          })})
          .catch(e=>{
            console.log(e);
          });


      })
      .catch(e=>{
        console.log(e);
      });
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleAddVariation = () => {
    let newTable = JSON.parse(JSON.stringify(emptyTable[0])); // copy
    setVariationTable([...variationTable, newTable]);
    setVariations([...variations,{}]);
  };

  //use Effects
  useEffect(() => {
    document.title = 'Batteriebau Neu';
    if(!loadMaterial){
      setInitialized(false);
      ApiMaterial.ListAll().then((data) => {
        setMaterials(data);
        setLoadMaterial(true);
        getPackageName();
        if(data.length < 1){
          let errorModal = document.getElementById("ErrorMissingMaterial");
          if(!errorModal) return;
          $('#ErrorMissingMaterial').modal('show')
        }
      });
    }
  },[]);
  useEffect(()=>{
    validateInputFields();
  },[variations]);
  useEffect(() => {
    validateInputFields();
  }, [variationTable]);
  useEffect(() => {
    validateInputFields();
  }, [batteryData]) // Aktualisiert immer dann, wenn sich batteryData ändert
  useEffect(()=>{
    if(userDataLoaded){
      setInputs({
        celltype: celltypeList[0],
        constructor: userListDict[0].displayName,
        state: 'aktiv',
        anode: '',
        anodengroesse: 0,
        kathode: '',
        kathodengroesse: 0,
        elektrolyt: '',
        elektrolytvolumen: '',
        elektrolytgesamtvolumen: 0,
        separator: '',
        separatorgroesse: 0,
        separatoranzahl: 0,
        feder: '',
        spacer: '',
        spacergroesse: 0,
        spacerdicke: 0,
        gehaeuse: '',
        checkAnode: true,
        checkCathode: true,
        checkElectrolyt: true,
        checkSeparator: true,
        checkSpacer: true,
        checkFeather: true,
        checkCase: true,
      })
    }
  },[userDataLoaded]);
  useEffect(()=>{
    if(loadMaterial && loadNamesOfMaterials && userDataLoaded){
      setInitialized(true);
    }
  },[loadNamesOfMaterials]);
  useEffect(()=>{
    if (!loadNamesOfMaterials && materials) {
      setInitialized(false);
      let newMaterialNames: NamesAndIDOfMaterials = {
          anode: [],
          cathode: [],
          electrolyt: [],
          feather: [],
          separator: [],
          spacer: [],
          case: [],
        };
      for (let item of materials) {
        if (item.type.name === 'Anode') {
          newMaterialNames.anode.push({
            name: item.name,
            id : item.id,
            state: item.state.name === 'gelöscht' || item.state.name === 'gesperrt' || item.deleted
          });
        }
        else if (item.type.name === 'Kathode') {
          newMaterialNames.cathode.push({
            name: item.name,
            id : item.id,
            state: item.state.name === 'gelöscht' || item.state.name === 'gesperrt' || item.deleted
          });
        }
        else if (item.type.name === 'Gehäuse') {
          newMaterialNames.case.push({
            name: item.name,
            id : item.id,
            state: item.state.name === 'gelöscht' || item.state.name === 'gesperrt' || item.deleted
          });
        }
        else if (item.type.name === 'Feder') {
          newMaterialNames.feather.push({
            name: item.name,
            id : item.id,
            state: item.state.name === 'gelöscht' || item.state.name === 'gesperrt' || item.deleted
          });
        } 
        else if (item.type.name === 'Elektrolyt') {
          newMaterialNames.electrolyt.push({
            name: item.name,
            id : item.id,
            state: item.state.name === 'gelöscht' || item.state.name === 'gesperrt' || item.deleted
          });
        }
        else if (item.type.name === 'Spacer') {
          newMaterialNames.spacer.push({
            name: item.name,
            id : item.id,
            state: item.state.name === 'gelöscht' || item.state.name === 'gesperrt' || item.deleted
          });
        }
        else if (item.type.name === 'Separator') {
          newMaterialNames.separator.push({
            name: item.name,
            id : item.id,
            state: item.state.name === 'gelöscht' || item.state.name === 'gesperrt' || item.deleted
          });
        }
        //sort names:
        newMaterialNames.anode = newMaterialNames.anode.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
          }
          if(a.name.toLowerCase() > b.name.toLowerCase()){
            return 1;
          }
          return 0;
        });
        newMaterialNames.cathode = newMaterialNames.cathode.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
          }
          if(a.name.toLowerCase() > b.name.toLowerCase()){
            return 1;
          }
          return 0;
        });
        newMaterialNames.case = newMaterialNames.case.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
          }
          if(a.name.toLowerCase() > b.name.toLowerCase()){
            return 1;
          }
          return 0;
        });
        newMaterialNames.feather = newMaterialNames.feather.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
          }
          if(a.name.toLowerCase() > b.name.toLowerCase()){
            return 1;
          }
          return 0;
        });
        newMaterialNames.electrolyt = newMaterialNames.electrolyt.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
          }
          if(a.name.toLowerCase() > b.name.toLowerCase()){
            return 1;
          }
          return 0;
        });
        newMaterialNames.separator = newMaterialNames.separator.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
          }
          if(a.name.toLowerCase() > b.name.toLowerCase()){
            return 1;
          }
          return 0;
        });
        newMaterialNames.spacer = newMaterialNames.spacer.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
          }
          if(a.name.toLowerCase() > b.name.toLowerCase()){
            return 1;
          }
          return 0;
        });
        setMaterialNames(newMaterialNames);
        
      }
      if( newMaterialNames.anode.filter(x => !x.state).length < 1 || 
        newMaterialNames.cathode.filter(x => !x.state).length < 1 || 
        newMaterialNames.case.filter(x => !x.state).length < 1 || 
        newMaterialNames.electrolyt.filter(x => !x.state).length < 1 || 
        newMaterialNames.feather.filter(x => !x.state).length < 1 || 
        newMaterialNames.separator.filter(x => !x.state).length < 1 || 
        newMaterialNames.spacer.filter(x => !x.state).length < 1 ){
          let errorModal = document.getElementById("ErrorMissingMaterial");
          // if(!errorModal) return;
          $('#ErrorMissingMaterial').modal('show');
        }
        else{
          setLoadNamesOfMaterials(true);
        }
    }
  },[loadMaterial]);
  useEffect(() => {
    validateInputFields();
    if(loadNamesOfMaterials){
       setInitialized(true);
    }
  }, [inputs]);

  return (
    <div className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
      {initialized 
        ? <>
            <h1>Batteriebau {location.state.type}</h1>
            <div className='col-4'>
              <div className='row'>
                  <h6>Arbeitspaket: {packagename}</h6>
                </div>
              <div className='row'>
                <h6>Arbeitsblock: {blockname}</h6>
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col'>
                <label htmlFor='celltype'>Zelltyp</label>
                <div style={{ visibility: 'hidden', height: 0 }}>
                    <InputField
                      value={inputs.celltype}
                      inputType='text'
                      name='celltype'
                      placeholder={inputs.celltype}
                      changeHandler={handleChange}
                      inputClass='text-md border-bottom-primary margin-bottom-10'
                    />
                  </div>
                <select
                  name='celltype'
                  value={inputs.celltype}
                  onChange={handleOptionChange}
                  className='input-lg text-center text-md border-bottom-primary margin-right-5'
                >
                  {celltypeList.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col'>
                <label htmlFor='constructor'>Verantwortlicher</label>
                <div style={{ visibility: 'hidden', height: 0 }}>
                    <InputField
                      value={inputs.constructor}
                      inputType='text'
                      name='constructor'
                      placeholder={inputs.constructor[0]}
                      changeHandler={handleChange}
                      inputClass='text-md border-bottom-primary margin-bottom-10'
                    />
                  </div>
                <select
                  name='constructor'
                  value={inputs.constructor}
                  onChange={handleOptionChange}
                  className='input-lg text-center text-md border-bottom-primary margin-right-5'
                >
                  {userListDict.map((option) => (
                    <option key={option.displayName} value={option.displayName} hidden={!option.stateIsActve}>
                      {option.displayName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='row margin-top-10'>
              <div className='col'>
                <h2>Festgesetze Werte</h2>
                <div className="table-responsive margin-top-5">
                  <table className='table main-table table-hover align-middle' style={{width:'100%'}}>
                    <thead>
                      <tr className=" align-middle">
                        {tableHeadersOne.map((header, index) => (
                          <th key={index}>{header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{width:'80px'}}>
                          <input type='checkbox' name='checkAnode' id='checkAnode'
                          checked = {inputs.checkAnode}
                          onChange={(e) => handleCheckboxChange(e, 0)}
                          disabled={false}
                          />
                        </td>
                        <td style={{width:'100px'}}>Anode</td>
                        {inputs.anode?(<td>{inputs.anode}, Größe: {inputs.anodengroesse}  mm</td>
                        ):(<td>Keine Einträge für Anode vorhanden</td>)}
                      </tr>

                      <tr>
                        <td style={{width:'80px'}}>
                          <input type='checkbox' name='checkCathode' id='checkCathode'
                          checked = {inputs.checkCathode}
                          onChange={(e) => handleCheckboxChange(e, 0)}
                          disabled={false}
                          />
                        </td>
                        <td style={{width:'100px'}}>Kathode</td>
                        {inputs.kathode?(
                        <td>{inputs.kathode}, Größe: {inputs.kathodengroesse} mm</td>
                        ):(<td>Keine Einträge für Kathode vorhanden</td>)}
                      </tr>

                      <tr>
                        <td style={{width:'80px'}}>
                          <input type='checkbox' name='checkElectrolyt' id='checkElectrolyt' 
                          checked = {inputs.checkElectrolyt}
                          onChange={(e) => handleCheckboxChange(e, 0)}
                          disabled={false}
                          />
                        </td>
                        <td style={{width:'100px'}}>Elektrolyt</td>
                        {inputs.elektrolyt?(
                        <td>{inputs.elektrolyt}, Volumen:{inputs.elektrolytvolumen}, Gesamtvolumen {inputs.elektrolytgesamtvolumen} ul</td>
                        ):(<td>Keine Einträge für Elektrolyt vorhanden</td>)}
                      </tr>

                      <tr>
                        <td style={{width:'80px'}}>
                          <input type='checkbox' name='checkSeparator' id='checkSeparator' 
                          checked = {inputs.checkSeparator}
                          onChange={(e) => handleCheckboxChange(e, 0)}
                          disabled={false}
                          />
                        </td>
                        <td style={{width:'100px'}}>Separator</td>
                        {inputs.separator?(
                        <td>{inputs.separator}, Größe: {inputs.separatorgroesse} mm, Anzahl: {inputs.separatoranzahl}</td>
                        ):(<td>Keine Einträge für Separator vorhanden</td>)}
                      </tr>

                      <tr>
                        <td style={{width:'80px'}}>
                          <input type='checkbox' name='checkFeather' id='checkFeather' 
                          checked = {inputs.checkFeather}
                          onChange={(e) => handleCheckboxChange(e, 0)}
                          disabled={false}
                          />
                        </td>
                        <td style={{width:'100px'}}>Feder</td>
                        {inputs.feder?(
                        <td>{inputs.feder}</td>
                        ):(<td>Keine Einträge für Feder vorhanden</td>)}
                      </tr>

                      <tr>
                        <td style={{width:'80px'}}>
                          <input type='checkbox' name='checkSpacer' id='checkSpacer' 
                          checked = {inputs.checkSpacer}
                          onChange={(e) => handleCheckboxChange(e, 0)}
                          disabled={false}
                          />
                        </td>
                        <td style={{width:'100px'}}>Spacer</td>
                        {inputs.spacer?(
                        <td>{inputs.spacer}, Größe: {inputs.spacergroesse} mm, Dicke: {inputs.spacerdicke} mm</td>
                        ):(<td>Keine Einträge für Spacer vorhanden</td>)}
                      </tr>

                      <tr>
                        <td style={{width:'80px'}}>
                          <input type='checkbox' name='checkCase' id='checkCase' 
                          checked = {inputs.checkCase}
                          onChange={(e) => handleCheckboxChange(e, 0)}
                          disabled={false}
                          />
                        </td>
                        <td style={{width:'100px'}}>Gehäuse</td>
                        {inputs.gehaeuse?(
                        <td>{inputs.gehaeuse}</td>
                        ):(<td>Keine Einträge für Gehäuse vorhanden</td>)}
                      </tr>
                    </tbody>
                  </table>
                </div>
                {missingBatteryData?
                  <p className='text-err-color-font'>{missingInputText}</p>
                  : <></>
                }
              </div>
              <div className='col'>
                <h2>Gewünschte Variationen</h2>
                <div className="table-responsive margin-top-5">
                  <table className='table main-table table-hover align-middle' style={{width:'100%'}}>
                    <thead>
                      <tr className=" align-middle">
                        {tableHeadersTwo.map((header, index) => (
                          <th key={index}>{header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {variationTable.map((vari, index) => (
                        <tr key={index}>
                          <td key='' style={{width:'56px'}}>
                            <button
                              className='btn-delete'
                              type='button'
                              data-bs-toggle='modal'
                              data-bs-target={'#BatteriebauVariationNew_DeleteModal_'+String(index)}
                            ><FontAwesomeIcon
                              icon={faXmark}
                              size='sm'
                              />
                            </button>
                            <ConfirmationModal
                              modalID={'BatteriebauVariationNew_DeleteModal_'+String(index)}
                              modalText='Soll der Eintrag wirklich gelöscht werden?'
                              confirmationText='löschen'
                              handleConfirm={() => setVariationTable((prevTable) => {
                                const newTable = [...prevTable];
                                newTable.splice(index, 1);
                                const newvariation = [...variations];
                                newvariation.splice(index,1);
                                setVariations(newvariation)
                                return newTable;
                              })}
                            />
                          </td>
                          
                          <td  style={{width:'100px'}}>
                            <input
                              type='number'
                              name='anzahl'
                              placeholder={'1'}
                              value={Number(vari.anzahl)}
                              min = '1'
                              onChange={(event) => handleChangeTable(event, index)}
                              className='input-lg text-md border-bottom-primary'
                            />
                          </td>
                          <td style={{width:'140px'}}>
                            <div style={{ visibility: 'hidden', height: 0 }}>
                                  <InputField
                                    inputType='text'
                                    name='bauort'
                                    placeholder={vari.bauort}
                                    value={vari.bauort}
                                    changeHandler={(event) => handleChangeTable(event, index)}
                                    disabled={false}
                                    inputClass='text-center text-md cut-text-overflow margin-top-5'
                                  />
                                </div>
                                <select
                                  name='bauort'
                                  value={vari.bauort}
                                  onChange={(event) => handleOptionChangeTable(event,index)}
                                  className='input-lg text-md border-bottom-primary margin-top-5'
                                    >
                                    {Location.map((option: string) => (
                                    <option key={option} value={option}>
                                      {option}
                                  </option>
                                  ))}
                                </select>
                            </td>
                          <td>{vari.variation}</td>
                          <td style={{width:'120px'}}>
                          <button
                            className='btn-main'
                            type='button'
                            data-bs-toggle='modal'
                            data-bs-target={'#BatteryConstructionVariationChangeNew_'+String(index)}
                            disabled = {
                              inputs.checkAnode && inputs.checkCase &&inputs.checkCathode 
                              && inputs.checkElectrolyt && inputs.checkFeather 
                              && inputs.checkSeparator && inputs.checkSpacer 
                            }
                            >Bearbeiten
                            </button>
                          </td>
                          { loadNamesOfMaterials ? 
                              <BatteriebauInputModalCreateVariaton
                              modalID={'BatteryConstructionVariationChangeNew_'+String(index)}
                              materialNames={materialNames}
                              submitChangeHandler={submitModalCloseVariation}
                              index={index}
                              anode={{
                                anode: variations[index].anode?.materialName,
                                anodengroesse: variations[index].anode?.diameter,
                                checked: !inputs.checkAnode
                              }}
                              kathode={{
                                kathode: variations[index].cathode?.materialName,
                                kathodengroesse: variations[index].cathode?.diameter,
                                checked: !inputs.checkCathode
                              }}
                              elektrolyt={{
                                elektrolyt: variations[index].electrolyt?.materialName,
                                elektrolytvolumen: variations[index].electrolyt?.volumeText,
                                elektrolytgesamtvolumen: variations[index].electrolyt?.volume,
                                checked: !inputs.checkElectrolyt
                              }}
                              separator={{
                                separator: variations[index].separator?.materialName,
                                separatorgroesse: variations[index].separator?.diameter,
                                separatoranzahl: variations[index].separator?.numberOfSeperators,
                                checked: !inputs.checkSeparator
                              }}
                              feder={{
                                feder: variations[index].feather?.materialName,
                                checked: !inputs.checkFeather
                              }}
                              spacer={{
                                spacer: variations[index].spacer?.materialName,
                                spacergroesse: variations[index].spacer?.diameter,
                                spacerdicke: variations[index].spacer?.thickness,
                                checked: !inputs.checkSpacer
                              }}
                              gehaeuse={{
                                gehaeuse: variations[index].caseData?.materialName,
                                checked: !inputs.checkCase
                              }}
                            />
                            : <></>
                          }
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {missingVariationData?
                  <p className='text-err-color-font'>{missingInputText}</p>
                  : <></>
                }
                <input
                  type='button'
                  className='btn-main margin-top-5'
                  onClick={handleAddVariation}
                  value='Variation hinzufügen'
                  disabled = {
                    inputs.checkAnode && inputs.checkCase &&inputs.checkCathode 
                    && inputs.checkElectrolyt && inputs.checkFeather 
                    && inputs.checkSeparator && inputs.checkSpacer 
                  }
                />
              </div>
            </div>

            <button
              className='btn-main margin-top-5'
              type='button'
              disabled={!(inputs.checkAnode || inputs.checkCase || inputs.checkCathode || inputs.checkElectrolyt || inputs.checkFeather || inputs.checkSeparator || inputs.checkSpacer)}
              data-bs-toggle='modal'
              data-bs-target={'#BatteryConstructionNewStaticData_'}
              >Bearbeiten
            </button>
            {loadNamesOfMaterials ? 
              <BatteriebauInputModalCreate
                modalID='BatteryConstructionNewStaticData_'
                changeHandler={submitModalClose}
                materialNames = {materialNames}
                anode={{
                  anode: inputs.anode,
                  anodengroesse: inputs.anodengroesse,
                  checked: inputs.checkAnode
                }}
                kathode={{
                  kathode: inputs.kathode,
                  kathodengroesse: inputs.kathodengroesse,
                  checked: inputs.checkCathode
                }}
                elektrolyt={{
                  elektrolyt: inputs.elektrolyt,
                  elektrolytvolumen: inputs.elektrolytvolumen,
                  elektrolytgesamtvolumen: inputs.elektrolytgesamtvolumen,
                  checked: inputs.checkElectrolyt
                }}
                separator={{
                  separator: inputs.separator,
                  separatorgroesse: inputs.separatorgroesse,
                  separatoranzahl: inputs.separatoranzahl,
                  checked: inputs.checkSeparator
                }}
                feder={{
                  feder: inputs.feder,
                  checked: inputs.checkFeather
                }}
                spacer={{
                  spacer: inputs.spacer,
                  spacergroesse: inputs.spacergroesse,
                  spacerdicke: inputs.spacerdicke,
                  checked: inputs.checkSpacer
                }}
                gehaeuse={{
                  gehaeuse: inputs.gehaeuse,
                  checked: inputs.checkCase
                }}
              />
              :<></>
            }

            <div className='row margin-top-10'>
              <div className='col'>
                <input
                  className='btn-main margin-right-15'
                  type='button'
                  value='Zurück'
                  onClick={handleBack}
                />
                <input
                  className='btn-main'
                  type='button'
                  value='Batteriebau anlegen'
                  disabled={!validatedInputs}
                  onClick={handleCreate}
                />
              </div>
            </div>
          </>
        : <></>
      }
      <NavigationModal 
        modalID='ErrorMissingMaterial'
        infoText='Bitte erstellen Sie alle Typen an Materialien mindestens einmal!'
        confirmationText='Ok'
        path='/material'
          />
    </div>
  );
};
