import { ChangeEvent, useEffect, useState } from 'react';
import InputField from '../../UI/InputField';
import { useNavigate, useLocation } from 'react-router-dom';
import { ApiBackendData } from '../../backend/ApiBackendData';
import { __WorkBlockCreate } from '../../backend/types/__RequestStructures';
import Config from '../../util/Config';
import { ApiWorkBlock } from '../../backend/ApiWorkBlock';
import Loading from '../../components/Ladebalken';
import { useUserList } from '../../hooks/UserHooks';

/**
 * Charakterisierung
 * Umbenennungen:
    Hafttest -> Sonstiges
    Post Mortem -> PM Foto
    Raman -> PM REM
    Mikroskopie -> LiMi
*/

interface IAddition {
  position: number;
  worktype: string;
  comment: string;
}
interface IAllAdditions extends Record<string, IAddition> { }
/*
{
  sheet2sheetCheckbox?: IAddition;
  roll2rollCheckbox?: IAddition;
  halbzellenCheckbox?: IAddition;
  vollzellenCheckbox?: IAddition;
  halbzellenTestCheckbox?: IAddition;
  vollzellenTestCheckbox?: IAddition;
  hafttestCheckbox?: IAddition;
  mikroskopieCheckbox?: IAddition;
  remCheckbox?: IAddition;
  xrdCheckbox?: IAddition;
  ramanCheckbox?: IAddition;
  postMortemVisuellCheckbox?: IAddition;
}
*/

function ConvertCheckboxName(name : string) : string {
  /**
   * Charakterisierung
   * Umbenennungen:
      Hafttest -> Sonstiges
      Post Mortem -> PM Foto
      Raman -> PM REM
      Mikroskopie -> LiMi
  */
  switch(name) {
    case "sheet2sheetCheckbox":
      return "Anodenbau Sheet-to-Sheet";
    case "roll2rollCheckbox":
      return "Anodenbau Roll-to-Roll";
    case "halbzellenCheckbox":
      return "Batteriebau Halbzelle";
    case "vollzellenCheckbox":
      return "Batteriebau Vollzelle";
    case "halbzellenTestCheckbox":
      return "Batterietest Halbzelle";
    case "vollzellenTestCheckbox":
      return "Batterietest Vollzelle";
    case "mikroskopieCheckbox":
      return "Charakterisierung LiMi";
    case "remCheckbox":
      return "Charakterisierung REM";
    case "xrdCheckbox":
      return "Charakterisierung XRD";
    case "ramanCheckbox":
      return "Charakterisierung PM REM";
    case "postMortemVisuellCheckbox":
      return "Charakterisierung PM Foto";
    case "hafttestCheckbox":
      return "Charakterisierung Sonstiges";
    default:
      return "unknown";
  }
}

function SearchSortAdditionList(list : IAllAdditions) : IAllAdditions {

  let sortList = [
    "sheet2sheetCheckbox",
    "roll2rollCheckbox",
    "halbzellenCheckbox",
    "vollzellenCheckbox",
    "halbzellenTestCheckbox",
    "vollzellenTestCheckbox",
    "mikroskopieCheckbox",
    "remCheckbox",
    "xrdCheckbox",
    "ramanCheckbox",
    "postMortemVisuellCheckbox",
    "hafttestCheckbox"
  ];

  let counter = 0;

  let sortedList : IAllAdditions = {};
  for(let item  of sortList) {
    for(let entry in list) {
      if(item === entry) {
        sortedList[entry] = list[entry];
        sortedList[entry].position = counter;
        counter++;
      }
    }
  }
  return sortedList;
}

export default function ArbeitsblockErgaenzung() {
  // call Apis for basic data
  const location = useLocation();
  const navigate = useNavigate();

  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  const [userListDict, userDataLoaded] = useUserList();
  let additionListDummy: IAllAdditions = {};
  const [addition, setAddition] = useState<IAllAdditions>(additionListDummy);
  const [validate, setValidation] = useState(false);
  const [inputs, setInputs] = useState({
    arbeitspaket: location.state ? location.state.workpackageID : '',
    blockName: '',
    verantwortlicher: '',
    syntheseort: enums.Location[0],
    aufgabenbeschreibung: '',
    kommentar: '',
    anzahlSheets: 0,
    laengeSheets: 0,
    anzahlHalbzellen: 0,
    anzahlVollzellen: 0,
    anzahlPouchzellen: 0,
    anodenbauCheckbox: false,
    sheet2sheetCheckbox: false,
    roll2rollCheckbox: false,
    batteriebauCheckbox: false,
    halbzellenCheckbox: false,
    vollzellenCheckbox: false,
    batterietestCheckbox: false,
    halbzellenTestCheckbox: false,
    vollzellenTestCheckbox: false,
    charakterisierungCheckbox: false,
    hafttestCheckbox: false,
    mikroskopieCheckbox: false,
    remCheckbox: false,
    xrdCheckbox: false,
    ramanCheckbox: false,
    postMortemVisuellCheckbox: false,
    postMortemREMCheckbox: false,
    anodenmaterial: '',
  });
  const [initialized, setInitialized] = useState(false);
  
  //validation
  const checkBoxCheck = () => {
    setValidation(false);
    let anode: boolean = inputs.anodenbauCheckbox 
      ? inputs.anodenbauCheckbox && ((inputs.sheet2sheetCheckbox && inputs.anzahlSheets > 0) || inputs.roll2rollCheckbox)
      : true; 
      let batterybau:boolean = inputs.batteriebauCheckbox 
      ? inputs.batteriebauCheckbox && (inputs.halbzellenCheckbox || inputs.vollzellenCheckbox)
      : true; 
    let batterytest:boolean = inputs.batterietestCheckbox 
      ? inputs.batterietestCheckbox && (inputs.halbzellenTestCheckbox || inputs.vollzellenTestCheckbox)
      : true; 
    let characteristic:boolean = inputs.charakterisierungCheckbox 
      ? inputs.charakterisierungCheckbox && (inputs.hafttestCheckbox || inputs.mikroskopieCheckbox || inputs.xrdCheckbox || inputs.remCheckbox || inputs.ramanCheckbox || inputs.postMortemREMCheckbox || inputs.postMortemVisuellCheckbox)
      : true; 
    
    if(inputs.anodenbauCheckbox || inputs.batteriebauCheckbox || inputs.batterietestCheckbox || inputs.charakterisierungCheckbox){
      if(anode && batterybau && batterytest && characteristic)
      setValidation(true);
      else{
        setValidation(false);
      }
    }
  };
  
  //Handle functions
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const handleSubmit = async(event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOpenLoadingModal(true);
    let user: string = userListDict[userListDict.map(x=>x.displayName).indexOf(inputs.verantwortlicher)].loginName ?? userListDict[0].loginName;
    let requestData: __WorkBlockCreate = {
      platform: Config.Platform,
      workpackageID: inputs.arbeitspaket,
      responsibleUser: [user],
      description: inputs.aufgabenbeschreibung,
      comment: inputs.kommentar,
      location: inputs.syntheseort,
      state: 'aktiv',
      isAddition: addition 
        ?{
          platform: Config.Platform,
          workblock:  null,
          comment: inputs.aufgabenbeschreibung,
          anodeConstructionComment: addition['roll2rollCheckbox'] || addition['sheet2sheetCheckbox']
            ? addition['sheet2sheetCheckbox'] && addition['roll2rollCheckbox']
              ? 'Sheet to Sheet: '+ addition['sheet2sheetCheckbox'].comment + ', Roll to Roll: ' + addition['roll2rollCheckbox'].comment
              : addition['sheet2sheetCheckbox']
                ? addition['sheet2sheetCheckbox'].comment
                : addition['roll2rollCheckbox'].comment
            : undefined,
          batteryConstructionComment: addition['halbzellenCheckbox'] || addition['vollzellenCheckbox']
            ? addition['halbzellenCheckbox'] && addition['vollzellenCheckbox']
              ? 'Halbzellen: '+ addition['halbzellenCheckbox'].comment + ', Vollzellen: ' + addition['vollzellenCheckbox'].comment
              : addition['halbzellenCheckbox']
                ? addition['halbzellenCheckbox'].comment
                : addition['vollzellenCheckbox'].comment
            : undefined,
          batteryTestComment: addition['halbzellenTestCheckbox'] || addition['vollzellenTestCheckbox']
            ? addition['halbzellenTestCheckbox'] && addition['vollzellenTestCheckbox']
              ? 'Halbzellen: '+ addition['halbzellenTestCheckbox'].comment + ', Vollzellen: ' + addition['vollzellenTestCheckbox'].comment
              : addition['halbzellenTestCheckbox']
                ? addition['halbzellenTestCheckbox'].comment
                : addition['vollzellenTestCheckbox'].comment
            : undefined,
          adhesionTestComment: addition['hafttestCheckbox']
            ? addition['hafttestCheckbox'].comment
            : undefined,
          microscopyComment: addition['mikroskopieCheckbox']
            ? addition['mikroskopieCheckbox'].comment
            : undefined,
          xrdComment: addition['xrdCheckbox']
            ? addition['xrdCheckbox'].comment
            : undefined,
          ramanComment: addition['ramanCheckbox']
            ? addition['ramanCheckbox'].comment
            : undefined,
          remComment: addition['remCheckbox']
            ? addition['remCheckbox'].comment
            : undefined,
          postMortemComment: addition['postMortemVisuellCheckbox']
            ? addition['postMortemVisuellCheckbox'].comment
            : undefined
        }
        : null,
      plannedSteps:{
        anodeConstructionSheetToSheet: inputs.sheet2sheetCheckbox
        ? {
          number: Number(inputs.anzahlSheets)
        }
        : null,
        anodeConstructionR2R: inputs.roll2rollCheckbox
        ? {
          length: Number(inputs.laengeSheets ?? 0)
        }
        : null,
        batteryConstruction: inputs.batteriebauCheckbox
        ?{
          VZ: inputs.vollzellenCheckbox
          ? {
            type: 'Vollzelle',
            number: Number(inputs.anzahlVollzellen),
            numberPZ: Number(inputs.anzahlPouchzellen)
          }
          : null,
          HZ: inputs.halbzellenCheckbox 
          ? {
            type: 'Halbzelle',
            number: Number(inputs.anzahlHalbzellen),
            numberPZ: 0
          }
          : null
        }
        : null,
        batteryTest: inputs.batterietestCheckbox
        ? {
          VZ: inputs.vollzellenTestCheckbox 
          ? {
            type: 'Vollzelle'
          }
          : null,
          HZ: inputs.halbzellenTestCheckbox
          ? {
            type: 'Halbzelle'
          }
          : null
        }
        : null,
        analysis: inputs.charakterisierungCheckbox
        ? {
          adhesionTest: inputs.hafttestCheckbox,
          microscopy: inputs.mikroskopieCheckbox,
          rem: inputs.remCheckbox,
          xrd: inputs.xrdCheckbox,
          raman: inputs.ramanCheckbox,
          postMortemAnalysis: inputs.postMortemVisuellCheckbox || inputs.postMortemREMCheckbox
        }
        : null 
      }
    }
    
    await ApiWorkBlock.Create(requestData);
    
    navigate(`/arbeitspakete/details`, {
        state: {
          workpackage: location.state.workpackage
        },
        replace:true
      });
  };
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.checked;
    setInputs((prevState) => ({ ...prevState, [name]: value }));

    setAddition((prevAddition) => {

      // only continue if name is of sub elements
      if (
        name === "anodenbauCheckbox" ||
        name === "batteriebauCheckbox" ||
        name === "batterietestCheckbox" ||
        name === "charakterisierungCheckbox"
      ) {
        return prevAddition;
      }

      let checked = value;
      if (checked) {
        let newAdd: IAddition = {
          position: Object.keys(addition).length,
          worktype: name,
          comment: ''
        };

        prevAddition[name] = newAdd;
      }
      else {
        delete prevAddition[name];

        // rewrite positions
        let keys = Object.keys(prevAddition);
        for (let idx = 0; idx < keys.length; idx++) {
          prevAddition[keys[idx]].position = idx;
        }
      }

      prevAddition = SearchSortAdditionList(prevAddition);
      return prevAddition;
    });
  };
  const handleAdditionCommentChange = (event: ChangeEvent<HTMLInputElement>, index:number|string)=>{
    const adds = addition;
    const value = event.target.value;
    adds[index] = {...adds[index],['comment']: value}
    setAddition(adds);
  };

  //Use effects
  useEffect(() => {
    document.title = 'Arbeitsblock Ergänzung';
  },[]);
  useEffect(()=>{
    if(userDataLoaded){
      let newInputs = {...inputs};
      newInputs.verantwortlicher = userListDict[0].displayName;
      setInputs(newInputs);
      setInitialized(true);
    }
  },[userDataLoaded]);
  useEffect(()=>{
    checkBoxCheck();
  },[inputs]);

  return (
    <div className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
      <h1>Neuen Arbeitsblock zum Arbeitspaket ergänzen</h1>
      { initialized
        ? <form onSubmit={handleSubmit} className='margin-top-15'>
            <div className='row'>
              <div className='col-5'>
                <div className='row margin-top-15'>
                  <div className='col-7'>
                    <label htmlFor='blockName'>Name</label>
                    <InputField
                      inputType='text'
                      name='blockName'
                      placeholder=''
                      value={inputs.blockName}
                      changeHandler={handleChange}
                      disabled={true}
                      inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
                    />
                  </div>
                </div>
                <div className='row margin-top-15'>
                    <div className='col-7'>
                      <label htmlFor='verantwortlicher'>Verantwortlicher</label>
                      <div style={{ visibility: 'hidden', height: 0 }}>
                        <InputField
                          inputType='text'
                          name='verantwortlicher'
                          placeholder=''
                          value={inputs.verantwortlicher}
                          changeHandler={handleChange}
                          disabled={false}
                          inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
                        />
                      </div>
                      <select
                        name='verantwortlicher'
                        value={inputs.verantwortlicher}
                        onChange={handleOptionChange}
                        className='input-lg text-center text-md border-bottom-primary margin-top-5'
                      >
                        {userListDict.map((option) => (
                          <option key={option.displayName} value={option.displayName} hidden={!option.stateIsActve}>
                            {option.displayName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='col-5'>
                      <label htmlFor='syntheseort'>Syntheseort</label>
                      <div style={{ visibility: 'hidden', height: 0 }}>
                        <InputField
                          inputType='text'
                          name='syntheseort'
                          placeholder=''
                          value={inputs.syntheseort}
                          changeHandler={handleChange}
                          disabled={false}
                          inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
                        />
                      </div>

                      <select
                        name='syntheseort'
                        value={inputs.syntheseort}
                        onChange={handleOptionChange}
                        className='input-lg text-center text-md border-bottom-primary margin-top-5'
                      >
                        {enums.Location.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                </div>
              </div>
              <div className='margin-top-15 col-7' style={{height:'135px'}}>
                <label htmlFor='aufgabenbeschreibung'>Kurzbeschreibung</label>
                <textarea
                  className='input-lg-textarea text-md border-bottom-primary cut-text-overflow margin-top-5'
                  name='aufgabenbeschreibung'
                  value={inputs.aufgabenbeschreibung}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className='row margin-top-15'>
              <div className='col-4'>
                <h2>Anode</h2>
                <div className='row margin-top-15'>
                  <div className='col'>
                    <input
                      type='checkbox'
                      name='anodenbauCheckbox'
                      id='anodenbauCheckbox'
                      checked={inputs.anodenbauCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor='anodenbauCheckbox' className='margin-left-5'>
                      Anodenbau
                    </label>
                  </div>
                  <div className='row'>
                    <div className='col-1'></div>
                    <div
                      className='col-11 mt-2'
                      style={
                        !inputs.anodenbauCheckbox ? { visibility: 'hidden' } : {}
                      }
                    >
                      <div className=''>
                        <input
                          type='checkbox'
                          name='sheet2sheetCheckbox'
                          id='sheet2sheetCheckbox'
                          checked={inputs.sheet2sheetCheckbox}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          htmlFor='sheet2sheetCheckbox'
                          className='margin-left-5'
                        >
                          Sheet-to-Sheet
                        </label>
                      </div>

                      <div
                        style={
                          !inputs.sheet2sheetCheckbox ? { visibility: 'hidden' } : {}
                        }
                      >
                        <label htmlFor='anzahlSheets'>Anzahl der Sheets</label>
                        <InputField
                          inputType='number'
                          name='anzahlSheets'
                          changeHandler={handleChange}
                          value={inputs.anzahlSheets}
                          min='0'
                          placeholder=''
                          inputClass={`text-md ${inputs.sheet2sheetCheckbox && inputs.anzahlSheets < 1 ? "border-bottom-error" : "border-bottom-primary"}`}
                        />
                        {inputs.anzahlSheets < 1 ?
                          <p className = 'text-err margin-bottom-10'>Anzahl muss größer als 0 sein.</p> : 
                          <></>
                        }
                      </div>
                      <div>
                        <input
                          type='checkbox'
                          name='roll2rollCheckbox'
                          id='roll2rollCheckbox'
                          checked={inputs.roll2rollCheckbox}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor='roll2rollCheckbox' className='margin-left-5'>
                          Roll-to-Roll
                        </label>
                      </div>
                      <div
                        style={
                          !inputs.roll2rollCheckbox ? { visibility: 'hidden' } : {}
                        }
                      >
                        <label htmlFor='laengeSheets'>Anzahl der Sheets</label>
                        <InputField
                          inputType='number'
                          name='laengeSheets'
                          changeHandler={handleChange}
                          value={inputs.laengeSheets}
                          min='0'
                          placeholder=''
                          inputClass='text-md border-bottom-primary margin-bottom-10'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-4'>
                <h2>Batterie</h2>
                <div className='row margin-top-15'>
                  <div className='col'>
                    <input
                      type='checkbox'
                      name='batteriebauCheckbox'
                      id='batteriebauCheckbox'
                      checked={inputs.batteriebauCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor='batteriebauCheckbox' className='margin-left-5'>
                      Batteriebau
                    </label>
                  </div>
                  <div className='row'>
                    <div className='col-1'></div>
                    <div
                      className='col-11 mt-2'
                      style={
                        !inputs.batteriebauCheckbox ? { visibility: 'hidden' } : {}
                      }
                    >
                      <div>
                        <input
                          type='checkbox'
                          name='halbzellenCheckbox'
                          id='halbzellenCheckbox'
                          checked={inputs.halbzellenCheckbox}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor='halbzellenCheckbox' className='margin-left-5'>
                          Halbzellen
                        </label>
                      </div>
                      <div
                        style={
                          !inputs.halbzellenCheckbox ? { visibility: 'hidden' } : {}
                        }
                      >
                        <label htmlFor='anzahlHalbzellen'>
                          Anzahl der Halbzellen
                        </label>
                        <InputField
                          inputType='number'
                          name='anzahlHalbzellen'
                          changeHandler={handleChange}
                          value={inputs.anzahlHalbzellen}
                          min='0'
                          placeholder=''
                          inputClass='text-md border-bottom-primary margin-bottom-10'
                        />
                      </div>
                      <div>
                        <input
                          type='checkbox'
                          name='vollzellenCheckbox'
                          id='vollzellenCheckbox'
                          checked={inputs.vollzellenCheckbox}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor='vollzellenCheckbox' className='margin-left-5'>
                          Vollzellen
                        </label>
                      </div>
                      <div
                        style={
                          !inputs.vollzellenCheckbox ? { visibility: 'hidden' } : {}
                        }
                      >
                        <label htmlFor='anzahlVollzellen'>
                          Anzahl der Vollzellen
                        </label>
                        <InputField
                          inputType='number'
                          name='anzahlVollzellen'
                          changeHandler={handleChange}
                          value={inputs.anzahlVollzellen}
                          min='0'
                          placeholder=''
                          inputClass='text-md border-bottom-primary margin-bottom-10'
                        />
                        <label htmlFor='anzahlPouchzellen'>
                          Anzahl der Pouchzellen
                        </label>
                        <InputField
                          inputType='number'
                          name='anzahlPouchzellen'
                          value={inputs.anzahlPouchzellen}
                          changeHandler={handleChange}
                          min='0'
                          placeholder=''
                          inputClass='text-md border-bottom-primary margin-bottom-10'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row '>
                  <div className='col'>
                    <input
                      type='checkbox'
                      name='batterietestCheckbox'
                      id='batterietestCheckbox'
                      checked={inputs.batterietestCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor='batterietestCheckbox' className='margin-left-5'>
                      Batterietest
                    </label>
                  </div>
                  <div className='row'>
                    <div className='col-1'></div>
                    <div
                      className='col-11 mt-2'
                      style={
                        !inputs.batterietestCheckbox ? { visibility: 'hidden' } : {}
                      }
                    >
                      <div>
                        <input
                          type='checkbox'
                          name='halbzellenTestCheckbox'
                          id='halbzellenTestCheckbox'
                          checked={inputs.halbzellenTestCheckbox}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          htmlFor='halbzellenTestCheckbox'
                          className='margin-left-5'
                        >
                          Halbzellen
                        </label>
                      </div>
                      <div>
                        <input
                          type='checkbox'
                          name='vollzellenTestCheckbox'
                          id='vollzellenTestCheckbox'
                          checked={inputs.vollzellenTestCheckbox}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          htmlFor='vollzellenTestCheckbox'
                          className='margin-left-5'
                        >
                          Vollzellen
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-4'>
                <h2>Analyse</h2>
                <div className='row margin-top-15'>
                  <div className='col'>
                    <input
                      type='checkbox'
                      name='charakterisierungCheckbox'
                      id='charakterisierungCheckbox'
                      checked={inputs.charakterisierungCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      htmlFor='charakterisierungCheckbox'
                      className='margin-left-5'
                    >
                      Charakterisierung
                    </label>
                  </div>
                  <div className='row'>
                    <div className='col-1'></div>
                    <div
                    className='col-11 mt-2'
                    style={
                      !inputs.charakterisierungCheckbox
                        ? { visibility: 'hidden' }
                        : {}
                    }
                  >
                    {/* /**
                      * Charakterisierung
                      * Umbenennungen:
                          Hafttest -> Sonstiges
                          Post Mortem -> PM Foto
                          Raman -> PM REM
                          Mikroskopie -> LiMi
                      */ }
                    
                  <div>
                    <input
                      type='checkbox'
                      name='mikroskopieCheckbox'
                      id='mikroskopieCheckbox'
                      checked={inputs.mikroskopieCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor='mikroskopieCheckbox' className='margin-left-5'>
                      LiMi
                    </label>
                  </div>
                  <div>
                    <input
                      type='checkbox'
                      name='remCheckbox'
                      id='remCheckbox'
                      checked={inputs.remCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor='remCheckbox' className='margin-left-5'>
                      REM
                    </label>
                  </div>
                  <div>
                    <input
                      type='checkbox'
                      name='xrdCheckbox'
                      id='xrdCheckbox'
                      checked={inputs.xrdCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor='xrdCheckbox' className='margin-left-5'>
                      XRD
                    </label>
                  </div>
                  <div>
                    <input
                      type='checkbox'
                      name='ramanCheckbox'
                      id='ramanCheckbox'
                      checked={inputs.ramanCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor='ramanCheckbox' className='margin-left-5'>
                      PM REM
                    </label>
                  </div>
                  <div>
                    <input
                      type='checkbox'
                      name='postMortemVisuellCheckbox'
                      id='postMortemVisuellCheckbox'
                      checked={inputs.postMortemVisuellCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      htmlFor='postMortemVisuellCheckbox'
                      className='margin-left-5'
                    >
                      PM Foto
                    </label>
                  </div>
                  <div>
                    <input
                      type='checkbox'
                      name='hafttestCheckbox'
                      id='hafttestCheckbox'
                      checked={inputs.hafttestCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    
                    <label htmlFor='hafttestCheckbox' className='margin-left-5'>
                      Sonstiges
                    </label>
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <table className='main-table margin-top-15 hover-effect' style={{display:'none'}}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Ergänzung</th>
                  <th>Anmerkung</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(addition).map((add, index) => (
                  <tr key={add}>
                    <td key='position'>{addition[add].position}</td>
                    <td key='worktype'>{ConvertCheckboxName(addition[add].worktype)}</td>
                    <td>
                      <InputField
                        key='comment'
                        inputType='text'
                        placeholder=''
                        name='comment'
                        changeHandler={(event) => handleAdditionCommentChange(event,add)}
                        inputClass ='text-center text-md cut-text-overflow margin-top-5'
                      />
                    </td>
                  </tr>
                ))}</tbody>
            </table>
            <input
              className='btn-main margin-top-25'
              type='button'
              value='Zurück'
              onClick={() => navigate(-1)}
            />
            <input
              type='submit'
              value='Arbeitsblock hinzufügen'
              className='btn-main margin-left-15'
              disabled = {!validate}
            />
          </form>
        : <></>
      }
    </div>
  );
}
